import React from 'react'
import { CFView, DefaultScreen, MobileScreen } from 'components'

export default ({ children, offset, ...props }) => {
  if (offset === 'header') {
    offset = '50px'
  } else if (offset === 'navbar') {
    offset = '64px'
  } else if (offset === 'defaultNavbar') {
    offset = '70px'
  }
  return (
    <>
      <MobileScreen>
        <CFView
          h={`calc(100% - ${offset})`}
          w="100%"
          overflowY="auto"
          style={{ '-webkit-overflow-scrolling': 'touch' }}
          {...props}
        >
          {children}
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h={`calc(100vh - ${offset})`}
          w="100%"
          overflowY="auto"
          {...props}
        >
          {children}
        </CFView>
      </DefaultScreen>
    </>
  )
}
