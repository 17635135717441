import React from 'react'
import { CFIcon, ThemedText } from 'components'
import { COLOR } from 'styles'

export default ({ selected }) =>
  selected ? (
    <CFIcon icon="check-square" color={COLOR.red} fontSize="24px" />
  ) : (
    <ThemedText>
      <CFIcon icon="square" fontSize="24px" />
    </ThemedText>
  )
