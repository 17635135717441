import React from 'react'
import { CFIcon, ThemedText } from 'components'
import { COLOR } from 'styles'

export default ({ selected }) =>
  selected ? (
    <CFIcon icon="dot-circle" color={COLOR.red} fontSize="24px" />
  ) : (
    <ThemedText>
      <CFIcon icon="circle" fontSize="24px" />
    </ThemedText>
  )
