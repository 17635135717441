import React from 'react'
import { CFImage } from 'components'

export default ({ product }) => {
  const { imageUrl } = product
  return (
    <CFImage
      h="25vh"
      w="100%"
      cover
      src={imageUrl}
      boxShadow="0 1px 3px rgba(0,0,0,.8)"
      alt="Product"
    />
  )
}
