import React from 'react'
import { CFSelect, CFView, ThemedText } from 'components'
import SectionHeader from './SectionHeader'
import { dispatch } from 'store'

export default ({ PayCreditCardButton, PayCashButton, EditButton }) => {
  return (
    <CFView column>
      <SectionHeader title="Payment Method" icon="credit-card" />
      <CFSelect
        selector={[
          dispatch.restaurant.getPaymentMethodType,
          dispatch.user.getPaymentMethod,
          dispatch.restaurant.getDeliveryOnlinePaymentOnly,
          dispatch.user.getOrderType,
        ]}
      >
        {([
          paymentMethodType,
          paymentMethod,
          deliveryOnlinePaymentOnly,
          orderType,
        ]) => (
          <CFView p="10px">
            {paymentMethodType === 'both' &&
              !(deliveryOnlinePaymentOnly && orderType === 'Delivery') && (
                <CFView row justifyBetween mt="10px" mb="15px">
                  <CFView center w="48%">
                    {PayCreditCardButton}
                  </CFView>
                  <CFView center w="48%">
                    {PayCashButton}
                  </CFView>
                </CFView>
              )}
            {(paymentMethodType === 'online' || paymentMethod === 'online') && (
              <CFView row mb="10px" pt="5px">
                <CFView fill column>
                  <ThemedText h3 bold mb="2px">
                    Credit / Debit Card
                  </ThemedText>
                  {dispatch.user.getHasPayment() ? (
                    <CFSelect selector={dispatch.user.getLast4}>
                      {last4 => (
                        <ThemedText
                          h5
                          subtitle
                        >{`**** **** **** ${last4}`}</ThemedText>
                      )}
                    </CFSelect>
                  ) : (
                    <ThemedText h5 subtitle>
                      Please add credit / debit card.
                    </ThemedText>
                  )}
                </CFView>
                {EditButton}
              </CFView>
            )}
          </CFView>
        )}
      </CFSelect>
    </CFView>
  )
}
