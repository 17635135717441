import React from 'react'
import { CFView } from 'components'
import RadioSelectIcon from './RadioSelectIcon'
import { COLOR } from 'styles'
import { dispatch } from 'store'

export default ({ time, selected, ...props }) => {
  return (
    <CFView h="40px" row justifyBetween alignCenter pointer {...props}>
      <CFView
        h3
        color={
          selected
            ? COLOR.red
            : dispatch.restaurant.getStyles().theme === 'dark'
            ? 'white'
            : 'black'
        }
      >
        {time}
      </CFView>
      <RadioSelectIcon selected={selected} />
    </CFView>
  )
}
