import React from 'react'
import { CFView } from 'components'
import { dispatch } from 'store'

export default ({ count }) => (
  <CFView
    bg={dispatch.restaurant.getStyles().theme === 'dark' ? 'white' : 'black'}
    p="5px"
    br="2px"
    h7
    xBold
    color={dispatch.restaurant.getStyles().theme === 'dark' ? 'black' : 'white'}
  >
    X{count}
  </CFView>
)
