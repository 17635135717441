import React from 'react'
import { CFView, ThemedText } from 'components'
import { COLOR } from 'styles'
import { dispatch } from 'store'

export default ({ children, title, LeftButton, ...props }) => {
  return (
    <CFView
      br="5px"
      bg={
        dispatch.restaurant.getStyles().theme === 'dark'
          ? COLOR.darkOpacity
          : COLOR.lightOpacity
      }
      boxShadow={
        dispatch.restaurant.getStyles().theme === 'dark'
          ? '1px 1px 3px rgba(255,255,255,.8)'
          : '1px 1px 3px rgba(0,0,0,.8)'
      }
      overflow="hidden"
      relative
      {...props}
    >
      <CFView absolute top="22px" left="40px">
        {LeftButton}
      </CFView>
      {title && (
        <ThemedText h2 bold textCenter mb="20px">
          {title}
        </ThemedText>
      )}
      {children}
    </CFView>
  )
}
