import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  BackButton,
  Card,
  CFImage,
  CFRenderless,
  CFSelect,
  CFView,
  DefaultScreen,
  IconButton,
  IconInput,
  MobileScreen,
  Navbar,
  PageContainer,
  PageHeader,
  PrimaryButton,
  SharedBackground,
} from 'components'
import ForgotPasswordButton from './ForgotPasswordButton'
import { dispatch } from 'store'
import { isEmpty } from 'lodash'
import { codefusionlogo } from 'images'

const passwordInput = React.createRef()

export default () => {
  const [authState, setAuthState] = useState('ENTER_EMAIL')
  const [isCheckingEmail, setIsCheckingEmail] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [name, setName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')

  const handleEnterPress = e => {
    if (e.key === 'Enter') {
      if (authState === 'ENTER_EMAIL') {
        checkEmailExists(e)
      } else if ('EMAIL_LOCKED_ENTER_PASS') {
        loginUser(e)
      } else if ('REGISTER_ENTER_EMAIL_AND_PASS') {
        signupUser(e)
      }
    }
  }

  const checkEmailExists = async () => {
    setIsCheckingEmail(true)
    try {
      const res = await dispatch.user.getIsEmailExists(email)
      if (res) {
        setAuthState('EMAIL_LOCKED_ENTER_PASS')
      } else {
        setAuthState('REGISTER_ENTER_EMAIL_AND_PASS')
      }
      passwordInput.current.focus()
    } catch (e) {
      alert(e.message)
    } finally {
      setIsCheckingEmail(false)
    }
  }

  const loginUser = async () => {
    try {
      await dispatch.user.signInWithEmailAndPassword({
        email,
        password,
      })
      setUserDetails()
      dispatch.notification.setMessage({
        message: `Welcome ${email}!`,
        level: 'success',
      })
    } catch (e) {
      alert(e.message)
    }
  }

  const signupUser = async () => {
    try {
      await dispatch.user.createUserWithEmailAndPassword({
        email,
        password,
        name,
        phoneNumber,
      })
      setUserDetails()
      dispatch.notification.setMessage({
        message: `Welcome ${email}!`,
        level: 'success',
      })
    } catch (e) {
      alert(e.message)
    }
  }

  const setUserDetails = () => {
    dispatch.user.setCart({})
    const choicesCart = dispatch.user.getChoicesCart()
    if (!isEmpty(choicesCart)) {
      dispatch.user.setChoicesCart(choicesCart)
    }
  }

  const history = useHistory()
  const params = useParams()
  const { nextRoute } = params
  const navigateNextRoute = () => {
    history.push(`/${nextRoute}`)
  }

  return (
    <SharedBackground>
      <CFSelect selector={dispatch.user.getIsLoggedIn}>
        <CFRenderless
          componentDidMount={() => {
            navigateNextRoute()
          }}
        />
      </CFSelect>
      <MobileScreen>
        {nextRoute === 'cart' && (
          <PageHeader
            title="Login"
            LeftButton={<BackButton onClick={() => history.push('/cart')} />}
          />
        )}
        <PageContainer offset={nextRoute === 'cart' ? 'header' : 'navbar'}>
          <CFView h="100%" column justifyCenter>
            <CFImage
              src={
                dispatch.restaurant.getImages().brandLogo
                  ? dispatch.restaurant.getImages().brandLogo
                  : codefusionlogo
              }
              w="70%"
              mb="20px"
              selfCenter
            />
            <CFView>
              {authState !== 'ENTER_EMAIL' && (
                <IconButton
                  h="40px"
                  w="80px"
                  ml="20px"
                  mb="20px"
                  color="black"
                  label="Back"
                  icon="arrow-left"
                  onClick={() => setAuthState('ENTER_EMAIL')}
                />
              )}
              <IconInput
                mh="20px"
                mb="20px"
                value={email}
                onChange={e => setEmail(e.target.value)}
                onKeyPress={handleEnterPress}
                disabled={authState !== 'ENTER_EMAIL'}
                fontSize={18}
                maxLength={38}
                type="email"
                icon="envelope"
                placeholder="Enter Email to Login or Signup"
                placeholderstyle={{
                  color: 'grey',
                  padding: '0 4px',
                  verticalAlign: 'center',
                }}
              />
              {(authState === 'REGISTER_ENTER_EMAIL_AND_PASS' ||
                authState === 'EMAIL_LOCKED_ENTER_PASS') && (
                <IconInput
                  mh="20px"
                  mb="20px"
                  ref={passwordInput}
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  onKeyPress={handleEnterPress}
                  fontSize={18}
                  maxLength={38}
                  icon="key"
                  type="password"
                  placeholder="Enter Password"
                  placeholderstyle={{
                    color: 'grey',
                    padding: '0 4px',
                    verticalAlign: 'center',
                  }}
                />
              )}
              {authState === 'REGISTER_ENTER_EMAIL_AND_PASS' && (
                <IconInput
                  mh="20px"
                  mb="20px"
                  value={name}
                  onChange={e => setName(e.target.value)}
                  onKeyPress={handleEnterPress}
                  fontSize={18}
                  maxLength={28}
                  icon="user"
                  placeholder="Enter Name"
                  placeholderstyle={{
                    color: 'grey',
                    padding: '0 4px',
                    verticalAlign: 'center',
                  }}
                />
              )}
              {!dispatch.restaurant.getIsDineIn() &&
                authState === 'REGISTER_ENTER_EMAIL_AND_PASS' && (
                  <IconInput
                    mh="20px"
                    mb="20px"
                    value={phoneNumber}
                    onChange={e => setPhoneNumber(e.target.value)}
                    onKeyPress={handleEnterPress}
                    fontSize={18}
                    maxLength={10}
                    icon="phone"
                    placeholder="Enter Phone Number"
                    placeholderstyle={{
                      color: 'grey',
                      padding: '0 4px',
                      verticalAlign: 'center',
                    }}
                  />
                )}
            </CFView>
            <CFView selfCenter>
              <CFSelect selector={dispatch.user.getIsLoggingIn}>
                {isLoggingIn => {
                  if (authState === 'REGISTER_ENTER_EMAIL_AND_PASS') {
                    return (
                      <PrimaryButton
                        label="SIGN UP"
                        disabled={isLoggingIn}
                        onClick={signupUser}
                      />
                    )
                  } else if (authState === 'EMAIL_LOCKED_ENTER_PASS') {
                    return (
                      <PrimaryButton
                        label="LOGIN"
                        disabled={isLoggingIn}
                        onClick={loginUser}
                      />
                    )
                  } else {
                    return (
                      <PrimaryButton
                        label="NEXT"
                        disabled={isCheckingEmail}
                        onClick={checkEmailExists}
                      />
                    )
                  }
                }}
              </CFSelect>
            </CFView>
            <ForgotPasswordButton mt="20px" selfCenter />
          </CFView>
        </PageContainer>
        {nextRoute !== 'cart' && <Navbar />}
      </MobileScreen>
      <DefaultScreen>
        <Navbar />
        <PageContainer offset="defaultNavbar" p="20px" center>
          <Card minHeight="400px" w="640px" p="40px" column center>
            <CFImage
              src={
                dispatch.restaurant.getImages().brandLogo
                  ? dispatch.restaurant.getImages().brandLogo
                  : codefusionlogo
              }
              w="250px"
              contain
              pb="20px"
            />
            <CFView w="360px" column>
              {authState !== 'ENTER_EMAIL' && (
                <IconButton
                  h="40px"
                  w="80px"
                  mb="10px"
                  label="Back"
                  icon="arrow-left"
                  onClick={() => setAuthState('ENTER_EMAIL')}
                />
              )}
              <IconInput
                mb="20px"
                value={email}
                onChange={e => setEmail(e.target.value)}
                onKeyPress={handleEnterPress}
                disabled={authState !== 'ENTER_EMAIL'}
                fontSize={18}
                maxLength={38}
                type="email"
                icon="envelope"
                placeholder="Enter Email to Login or Signup"
                placeholderstyle={{ color: 'grey', paddingTop: '10px' }}
              />
              {(authState === 'REGISTER_ENTER_EMAIL_AND_PASS' ||
                authState === 'EMAIL_LOCKED_ENTER_PASS') && (
                <IconInput
                  mb="20px"
                  ref={passwordInput}
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  onKeyPress={handleEnterPress}
                  fontSize={18}
                  maxLength={38}
                  icon="key"
                  type="password"
                  placeholder="Enter Password"
                  placeholderstyle={{ color: 'grey', paddingTop: '10px' }}
                />
              )}
              {authState === 'REGISTER_ENTER_EMAIL_AND_PASS' && (
                <IconInput
                  mb="20px"
                  value={name}
                  maxLength={28}
                  onChange={e => setName(e.target.value)}
                  onKeyPress={handleEnterPress}
                  fontSize={18}
                  icon="user"
                  placeholder="Enter Name"
                  placeholderstyle={{ color: 'grey', paddingTop: '10px' }}
                />
              )}
              {authState === 'REGISTER_ENTER_EMAIL_AND_PASS' && (
                <IconInput
                  mb="20px"
                  value={phoneNumber}
                  onChange={e => setPhoneNumber(e.target.value)}
                  onKeyPress={handleEnterPress}
                  fontSize={18}
                  maxLength={10}
                  icon="phone"
                  placeholder="Enter Phone Number"
                  placeholderstyle={{ color: 'grey', paddingTop: '10px' }}
                />
              )}
              <CFSelect selector={dispatch.user.getIsLoggingIn}>
                {isLoggingIn => {
                  if (authState === 'REGISTER_ENTER_EMAIL_AND_PASS') {
                    return (
                      <PrimaryButton
                        selfCenter
                        label="SIGN UP"
                        disabled={isLoggingIn}
                        onClick={signupUser}
                      />
                    )
                  } else if (authState === 'EMAIL_LOCKED_ENTER_PASS') {
                    return (
                      <PrimaryButton
                        selfCenter
                        label="LOGIN"
                        disabled={isLoggingIn}
                        onClick={loginUser}
                      />
                    )
                  } else {
                    return (
                      <PrimaryButton
                        selfCenter
                        label="NEXT"
                        disabled={isCheckingEmail}
                        onClick={checkEmailExists}
                      />
                    )
                  }
                }}
              </CFSelect>
            </CFView>
            <ForgotPasswordButton mt="20px" />
          </Card>
        </PageContainer>
      </DefaultScreen>
    </SharedBackground>
  )
}
