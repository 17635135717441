import React from 'react'
import { CFIcon, CFView, ThemedText } from 'components'
import QuantityIcon from './QuantityIcon'

export default ({ reward, RemoveButton }) => {
  const { count, name, productId, totalDiscountSubtotal } = reward
  return (
    <CFView column mb="20px">
      <CFView row justifyBetween alignCenter>
        <CFView fill row alignStart>
          <QuantityIcon count={count} />
          <CFIcon icon="gift" color="red" fontSize="20px" mt="2px" ml="10px" />
          <ThemedText h5 bold ml="5px" pr="5px" mt="2px">
            {name}
          </ThemedText>
        </CFView>
        <CFView row alignStart>
          <CFView ph="10px" mt="2px">
            {productId ? (
              <ThemedText h5 bold>
                FREE
              </ThemedText>
            ) : (
              <ThemedText h5 bold>
                {`-$${totalDiscountSubtotal.toFixed(2)}`}
              </ThemedText>
            )}
          </CFView>
          {RemoveButton}
        </CFView>
      </CFView>
    </CFView>
  )
}
