import React from 'react'
import { CFView, ThemedText } from 'components'

export default ({ Tip10Button, Tip15Button, Tip20Button, TipNoneButton }) => {
  return (
    <CFView column p="10px">
      <ThemedText h5 mt="5px" mb="10px">
        Please select your tip:
      </ThemedText>
      <CFView row justifyBetween>
        <CFView w="22%">{Tip10Button}</CFView>
        <CFView w="22%">{Tip15Button}</CFView>
        <CFView w="22%">{Tip20Button}</CFView>
        <CFView w="22%">{TipNoneButton}</CFView>
      </CFView>
    </CFView>
  )
}
