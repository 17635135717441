import React from 'react'
import { CFSelect, CFView, ThemedText } from 'components'
import SectionHeader from './SectionHeader'
import { dispatch } from 'store'

export default ({ EditButton }) => {
  return (
    <CFView column>
      <SectionHeader title="Location" icon="map-marker-alt" />
      <CFView mh="10px" mb="20px">
        <CFView row mt="10px">
          <CFView fill column>
            <ThemedText h3 bold mb="2px">
              <CFSelect selector={dispatch.restaurant.getLocationName} />
            </ThemedText>
            <ThemedText h5 subtitle>
              <CFSelect selector={dispatch.restaurant.getAddress} />
            </ThemedText>
          </CFView>
          {EditButton}
        </CFView>
      </CFView>
    </CFView>
  )
}
