import React from 'react'
import { CFView, DefaultScreen, MobileScreen } from 'components'
import { dispatch } from 'store'

export default ({ children, ...props }) => {
  return (
    <>
      <MobileScreen>
        <CFView
          h="100%"
          w="100%"
          image={`url(${
            dispatch.restaurant.getImages().mainBgMobile
          }) center / cover no-repeat`}
          {...props}
        >
          {children}
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          minHeight="100vh"
          minWidth="100vw"
          image={`url(${
            dispatch.restaurant.getImages().mainBg
          }) center / cover no-repeat`}
          {...props}
        >
          {children}
        </CFView>
      </DefaultScreen>
    </>
  )
}
