import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { dispatch } from 'store'

export default () => {
  const history = useHistory()
  const { restaurantId = '', locationId = '', tableId = '' } = useParams()
  useEffect(() => {
    if (restaurantId) {
      dispatch.restaurant.setRestaurantId(restaurantId)
      localStorage.setItem('restaurantId', restaurantId)
    }
    if (locationId) {
      dispatch.restaurant.setLocationId(locationId)
      localStorage.setItem('locationId', locationId)
    }
    if (tableId) {
      dispatch.restaurant.setTableId(tableId)
      localStorage.setItem('tableId', tableId)
    } else {
      dispatch.restaurant.setTableId('')
      localStorage.removeItem('tableId')
    }
    history.push('/menu')
  })
  return null
}
