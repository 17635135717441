import React from 'react'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import { CFLogoAnimated, spinner } from 'images'

export default () => {
  return (
    <>
      <MobileScreen>
        <CFView h="100%" column center bg="white">
          <CFView center>
            <CFImage src={CFLogoAnimated} w="50%" />
          </CFView>
          <CFView center>
            <CFImage src={spinner} w="50%" />
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView h="100%" column center bg="white">
          <CFView center>
            <CFImage src={CFLogoAnimated} h="150px" />
          </CFView>
          <CFView center>
            <CFImage src={spinner} w="150px" />
          </CFView>
        </CFView>
      </DefaultScreen>
    </>
  )
}
