import React from 'react'
import { CFView, ThemedText } from 'components'
import { dispatch } from 'store'

export default ({ title, LeftButton, ...props }) => {
  return (
    <CFView
      h="50px"
      w="100%"
      bg={dispatch.restaurant.getStyles().theme === 'dark' ? 'black' : 'white'}
      boxShadow={
        dispatch.restaurant.getStyles().theme === 'dark'
          ? '0 1px 3px rgba(255,255,255,.8)'
          : '0 1px 3px rgba(0,0,0,.8)'
      }
      relative
      row
      alignCenter
      {...props}
    >
      <ThemedText h="50px" w="40px" center left={0} absolute>
        {LeftButton}
      </ThemedText>
      <CFView center fill>
        <ThemedText h3 bold truncate maxWidth="280px">
          {title}
        </ThemedText>
      </CFView>
    </CFView>
  )
}
