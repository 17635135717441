import React from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import {
  BackButton,
  Card,
  CFImage,
  CFView,
  DefaultScreen,
  MobileScreen,
  Navbar,
  OrderStatus,
  PageBackground,
  PageContainer,
  PageHeader,
  PrimaryButton,
  ThemedText,
} from 'components'
import { animatedTaco } from 'images'
import { dispatch } from 'store'

export default ({ location }) => {
  const history = useHistory()
  const order = location.state
  const {
    completedAt,
    orderId,
    orderNumber,
    orderType,
    total,
    paymentMethod,
    pointsEarned,
    tableNumber,
  } = order

  return (
    <PageBackground>
      <MobileScreen>
        <PageHeader
          title="Order Complete"
          LeftButton={<BackButton onClick={() => history.push('/menu')} />}
        />
        <PageContainer offset="header" pt="10%">
          <CFView relative w="100%">
            <OrderStatus orderId={orderId} />
          </CFView>
          <CFView w="100%" column alignCenter pt="30%">
            <CFImage
              h="150px"
              src={
                dispatch.restaurant.getImages().orderComplete
                  ? dispatch.restaurant.getImages().orderComplete
                  : animatedTaco
              }
              alt="Order Complete Taco"
            />
            <CFView
              h2
              bold
              mt="20px"
              color={dispatch.restaurant.getStyles().fontColor}
            >
              Thank you!
            </CFView>
            {total > 0 && paymentMethod === 'online' ? (
              <CFView h2 bold color={dispatch.restaurant.getStyles().fontColor}>
                Payment Successful!
              </CFView>
            ) : (
              <CFView h2 bold color={dispatch.restaurant.getStyles().fontColor}>
                Order Successful!
              </CFView>
            )}
            <Card w="90%" mv="40px" p="10px" column>
              <CFView fill row justifyBetween alignCenter mb="8px">
                <ThemedText h4>Order Number</ThemedText>
                <CFView h5 color="red" bold>
                  {orderNumber}
                </CFView>
              </CFView>
              {orderType === 'Dine-in' ? (
                <CFView fill row justifyBetween alignCenter>
                  <ThemedText h4>Table Number</ThemedText>
                  <CFView h5 color="red" bold>
                    {tableNumber}
                  </CFView>
                </CFView>
              ) : (
                <CFView fill row justifyBetween alignCenter>
                  <ThemedText h4>{`${orderType} Time`}</ThemedText>
                  <CFView h5 color="red" bold>
                    {moment(completedAt).format('LT, MMM D')}
                  </CFView>
                </CFView>
              )}
              {pointsEarned > 0 && (
                <CFView mt="8px" fill row justifyBetween alignCenter>
                  <ThemedText h4>Points Earned</ThemedText>
                  <CFView h5 color="red" bold>
                    {pointsEarned}
                  </CFView>
                </CFView>
              )}
            </Card>
          </CFView>
        </PageContainer>
      </MobileScreen>
      <DefaultScreen>
        <Navbar />
        <PageContainer offset="defaultNavbar">
          <CFView w="100%" p="20px" center>
            <Card w="480px" p="20px" column center>
              <CFView relative w="100%">
                <OrderStatus orderId={orderId} />
              </CFView>
              <CFView w="100%" column center pt="28%">
                <CFImage
                  h="150px"
                  src={
                    dispatch.restaurant.getImages().orderComplete
                      ? dispatch.restaurant.getImages().orderComplete
                      : animatedTaco
                  }
                  alt="Order Complete Taco"
                />
                <ThemedText bold mt="15px">
                  Thank you!
                </ThemedText>
                {paymentMethod === 'online' ? (
                  <ThemedText bold>Payment Successful!</ThemedText>
                ) : (
                  <ThemedText bold>Order Successful!</ThemedText>
                )}
                <Card w="90%" mv="20px" p="10px" column>
                  <CFView mb="8px" fill row justifyBetween alignCenter>
                    <ThemedText h4>Your Order Number</ThemedText>
                    <CFView h5 color="red" bold>
                      {orderNumber}
                    </CFView>
                  </CFView>
                  {orderType === 'Dine-in' ? (
                    <CFView fill row justifyBetween alignCenter>
                      <ThemedText h4>Table Number</ThemedText>
                      <CFView h5 color="red" bold>
                        {tableNumber}
                      </CFView>
                    </CFView>
                  ) : (
                    <CFView fill row justifyBetween alignCenter>
                      <ThemedText h4>{`${orderType} Time`}</ThemedText>
                      <CFView h5 color="red" bold>
                        {moment(completedAt).format('LT, MMM D')}
                      </CFView>
                    </CFView>
                  )}
                  {pointsEarned > 0 && (
                    <CFView mt="8px" fill row justifyBetween alignCenter>
                      <ThemedText h4>Points Earned</ThemedText>
                      <CFView h5 color="red" bold>
                        {pointsEarned}
                      </CFView>
                    </CFView>
                  )}
                </Card>
              </CFView>
              <PrimaryButton
                mt="20px"
                label="FINISH"
                onClick={() => history.push('/menu')}
              />
            </Card>
          </CFView>
        </PageContainer>
      </DefaultScreen>
    </PageBackground>
  )
}
