import React from 'react'
import { CFSelect, CFView } from 'components'
import CheckSelectIcon from './CheckSelectIcon'
import RadioSelectIcon from './RadioSelectIcon'
import { dispatch } from 'store'
import { COLOR } from 'styles'

export default ({ modifier, choice, choiceIndex, ...props }) => {
  const { name, selected, price } = choice
  return (
    <CFView h="40px" row justifyBetween alignCenter pointer {...props}>
      <CFView
        h3
        truncate
        color={
          selected
            ? COLOR.red
            : dispatch.restaurant.getStyles().theme === 'dark'
            ? 'white'
            : 'black'
        }
      >
        {name}
      </CFView>
      <CFSelect
        selector={() =>
          dispatch.restaurant.getIsChoiceActive(modifier.id, choiceIndex)
        }
      >
        {isChoiceActive => (
          <CFView row>
            <CFView justifyEnd alignCenter>
              {isChoiceActive ? (
                price > 0 && (
                  <CFView
                    h4
                    black
                    color={
                      selected
                        ? COLOR.red
                        : dispatch.restaurant.getStyles().theme === 'dark'
                        ? 'white'
                        : 'black'
                    }
                    pr="10px"
                  >
                    +${Number(price).toFixed(2)}
                  </CFView>
                )
              ) : (
                <CFView
                  h4
                  bold
                  color={
                    dispatch.restaurant.getStyles().theme === 'dark'
                      ? 'white'
                      : 'black'
                  }
                >
                  SOLD OUT
                </CFView>
              )}
            </CFView>
            {isChoiceActive &&
              (modifier.required === 1 && modifier.maxSelect === 1 ? (
                <RadioSelectIcon selected={selected} />
              ) : (
                <CheckSelectIcon selected={selected} />
              ))}
          </CFView>
        )}
      </CFSelect>
    </CFView>
  )
}
