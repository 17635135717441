import React from 'react'
import { CFSelect, CFView, ThemedText } from 'components'
import SectionHeader from './SectionHeader'
import { dispatch } from 'store'

export default ({ PickupButton, DeliveryButton }) => {
  return (
    <CFSelect
      selector={{
        orderType: dispatch.user.getOrderType,
        deliveryOpen: dispatch.restaurant.getDeliveryOpen,
        deliveryHoursTime: dispatch.restaurant.getDeliveryHoursTime,
        minOrder: dispatch.restaurant.getMinOrder,
      }}
    >
      {({ orderType, deliveryOpen, deliveryHoursTime, minOrder }) => (
        <CFView column pb="5px">
          <SectionHeader title="Order Type" icon="utensils" />
          <CFView row mv="20px" mh="10px" justifyBetween>
            <CFView center w="48%">
              {PickupButton}
            </CFView>
            <CFView center w="48%">
              {DeliveryButton}
            </CFView>
          </CFView>
          {orderType === 'Delivery' && (
            <CFView column mh="10px" mb="15px">
              {deliveryHoursTime === 'Not Available' || !deliveryOpen ? (
                <ThemedText h5 subtitle>
                  Delivery is closed today.
                </ThemedText>
              ) : (
                <ThemedText
                  h5
                  subtitle
                >{`Delivery hours are ${deliveryHoursTime}.`}</ThemedText>
              )}
              <ThemedText
                h5
                subtitle
                mt="10px"
              >{`Minimum subtotal for delivery is $${minOrder}.`}</ThemedText>
              {dispatch.restaurant.getDoorDashEnabled() && (
                <ThemedText h5 subtitle mt="10px">
                  {'Minimum tip for delivery driver is 10%.'}
                </ThemedText>
              )}
            </CFView>
          )}
        </CFView>
      )}
    </CFSelect>
  )
}
