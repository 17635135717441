import React from 'react'
import { CFSelect, CFView, ThemedText } from 'components'
import SectionHeader from './SectionHeader'
import { dispatch } from 'store'

export default ({ EditButton }) => {
  return (
    <CFSelect
      selector={{
        orderType: dispatch.user.getOrderType,
        orderTime: dispatch.user.getOrderTime,
        waitTime: dispatch.restaurant.getWaitTime,
        deliveryTime: dispatch.restaurant.getDeliveryTime,
      }}
    >
      {({ orderType, orderTime, waitTime, deliveryTime }) => (
        <CFView column>
          <SectionHeader title={`${orderType} Time`} icon="calendar-alt" />
          <CFView mh="10px" mb="15px">
            <CFView row mt="10px">
              <CFView column fill>
                <ThemedText h3 bold>
                  {orderTime}
                </ThemedText>
                {orderTime === 'ASAP' && (
                  <ThemedText h5 subtitle mb="5px">
                    {orderType === 'Delivery'
                      ? `Estimated delivery time is ${
                          waitTime + deliveryTime
                        } minutes.`
                      : `Estimated pickup time is ${waitTime} minutes.`}
                  </ThemedText>
                )}
              </CFView>
              {EditButton}
            </CFView>
          </CFView>
        </CFView>
      )}
    </CFSelect>
  )
}
