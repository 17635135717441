import React from 'react'
import { CFIcon, CFView } from 'components'
import { dispatch } from 'store'
import { COLOR } from 'styles'

export default ({ label, icon, ...props }) => {
  return (
    <CFView
      h="100px"
      bg={
        dispatch.restaurant.getStyles().brandColor
          ? dispatch.restaurant.getStyles().brandColor
          : COLOR.brand
      }
      br="12px"
      mb="15px"
      center
      boxShadow="1px 1px 3px rgba(0,0,0,.8)"
      {...props}
    >
      <CFView white bold column center>
        <CFView mb="10px" white bold fontSize="24px">
          <CFIcon icon={icon} color="white" />
        </CFView>
        {label}
      </CFView>
    </CFView>
  )
}
