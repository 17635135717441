import React from 'react'
import { CFSelect, CFView, HorizontalLine, ThemedText } from 'components'
import { dispatch } from 'store'
import { COLOR } from 'styles'

export default () => {
  return (
    <CFSelect
      selector={{
        subTotal: dispatch.user.getCartSubtotalAfterRewards,
        firstOrderDiscount: dispatch.user.getFirstOrderDiscountAmount,
        pickupDiscount: dispatch.user.getPickupDiscountAmount,
        promoDiscount: dispatch.user.getPromoDiscountAmount,
        deliveryFee: dispatch.restaurant.getDeliveryFee,
        bagFee: dispatch.user.getCartBagFee,
        tax: dispatch.user.getCartTax,
        tip: dispatch.user.getCartTip,
        total: dispatch.user.getCartTotal,
        orderType: dispatch.user.getOrderType,
      }}
    >
      {({
        subTotal,
        firstOrderDiscount,
        pickupDiscount,
        promoDiscount,
        deliveryFee,
        bagFee,
        tax,
        tip,
        total,
        orderType,
      }) => (
        <CFView column>
          <CFView mt="10px" mh="10px" mb="20px">
            <CFView row justifyBetween>
              <ThemedText h5>Subtotal</ThemedText>
              <ThemedText h5 subtitle>{`$${subTotal.toFixed(2)}`}</ThemedText>
            </CFView>
            {firstOrderDiscount > 0 && (
              <CFView row justifyBetween mv="2px">
                <ThemedText h5>First Order Discount</ThemedText>
                <ThemedText h5 subtitle>{`-$${firstOrderDiscount.toFixed(
                  2
                )}`}</ThemedText>
              </CFView>
            )}
            {pickupDiscount > 0 && (
              <CFView row justifyBetween mv="2px">
                <ThemedText h5>Pickup Discount</ThemedText>
                <ThemedText h5 subtitle>{`-$${pickupDiscount.toFixed(
                  2
                )}`}</ThemedText>
              </CFView>
            )}
            {promoDiscount > 0 && (
              <CFView row justifyBetween mv="2px">
                <ThemedText h5>Promo Discount</ThemedText>
                <ThemedText h5 subtitle>{`-$${promoDiscount.toFixed(
                  2
                )}`}</ThemedText>
              </CFView>
            )}
            {orderType === 'Delivery' && (
              <CFView row justifyBetween mv="2px">
                <ThemedText h5>Delivery Fee</ThemedText>
                <ThemedText h5 subtitle>{`$${deliveryFee.toFixed(
                  2
                )}`}</ThemedText>
              </CFView>
            )}
            <CFView row justifyBetween mv="2px">
              <ThemedText h5>Taxes</ThemedText>
              <ThemedText h5 subtitle>{`$${tax.toFixed(2)}`}</ThemedText>
            </CFView>
            {bagFee > 0 && (
              <CFView row justifyBetween mv="2px">
                <ThemedText h5>Bag Fee</ThemedText>
                <ThemedText h5 subtitle>{`$${bagFee.toFixed(2)}`}</ThemedText>
              </CFView>
            )}
            {tip > 0 && (
              <CFView row justifyBetween mv="2px">
                <ThemedText h5>Tip</ThemedText>
                <ThemedText h5 subtitle>{`$${tip.toFixed(2)}`}</ThemedText>
              </CFView>
            )}
            <HorizontalLine mv="5px" />
            <CFView row justifyBetween>
              <ThemedText bold>Total</ThemedText>
              <CFView bold color={COLOR.red}>{`$${total.toFixed(2)}`}</CFView>
            </CFView>
          </CFView>
        </CFView>
      )}
    </CFSelect>
  )
}
