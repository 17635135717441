import React from 'react'
import { useHistory } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import {
  BackButton,
  CFImage,
  CFSelect,
  CFView,
  DefaultScreen,
  MobileScreen,
  PageHeader,
  PageContainer,
  PageBackground,
} from 'components'
import LocationButton from './LocationButton'
import { dispatch } from 'store'

export default ({ onRequestClose }) => {
  const history = useHistory()
  const isMobile = useMediaQuery({ maxWidth: 767 })

  const changeLocation = locationId => {
    dispatch.restaurant.setLocationId(locationId)
    history.push('/menu')
    if (!isMobile) {
      onRequestClose()
    }
  }

  return (
    <>
      <MobileScreen>
        <PageBackground>
          <PageHeader
            title="Locations"
            LeftButton={<BackButton onClick={history.goBack} />}
          />
          <PageContainer offset="header" column>
            <CFSelect selector={dispatch.restaurant.getLocations}>
              {locations => {
                return (
                  <CFView
                    h="100%"
                    w="100%"
                    column
                    alignCenter
                    pv="30px"
                    overflowY="auto"
                    style={{ '-webkit-overflow-scrolling': 'touch' }}
                  >
                    <CFImage
                      h="120px"
                      src={dispatch.restaurant.getImages().logo}
                      alt="Logo"
                    />
                    <CFView
                      h3
                      bold
                      mv="20px"
                      color={dispatch.restaurant.getStyles().fontColor}
                    >
                      Please choose a location:
                    </CFView>
                    <CFView w="90%">
                      {Object.values(locations)
                        .filter(location => !location.inactive)
                        .sort((a, b) => {
                          if (a.locationName < b.locationName) {
                            return -1
                          }
                          if (a.locationName > b.locationName) {
                            return 1
                          }
                          return 0
                        })
                        .map(location => (
                          <LocationButton
                            key={location.id}
                            name={location.locationName}
                            address={location.address}
                            onClick={() => changeLocation(location.id)}
                          />
                        ))}
                    </CFView>
                  </CFView>
                )
              }}
            </CFSelect>
          </PageContainer>
        </PageBackground>
      </MobileScreen>
      <DefaultScreen>
        <CFView w="480px" pt="20px" pb="20px" ph="20px" column>
          <CFSelect selector={dispatch.restaurant.getLocations}>
            {locations => {
              return (
                <CFView fill column center>
                  <CFImage
                    h="100px"
                    src={dispatch.restaurant.getImages().logo}
                    alt="Logo"
                  />
                  <CFView
                    h4
                    bold
                    mv="20px"
                    color={
                      dispatch.restaurant.getStyles().brandColor
                        ? dispatch.restaurant.getStyles().fontColor
                        : 'black'
                    }
                  >
                    Please choose a location:
                  </CFView>
                  {Object.values(locations)
                    .filter(location => !location.inactive)
                    .sort((a, b) => {
                      if (a.locationName < b.locationName) {
                        return -1
                      }
                      if (a.locationName > b.locationName) {
                        return 1
                      }
                      return 0
                    })
                    .map(location => (
                      <LocationButton
                        key={location.id}
                        name={location.locationName}
                        address={location.address}
                        onClick={() => changeLocation(location.id)}
                      />
                    ))}
                </CFView>
              )
            }}
          </CFSelect>
        </CFView>
      </DefaultScreen>
    </>
  )
}
