import React from 'react'
import { CFIcon, CFInput, CFView } from 'components'

export default React.forwardRef((props, ref) => {
  const {
    icon,
    disabled,
    onKeyPress,
    onChange,
    value,
    type,
    maxLength,
    placeholder,
    placeholderstyle,
    ...rest
  } = props
  return (
    <CFView
      row
      bg={disabled ? 'rgba(0,0,0,0.4)' : 'rgba(245,245,245,.8)'}
      h="42px"
      br="5px"
      ph="10px"
      boxShadow="2px 2px 4px 0 rgba(0,0,0,.5) inset"
      {...rest}
    >
      <CFView w="40px" h="40px" center m="auto 0">
        <CFIcon icon={icon} />
      </CFView>
      <CFInput
        w="100%"
        forwardedRef={ref}
        type={type}
        disabled={disabled}
        onKeyPress={onKeyPress}
        onChange={onChange}
        value={value}
        maxLength={maxLength}
        placeholder={placeholder}
        placeholderstyle={placeholderstyle}
      />
    </CFView>
  )
})
