export const codefusionlogo =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1582533988/general/codefusionlogo.png'
export const CFLogoAnimated =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1598313781/general/Transparent_CodeFusion_Logo.png'
export const spinner =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1582533988/general/spinner.gif'
export const recentOrders =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1585627992/general/recentOrders.jpg'
export const searchMenu =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1598511628/general/Search.png'
export const cartEmpty =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1582533988/general/cartEmpty.png'
export const sharedBg =
  'https://d24rddnpxh8ji7.cloudfront.net/marblebgdefault.jpg'
export const sharedBgMobile =
  'https://d24rddnpxh8ji7.cloudfront.net/marblebgdefault.jpg'
export const animatedTaco =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1582790345/general/taco.gif'
export const requestConfirm =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1583285809/general/nigiri.gif'
export const Hero404 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1582791158/general/404Hero.png'

// Order Status
export const orderStatusMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1554666730/general/OrderStatusMeter.png'
export const orderStatusProgress =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1554602154/general/OrderStatusProgress.png'

// Rewards
export const emptyMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1582533988/general/emptyMeter.png'
export const pointsMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1582533988/general/pointsMeter.png'
export const leftRewardArrow =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardArrowLeft.png'
export const rightRewardArrow =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardArrowRight.png'
export const rewardButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1583620865/general/rewardButton.gif'
