import React from 'react'
import {
  CFImage,
  CFSelect,
  CFView,
  DefaultScreen,
  IconLink,
  LocationSelect,
  MobileScreen,
} from 'components'
import { COLOR } from 'styles'
import { dispatch } from 'store'

export default props => {
  return (
    <>
      <MobileScreen>
        <CFView
          h="64px"
          w="100%"
          bg={
            dispatch.restaurant.getStyles().theme === 'dark' ? 'black' : 'white'
          }
          row
          justifyBetween
          alignCenter
          boxShadow={
            dispatch.restaurant.getStyles().theme === 'dark'
              ? '0 -1px 3px rgba(255,255,255,.8)'
              : '0 -1px 3px rgba(0,0,0,.8)'
          }
          {...props}
        >
          <IconLink label="MENU" to="/menu" icon="utensils" />
          <CFSelect
            selector={[
              dispatch.restaurant.getIsDineIn,
              dispatch.restaurant.getVirtualKioskEnabled,
            ]}
          >
            {([isDineIn, virtualKioskEnabled]) =>
              isDineIn && !virtualKioskEnabled ? (
                <IconLink label="REQUESTS" to="/requests" icon="comment-dots" />
              ) : null
            }
          </CFSelect>
          <CFSelect selector={dispatch.restaurant.getRewardsEnabled}>
            <IconLink label="REWARDS" to="/rewards" icon="gift" />
          </CFSelect>
          <IconLink label="ORDERS" to="/order_history" icon="receipt" />
          <CFSelect selector={dispatch.user.getIsLoggedIn}>
            {isLoggedIn =>
              isLoggedIn ? (
                <IconLink label="USER" to="/user" icon="user" />
              ) : (
                <IconLink label="LOGIN" icon="sign-in-alt" to="/auth/menu" />
              )
            }
          </CFSelect>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="70px"
          w="100%"
          bg={
            dispatch.restaurant.getStyles().theme === 'dark'
              ? COLOR.darkOpacity
              : COLOR.lightOpacity
          }
          boxShadow={
            dispatch.restaurant.getStyles().theme === 'dark'
              ? '0 1px 3px rgba(255,255,255,.8)'
              : '0 1px 3px rgba(0,0,0,.8)'
          }
          center
          relative
          zIndex={98}
          {...props}
        >
          <CFView w="100%" maxWidth="1200px" row justifyBetween alignCenter>
            <CFView center>
              <a href={dispatch.restaurant.getLandingUrl()}>
                <CFView row relative top="40px">
                  <CFImage
                    h="140px"
                    w="140px"
                    src={dispatch.restaurant.getImages().logo}
                  />
                  <CFImage
                    h="50px"
                    contain
                    src={dispatch.restaurant.getImages().logoTitle}
                  />
                </CFView>
              </a>
              <CFSelect selectorNot={dispatch.restaurant.getIsDineIn}>
                <CFSelect
                  selector={dispatch.restaurant.getHasMultipleLocations}
                >
                  <CFView ml="20px">
                    <LocationSelect />
                  </CFView>
                </CFSelect>
              </CFSelect>
            </CFView>
            <CFView w="480px" row>
              <IconLink label="MENU" to="/menu" icon="utensils" />
              <CFSelect selector={dispatch.user.getCartCount}>
                {cartCount => (
                  <IconLink
                    label={`CART (${cartCount})`}
                    to="/cart"
                    icon="shopping-cart"
                  />
                )}
              </CFSelect>
              <CFSelect selector={dispatch.user.getIsLoggedIn}>
                {isLoggedIn =>
                  isLoggedIn ? (
                    <IconLink label="USER" to="/user" icon="user" />
                  ) : (
                    <IconLink
                      label="LOGIN"
                      icon="sign-in-alt"
                      to="/auth/menu"
                    />
                  )
                }
              </CFSelect>
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </>
  )
}
