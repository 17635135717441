import React from 'react'
import { CFView, DefaultScreen, MobileScreen } from 'components'
import { dispatch } from 'store'
import { COLOR } from 'styles'

export default ({ name, price, ...props }) => {
  return (
    <>
      <MobileScreen>
        <CFView
          bg={
            dispatch.restaurant.getStyles().brandColor
              ? dispatch.restaurant.getStyles().brandColor
              : COLOR.brand
          }
          w="100%"
          h="45px"
          br="25px"
          p="5px"
          row
          center
          relative
          boxShadow="1px 1px 3px rgba(0,0,0,.8)"
          {...props}
        >
          <CFView white bold>
            ADD TO CART
          </CFView>
          <CFView white bold absolute right={20}>
            {`$${price.toFixed(2)}`}
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView w="100%" ph="10px" center>
          <CFView
            relative
            bg={
              dispatch.restaurant.getStyles().brandColor
                ? dispatch.restaurant.getStyles().brandColor
                : COLOR.brand
            }
            fill
            h="45px"
            br="25px"
            row
            center
            boxShadow="1px 1px 3px rgba(0,0,0,.8)"
            hover
            {...props}
          >
            <CFView white bold>
              ADD TO CART
            </CFView>
            <CFView white bold absolute right={20}>
              {`$${price.toFixed(2)}`}
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </>
  )
}
