import React from 'react'
import { CFView } from 'components'
import { dispatch } from 'store'

export default props => {
  return (
    <CFView
      h={1}
      bg={dispatch.restaurant.getStyles().theme === 'dark' ? 'white' : 'black'}
      selfStretch
      {...props}
    />
  )
}
