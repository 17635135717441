import React from 'react'
import { CFSelect } from 'components'
import { dispatch } from 'store'

export default class SubscribeRestaurantLocation extends React.PureComponent {
  locationId = null
  unsubscribe = null
  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe()
  }
  render() {
    return (
      <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
        {locationId => {
          if (locationId !== this.locationId) {
            if (locationId) {
              this.unsubscribe && this.unsubscribe()
              this.unsubscribe = dispatch.restaurant.subscribeRestaurantLocation()
            }
            this.locationId = locationId
          }
          return null
        }}
      </CFSelect>
    )
  }
}
