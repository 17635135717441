import React, { useState } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import {
  BackButton,
  CFIcon,
  CFSelect,
  CFView,
  DefaultScreen,
  LabelInput,
  MobileScreen,
  PageContainer,
  PageHeader,
  PrimaryButton,
  SharedBackground,
} from 'components'
import { dispatch } from 'store'
import { COLOR } from 'styles'

export default ({ onRequestClose }) => {
  const history = useHistory()
  const params = useParams()
  const location = useLocation()
  const { nextRoute } = params
  const isMobile = useMediaQuery({ maxWidth: 767 })

  const [cardNumber, setCardNumber] = useState('')
  const [expiry, setExpiry] = useState('')
  const [cvc, setCVC] = useState('')
  const [saving, setSaving] = useState(false)

  const saveCreditCard = async () => {
    if (!isFormValid()) {
      return
    }
    setSaving(true)
    try {
      const card = {
        number: cardNumber,
        cvc,
        exp_month: expiry.slice(0, 2),
        exp_year: `20${expiry.slice(2, 4)}`,
      }
      if (dispatch.user.getHasPayment()) {
        await dispatch.user.updateCreditCard(card)
      } else {
        await dispatch.user.addCreditCard(card)
      }
      dispatch.notification.setMessage({
        message: 'Credit / debit card saved!',
        level: 'success',
      })
      if (isMobile) {
        history.push(`/${nextRoute}`)
      } else {
        onRequestClose()
      }
    } catch (e) {
      alert('Sorry, we could not save your credit / debit card.')
    } finally {
      setSaving(false)
    }
  }

  const deleteCreditCard = async () => {
    if (window.confirm('Are you sure you want to delete?')) {
      try {
        await dispatch.user.deleteCreditCard()
        dispatch.notification.setMessage({
          message: `Credit / debit card deleted!`,
          level: 'success',
        })
      } catch (e) {
        alert('Error deleting card.')
      }
    }
  }

  const isFormValid = () => {
    if (isNaN(Number(cardNumber))) {
      alert('Card number must be a number.')
      return false
    }
    if (cardNumber.length < 16) {
      alert('Card number must be 16 digits.')
      return false
    }
    if (isNaN(Number(expiry))) {
      alert('Expiry must be a number.')
      return false
    }
    if (expiry.length < 4) {
      alert('Expiry must be a 4 digits.')
      return false
    }
    if (isNaN(Number(cvc))) {
      alert('CVC must be a number.')
      return false
    }
    if (cvc.length < 3) {
      alert('CVC must be a 3 digits.')
      return false
    }
    return true
  }

  const navigateBack = () => {
    if (!dispatch.user.getHasPayment()) {
      dispatch.user.setPaymentMethod('')
    }
    history.push(`/${nextRoute}`)
  }

  const formatExpiry = exp => {
    if (exp.length < 3) {
      return exp
    }
    return exp.substr(0, 2) + '/' + exp.substr(2, 2)
  }

  return (
    <>
      <MobileScreen>
        <SharedBackground>
          <PageHeader
            title="Credit / Debit Card"
            LeftButton={<BackButton onClick={navigateBack} />}
          />
          <PageContainer offset="header" pt="40px" pb="20px" ph="20px" column>
            <CFView fill>
              <LabelInput
                mb="20px"
                label="Card Number"
                color="black"
                maxLength={19}
                value={cardNumber.replace(/(.{4})/g, '$1 ').trim()}
                onChange={e => setCardNumber(e.target.value.replace(/\D/g, ''))}
                placeholder="**** **** **** ****"
              />
              <CFView row mb="40px">
                <LabelInput
                  mr="20px"
                  label="Exp"
                  color="black"
                  maxLength={5}
                  value={formatExpiry(expiry)}
                  onChange={e => setExpiry(e.target.value.replace(/\D/g, ''))}
                  placeholder="MM/YY"
                />
                <LabelInput
                  label="CVC"
                  color="black"
                  maxLength={3}
                  value={cvc}
                  onChange={e => setCVC(e.target.value.replace(/\D/g, ''))}
                  placeholder="***"
                />
              </CFView>
              {location.pathname === '/credit_card/user' && (
                <CFSelect selector={dispatch.user.getHasPayment}>
                  <CFView
                    br="5px"
                    p="10px"
                    mb="40px"
                    bg={COLOR.darkOpacity}
                    bowShadow={'1px 1px 3px rgba(255,255,255,.8)'}
                    row
                    justifyBetween
                    alignCenter
                  >
                    <CFView column>
                      <CFView white bold mb="8px">
                        Current Card
                      </CFView>
                      <CFView
                        white
                      >{`**** **** **** ${dispatch.user.getLast4()}`}</CFView>
                    </CFView>
                    <CFView hover onClick={deleteCreditCard}>
                      <CFIcon
                        icon="times-circle"
                        color={COLOR.red}
                        fontSize={28}
                      />
                    </CFView>
                  </CFView>
                </CFSelect>
              )}
            </CFView>
            <PrimaryButton
              selfCenter
              label={saving ? 'SAVING...' : 'SAVE'}
              onClick={saveCreditCard}
              disabled={saving}
            />
          </PageContainer>
        </SharedBackground>
      </MobileScreen>
      <DefaultScreen>
        <CFView w="420px" pt="40px" pb="20px" ph="20px" column>
          <LabelInput
            mb="20px"
            label="Card Number"
            color="black"
            maxLength={19}
            value={cardNumber.replace(/(.{4})/g, '$1 ').trim()}
            onChange={e => setCardNumber(e.target.value.replace(/\D/g, ''))}
            placeholder="**** **** **** ****"
          />
          <CFView mb="40px" row>
            <LabelInput
              mr="20px"
              label="Exp"
              color="black"
              maxLength={5}
              value={formatExpiry(expiry)}
              onChange={e => setExpiry(e.target.value.replace(/\D/g, ''))}
              placeholder="MM/YY"
            />
            <LabelInput
              label="CVC"
              color="black"
              maxLength={3}
              value={cvc}
              onChange={e => setCVC(e.target.value.replace(/\D/g, ''))}
              placeholder="***"
            />
          </CFView>
          {location.pathname === '/user' && (
            <CFSelect selector={dispatch.user.getHasPayment}>
              <CFView
                br="5px"
                p="10px"
                mb="40px"
                bg={COLOR.darkOpacity}
                bowShadow={'1px 1px 3px rgba(255,255,255,.8)'}
                row
                justifyBetween
                alignCenter
              >
                <CFView column>
                  <CFView white bold mb="8px">
                    Current Card
                  </CFView>
                  <CFView
                    white
                  >{`**** **** **** ${dispatch.user.getLast4()}`}</CFView>
                </CFView>
                <CFView hover onClick={deleteCreditCard}>
                  <CFIcon icon="times-circle" color={COLOR.red} fontSize={28} />
                </CFView>
              </CFView>
            </CFSelect>
          )}
          <PrimaryButton
            selfCenter
            label={saving ? 'SAVING...' : 'SAVE'}
            onClick={saveCreditCard}
            disabled={saving}
          />
        </CFView>
      </DefaultScreen>
    </>
  )
}
