import axios from 'axios'

export function createOrderAndCharge(serverUrl, authToken, restaurantId, locationId, order) {
  const config = {
    url: `${serverUrl}/api/create_order_v2`,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: authToken,
    },
    data: {
      ...order,
      restaurantId,
      locationId,
    },
  }
  return axios(config)
}

export function createGuestOrder(serverUrl, authToken, restaurantId, locationId, order) {
  const config = {
    url: `${serverUrl}/guest/create_order`,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: authToken,
    },
    data: {
      ...order,
      restaurantId,
      locationId,
    },
  }
  return axios(config)
}

export function createCustomerWithStripe(serverUrl, authToken, cardToken) {
  const config = {
    url: `${serverUrl}/api/create_customer`,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: authToken,
    },
    data: cardToken,
  }
  return axios(config)
}

export function updateCustomerWithStripe(serverUrl, authToken, cardToken) {
  const config = {
    url: `${serverUrl}/api/update_customer`,
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: authToken,
    },
    data: cardToken,
  }
  return axios(config)
}

export function sendStaffNotification(serverUrl, authToken, message) {
  const config = {
    url: `${serverUrl}/guest/create_staff_notification`,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: authToken,
    },
    data: message,
  }
  return axios(config)
}

export function estimateDelivery(serverUrl, authToken, data) {
  const config = {
    url: `${serverUrl}/api/estimate_delivery`,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: authToken,
    },
    data,
  }
  return axios(config)
}

export function createDelivery(serverUrl, authToken, data) {
  const config = {
    url: `${serverUrl}/api/create_delivery`,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: authToken,
    },
    data,
  }
  return axios(config)
}

export function deactivateUser(serverUrl, authToken, data) {
  const config = {
    url: `${serverUrl}/api/deactivate_user`,
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      Authorization: authToken,
    },
    data,
  }
  return axios(config)
}
