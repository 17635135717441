import React from 'react'
import { NavLink } from 'react-router-dom'
import isExternal from 'is-url-external'
import { CFIcon, CFView, DefaultScreen, MobileScreen } from 'components'
import { COLOR } from 'styles'
import { dispatch } from 'store'

export default ({ label, icon, color, to, ...props }) => {
  return (
    <>
      <MobileScreen>
        {isExternal(to) ? (
          <a
            href={to}
            style={{
              color:
                dispatch.restaurant.getStyles().theme === 'dark'
                  ? 'white'
                  : 'black',
              flex: 1,
              textDecoration: 'none',
            }}
          >
            <CFView column center fill>
              <CFIcon icon={icon} fontSize="24px" />
              <CFView hemi h6 mt="3px">
                {label}
              </CFView>
            </CFView>
          </a>
        ) : (
          <NavLink
            to={to}
            activeStyle={{
              color: COLOR.red,
            }}
            style={{
              color:
                dispatch.restaurant.getStyles().theme === 'dark'
                  ? 'white'
                  : 'black',
              flex: 1,
              textDecoration: 'none',
            }}
            {...props}
          >
            <CFView column center fill>
              <CFIcon icon={icon} fontSize="24px" />
              <CFView hemi fontSize="13px" mt="4px">
                {label}
              </CFView>
            </CFView>
          </NavLink>
        )}
      </MobileScreen>
      <DefaultScreen>
        {isExternal(to) ? (
          <a
            href={to}
            style={{
              color:
                dispatch.restaurant.getStyles().theme === 'dark'
                  ? 'white'
                  : 'black',
              flex: 1,
              textDecoration: 'none',
            }}
          >
            <CFView row center fill>
              <CFIcon icon={icon} fontSize="24px" />
              <CFView hemi h3 bold ml="10px">
                {label}
              </CFView>
            </CFView>
          </a>
        ) : (
          <NavLink
            to={to}
            activeStyle={{
              color: COLOR.red,
            }}
            style={{
              color:
                dispatch.restaurant.getStyles().theme === 'dark'
                  ? 'white'
                  : 'black',
              flex: 1,
              textDecoration: 'none',
            }}
            {...props}
          >
            <CFView row center fill>
              <CFIcon icon={icon} fontSize="24px" />
              <CFView hemi h3 bold ml="10px">
                {label}
              </CFView>
            </CFView>
          </NavLink>
        )}
      </DefaultScreen>
    </>
  )
}
