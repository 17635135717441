import { init } from '@rematch/core'
import { plugins } from 'cf-core'
import { plugin as restaurantPlugin } from 'cf-models-restaurant'
import { plugin as userPlugin } from 'cf-models-user'
import * as models from 'models'
import ENV from 'ENV'

const restaurantId = localStorage.getItem('restaurantId')
const locationId = localStorage.getItem('locationId')
const tableId = localStorage.getItem('tableId')
const choicesCart = JSON.parse(localStorage.getItem('cart')) || []

export const store = init({
  models,
  plugins: [
    plugins.actions,
    restaurantPlugin(restaurantId, locationId, tableId, false),
    userPlugin(ENV.STRIPE_KEY, ENV.SERVER_URL, ENV.API_KEY, choicesCart),
  ],
})

export const { dispatch, getState } = store
