import React from 'react'
import { CFView, DefaultScreen, MobileScreen } from 'components'
import { sharedBg, sharedBgMobile } from 'images'
import { dispatch } from 'store'

export default ({ children, ...props }) => {
  return (
    <>
      <MobileScreen>
        <CFView
          h="100%"
          w="100%"
          image={`url(${
            dispatch.restaurant.getStyles().brandColor
              ? dispatch.restaurant.getImages().mainBgMobile
              : sharedBgMobile
          }) center / cover no-repeat`}
          {...props}
        >
          {children}
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          minHeight="100vh"
          minWidth="100vw"
          image={`url(${
            dispatch.restaurant.getStyles().brandColor
              ? dispatch.restaurant.getImages().mainBg
              : sharedBg
          }) center / cover no-repeat`}
          {...props}
        >
          {children}
        </CFView>
      </DefaultScreen>
    </>
  )
}
