import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  CFImage,
  CFSelect,
  CFView,
  DefaultScreen,
  MobileScreen,
  Navbar,
  PageContainer,
  PrimaryButton,
} from 'components'
import RightArrowButton from './RightArrowButton'
import LeftArrowButton from './LeftArrowButton'
import RedeemButton from './RedeemButton'
import { emptyMeter, pointsMeter } from 'images'
import { dispatch } from 'store'

export default props => {
  const [rewardIndex, setRewardIndex] = useState(0)
  const [pointsMeterWidth, setPointsMeterWidth] = useState(0)
  const { pointsWithPromoApplied } = props

  useEffect(() => {
    updatePointsMeterWidth()
  }, [pointsWithPromoApplied])

  const redeemReward = async reward => {
    const { valid, name, id } = reward
    if (valid) {
      await dispatch.user.addPromo(id)
      updatePointsMeterWidth()
      dispatch.notification.setMessage({
        message: `${name} redeemed!`,
        level: 'success',
      })
    } else {
      alert(reward.message)
    }
  }

  const updatePointsMeterWidth = () => {
    requestAnimationFrame(() => {
      const points = dispatch.user.getPointsWithPromoApplied()
      const pointsMeterWidth =
        points >= dispatch.user.getPointsMax()
          ? 1
          : (1 * points) / dispatch.user.getPointsMax()
      setPointsMeterWidth(pointsMeterWidth)
    })
  }

  const history = useHistory()
  const params = useParams()
  const { nextRoute } = params

  return (
    <CFSelect selector={dispatch.user.getRewardsWithDetailsSorted}>
      {rewards => {
        const reward = rewards[rewardIndex] || {}
        const { imageUrl, requiredPoints, name } = reward
        return (
          <>
            <MobileScreen>
              <PageContainer
                offset={nextRoute === 'cart' ? '0px' : 'navbar'}
                image={`url(${
                  dispatch.restaurant.getImages().rewardBg
                }) center / cover no-repeat`}
              >
                <CFView center absolute top="43%" transform="translateY(-50%)">
                  <CFImage
                    w="75%"
                    src={dispatch.restaurant.getImages().rewardContainer}
                    alt="RewardContainer"
                    zIndex={0}
                  />
                </CFView>
                <CFView
                  absolute
                  w="100%"
                  center
                  top="43%"
                  transform="translateY(-50%)"
                >
                  {imageUrl ? (
                    <CFImage
                      w="50%"
                      br="200px"
                      zIndex={1}
                      src={imageUrl}
                      alt="Reward"
                      bowShadow="0 0 3px rgba(0,0,0,.5)"
                    />
                  ) : (
                    <CFImage
                      w="50%"
                      zIndex={1}
                      src={dispatch.restaurant.getImages().rewardDefault}
                      alt="Reward"
                    />
                  )}
                </CFView>
                <LeftArrowButton
                  disabled={rewardIndex === 0}
                  onClick={() => setRewardIndex(rewardIndex - 1)}
                />
                <RightArrowButton
                  disabled={rewardIndex === rewards.length - 1}
                  onClick={() => setRewardIndex(rewardIndex + 1)}
                />
                <CFView w="100%" center absolute top="25px">
                  <CFImage
                    w="95%"
                    src={emptyMeter}
                    alt="Empty Meter"
                    zIndex={0}
                  />
                </CFView>
                <CFView w="100%" center absolute top="25px">
                  <CFView
                    overflow="hidden"
                    center
                    style={{
                      clipPath: `polygon(0% 0%, 0% 100%, ${
                        pointsMeterWidth * 100
                      }% 100%, ${pointsMeterWidth * 100}% 0%)`,
                      transition: '1s cubic-bezier(.17,.67,.83,.67)',
                    }}
                  >
                    <CFImage w="95.29%" contain src={pointsMeter} alt="Meter" />
                  </CFView>
                </CFView>
                <CFView absolute top="9%" right="4%">
                  <CFSelect
                    selector={[
                      dispatch.user.getPointsWithPromoApplied,
                      dispatch.user.getPointsMax,
                    ]}
                  >
                    {([pointsWithPromoApplied, pointsMax]) => (
                      <CFView
                        h5
                        bold
                        color={dispatch.restaurant.getStyles().rewardsFontColor}
                      >
                        {`${pointsWithPromoApplied} / ${pointsMax}`}
                      </CFView>
                    )}
                  </CFSelect>
                </CFView>
                <CFView w="100%" absolute center top="15%">
                  <CFView
                    amatic
                    h1
                    color={dispatch.restaurant.getStyles().rewardsFontColor}
                  >
                    {requiredPoints === 0 ? 'FREE' : `${requiredPoints} Points`}
                  </CFView>
                </CFView>
                <CFView w="100%" absolute bottom="30%" textCenter>
                  <CFView
                    amatic
                    h1
                    center
                    color={dispatch.restaurant.getStyles().rewardsFontColor}
                  >
                    {name ? name.toUpperCase() : ''}
                  </CFView>
                </CFView>
                <CFView w="100%" absolute center bottom="15%">
                  <RedeemButton onClick={() => redeemReward(reward)} />
                </CFView>
                {nextRoute === 'cart' && (
                  <CFView w="100%" absolute center bottom="20px">
                    <PrimaryButton
                      label="BACK TO CART"
                      onClick={() => history.push('/cart')}
                    />
                  </CFView>
                )}
                {nextRoute !== 'cart' && <Navbar absolute bottom={0} />}
              </PageContainer>
            </MobileScreen>
            <DefaultScreen>
              <CFView
                h="600px"
                w="375px"
                br="5px"
                center
                relative
                boxShadow="1px 1px 3px rgba(0,0,0,.8)"
                image={`url(${
                  dispatch.restaurant.getImages().rewardBg
                }) center / cover no-repeat`}
              >
                <CFView center absolute top="48%" transform="translateY(-50%)">
                  <CFImage
                    w="75%"
                    src={dispatch.restaurant.getImages().rewardContainer}
                    alt="RewardContainer"
                    zIndex={0}
                  />
                </CFView>
                <CFView
                  absolute
                  w="100%"
                  center
                  top="48%"
                  transform="translateY(-50%)"
                >
                  {imageUrl ? (
                    <CFImage
                      w="50%"
                      br="200px"
                      zIndex={1}
                      src={imageUrl}
                      alt="Reward"
                      bowShadow="0 0 3px rgba(0,0,0,.5)"
                    />
                  ) : (
                    <CFImage
                      w="50%"
                      zIndex={1}
                      src={dispatch.restaurant.getImages().rewardDefault}
                      alt="Reward"
                    />
                  )}
                </CFView>
                <CFView absolute top="280px" left="10px">
                  <LeftArrowButton
                    disabled={rewardIndex === 0}
                    onClick={() => setRewardIndex(rewardIndex - 1)}
                  />
                </CFView>
                <CFView absolute top="280px" right="10px">
                  <RightArrowButton
                    disabled={rewardIndex === rewards.length - 1}
                    onClick={() => setRewardIndex(rewardIndex + 1)}
                  />
                </CFView>
                <CFView w="100%" center absolute top="25px">
                  <CFImage
                    w="95%"
                    src={emptyMeter}
                    alt="emptyMeter"
                    zIndex={0}
                  />
                </CFView>
                <CFView w="100%" center absolute top="25px">
                  <CFView
                    overflow="hidden"
                    center
                    style={{
                      clipPath: `polygon(0% 0%, 0% 100%, ${
                        pointsMeterWidth * 100
                      }% 100%, ${pointsMeterWidth * 100}% 0%)`,
                      transition: '1s cubic-bezier(.17,.67,.83,.67)',
                    }}
                  >
                    <CFImage w="95.29%" contain src={pointsMeter} alt="Meter" />
                  </CFView>
                </CFView>
                <CFView absolute top="60px" right="15px">
                  <CFSelect
                    selector={[
                      dispatch.user.getPointsWithPromoApplied,
                      dispatch.user.getPointsMax,
                    ]}
                  >
                    {([pointsWithPromoApplied, pointsMax]) => (
                      <CFView
                        h5
                        bold
                        color={dispatch.restaurant.getStyles().rewardsFontColor}
                      >
                        {`${pointsWithPromoApplied} / ${pointsMax}`}
                      </CFView>
                    )}
                  </CFSelect>
                </CFView>
                <CFView absolute top="120px">
                  <CFView
                    amatic
                    h1
                    color={dispatch.restaurant.getStyles().rewardsFontColor}
                  >
                    {requiredPoints === 0 ? 'FREE' : `${requiredPoints} Points`}
                  </CFView>
                </CFView>
                <CFView w="100%" absolute bottom="140px" textCenter>
                  <CFView
                    amatic
                    h1
                    center
                    color={dispatch.restaurant.getStyles().rewardsFontColor}
                  >
                    {name ? name.toUpperCase() : ''}
                  </CFView>
                </CFView>
                <CFView absolute bottom="30px">
                  <RedeemButton onClick={() => redeemReward(reward)} />
                </CFView>
              </CFView>
            </DefaultScreen>
          </>
        )
      }}
    </CFSelect>
  )
}
