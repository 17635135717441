export const COLOR = {
  brand: '#36B7EE',
  disabled: '#888888',
  lightGrey: '#E6E6E6',
  grey: '#BBBBBB',
  mediumGrey: '#888888',
  darkGrey: '#4A4A4A',
  red: '#D0021B',
  orange: '#FF9F00',
  green: '#BEE100',
  darkGreen: '#0FD05A',
  darkOpacity: 'rgba(0,0,0,.7)',
  lightOpacity: 'rgba(255,255,255,.7)',
}
