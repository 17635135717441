import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Card,
  CFSelect,
  CFView,
  DefaultScreen,
  LocationSelect,
  MobileScreen,
  Navbar,
  PageBackground,
  PageContainer,
  StoreClosedBanner,
  ViewCartButton,
} from 'components'
import { Category, Search } from 'pages'
import { recentOrders, searchMenu } from 'images'
import MenuHeader from './MenuHeader'
import CategoryItem from './CategoryItem'
import { dispatch } from 'store'

export default () => {
  const history = useHistory()
  const recentOrdersCategory = {
    name: 'Recent Orders',
    imageUrl: recentOrders,
    id: 'recent',
  }
  const searchMenuCategory = {
    name: 'Search Menu',
    imageUrl: searchMenu,
    id: 'search',
  }
  const [selectedCategory, setSelectedCategory] = useState(searchMenuCategory)

  useEffect(() => {
    const internvalId = setInterval(() => {
      dispatch.restaurant.updateActiveCategoryOrder()
    }, 5000)
    return () => clearInterval(internvalId)
  })

  return (
    <PageBackground>
      <MobileScreen>
        <PageContainer offset="navbar" pb="60px">
          <CFSelect selectorNot={dispatch.restaurant.getIsDineIn}>
            <CFSelect selector={dispatch.restaurant.getHasMultipleLocations}>
              <LocationSelect />
            </CFSelect>
          </CFSelect>
          <StoreClosedBanner />
          <CFSelect selector={dispatch.restaurant.getIsDineIn}>
            <MenuHeader />
          </CFSelect>
          <CFSelect selectorNot={dispatch.restaurant.getIsDineIn}>
            <a href={dispatch.restaurant.getLandingUrl()}>
              <MenuHeader />
            </a>
          </CFSelect>
          <CFView w="100%" pt="5px" center>
            <CFView w="98vw" ml="5px" row alignCenter wrap>
              <CategoryItem
                category={searchMenuCategory}
                onClick={() => history.push('/search')}
              />
              <CFSelect selector={dispatch.user.getIsLoggedIn}>
                <CategoryItem
                  category={recentOrdersCategory}
                  onClick={() =>
                    history.push('/category', recentOrdersCategory)
                  }
                />
              </CFSelect>
              <CFSelect
                selector={() => dispatch.restaurant.getCategoriesInOrder(false)}
              >
                {categoriesInOrder =>
                  categoriesInOrder.map((category, index) => (
                    <CategoryItem
                      key={index}
                      category={category}
                      onClick={() => history.push('/category', category)}
                    />
                  ))
                }
              </CFSelect>
            </CFView>
          </CFView>
          <CFSelect selectorNot={dispatch.user.getIsCartEmpty}>
            <CFView w="100%" fixed bottom="86px" center zIndex={99}>
              <ViewCartButton onClick={() => history.push('/cart')} />
            </CFView>
          </CFSelect>
        </PageContainer>
        <Navbar />
      </MobileScreen>
      <DefaultScreen>
        <CFView h="100vh" w="100%" column>
          <Navbar />
          <CFView p="20px" h="100%" center>
            <Card w="100%" h="100%" maxWidth="1080px" p="20px" row>
              <CFView>
                <CFView
                  w="445px"
                  row
                  wrap
                  maxHeight="calc(100vh - 150px)"
                  overflowY="auto"
                >
                  <StoreClosedBanner />
                  <CategoryItem
                    selected={selectedCategory.id === 'search'}
                    category={searchMenuCategory}
                    onClick={() => setSelectedCategory(searchMenuCategory)}
                  />
                  <CFSelect selector={dispatch.user.getIsLoggedIn}>
                    <CategoryItem
                      selected={selectedCategory.id === 'recent'}
                      category={recentOrdersCategory}
                      onClick={() => setSelectedCategory(recentOrdersCategory)}
                    />
                  </CFSelect>
                  <CFSelect
                    selector={() =>
                      dispatch.restaurant.getCategoriesInOrder(false)
                    }
                  >
                    {categoriesInOrder =>
                      categoriesInOrder.map((category, index) => (
                        <CategoryItem
                          key={index}
                          selected={selectedCategory.id === category.id}
                          category={category}
                          onClick={() => setSelectedCategory(category)}
                        />
                      ))
                    }
                  </CFSelect>
                </CFView>
              </CFView>
              <CFView
                fill
                pr="10px"
                maxHeight="calc(100vh - 150px)"
                overflowY="auto"
              >
                {selectedCategory.id === 'search' ? (
                  <Search />
                ) : (
                  <Category category={selectedCategory} />
                )}
              </CFView>
            </Card>
          </CFView>
        </CFView>
      </DefaultScreen>
    </PageBackground>
  )
}
