import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Card,
  CFImage,
  CFLink,
  CFSelect,
  CFView,
  DefaultScreen,
  SharedBackground,
  MobileScreen,
  Navbar,
  PageContainer,
  PrimaryModal,
} from 'components'
import { CreditCard, OrderHistory, UserDetails } from 'pages'
import SectionButton from './SectionButton'
import { codefusionlogo } from 'images'
import { dispatch } from 'store'

export default () => {
  const history = useHistory()

  const [showCreditCardModal, setShowCreditCardModal] = useState(false)
  const [showUserDetailsModal, setShowUserDetailsModal] = useState(false)

  const signoutUser = () => {
    history.push('/auth/menu')
    dispatch.user.signOut()
    dispatch.notification.setMessage({
      message: 'Successfully logged out!',
      level: 'success',
    })
  }

  return (
    <SharedBackground>
      <MobileScreen>
        <PageContainer offset="navbar" column justifyBetween alignCenter>
          <CFImage
            src={
              dispatch.restaurant.getImages().brandLogo
                ? dispatch.restaurant.getImages().brandLogo
                : codefusionlogo
            }
            w="240px"
            m="auto 0"
          />
          <CFSelect
            selector={[
              dispatch.restaurant.getPaymentMethodType,
              dispatch.restaurant.getDeliveryOnlinePaymentOnly,
            ]}
          >
            {([paymentMethodType, deliveryOnlinePaymentOnly]) => (
              <CFView column w="100%">
                <SectionButton
                  bg={
                    paymentMethodType !== 'offline' || deliveryOnlinePaymentOnly
                      ? 'rgba(0,0,0,.7)'
                      : 'rgba(0,0,0,.4)'
                  }
                  title="User Details"
                  subtitle={dispatch.user.getEmail()}
                  icon="user"
                  onClick={() => history.push('/user_details/user')}
                />
                {(paymentMethodType !== 'offline' ||
                  deliveryOnlinePaymentOnly) && (
                  <SectionButton
                    bg="rgba(0,0,0,.4)"
                    title="Credit / Debit Card"
                    subtitle={
                      dispatch.user.getHasPayment()
                        ? `**** **** **** ${dispatch.user.getLast4()}`
                        : ''
                    }
                    icon="credit-card"
                    onClick={() => history.push('/credit_card/user')}
                  />
                )}
                <CFLink href={`tel:${dispatch.restaurant.getPhoneNumber()}`}>
                  <SectionButton
                    bg="rgba(0,0,0,.7)"
                    title="Contact Restaurant"
                    subtitle={dispatch.restaurant.getFormattedPhoneNumber()}
                    icon="phone"
                  />
                </CFLink>
                <CFLink href={dispatch.restaurant.getGoogleMapsUrl()}>
                  <SectionButton
                    bg="rgba(0,0,0,.4)"
                    title="Find Restaurant"
                    subtitle={dispatch.restaurant.getAddress()}
                    icon="map-marker-alt"
                  />
                </CFLink>
                <SectionButton
                  bg="rgba(0,0,0,.7)"
                  title="Sign Out"
                  icon="sign-out-alt"
                  onClick={signoutUser}
                />
              </CFView>
            )}
          </CFSelect>
        </PageContainer>
        <Navbar />
      </MobileScreen>
      <DefaultScreen>
        <Navbar />
        <PageContainer offset="defaultNavbar">
          <CFView w="100%" center>
            <CFView w="100%" maxWidth="1080px" pv="20px" row justifyAround>
              <Card h="560px" w="420px" column justifyBetween>
                <CFImage
                  src={
                    dispatch.restaurant.getImages().brandLogo
                      ? dispatch.restaurant.getImages().brandLogo
                      : codefusionlogo
                  }
                  w="220px"
                  mt="20px"
                  selfCenter
                />
                <CFSelect
                  selector={[
                    dispatch.restaurant.getPaymentMethodType,
                    dispatch.restaurant.getDeliveryOnlinePaymentOnly,
                  ]}
                >
                  {([paymentMethodType, deliveryOnlinePaymentOnly]) => (
                    <CFView w="100%">
                      <SectionButton
                        bg={
                          paymentMethodType !== 'offline' ||
                          deliveryOnlinePaymentOnly
                            ? 'rgba(0,0,0,.7)'
                            : 'rgba(0,0,0,.4)'
                        }
                        title="User Details"
                        subtitle={dispatch.user.getEmail()}
                        icon="user"
                        onClick={() => setShowUserDetailsModal(true)}
                      />
                      {(paymentMethodType !== 'offline' ||
                        deliveryOnlinePaymentOnly) && (
                        <SectionButton
                          bg="rgba(0,0,0,.4)"
                          title="Credit / Debit Card"
                          subtitle={
                            dispatch.user.getHasPayment()
                              ? `**** **** **** ${dispatch.user.getLast4()}`
                              : ''
                          }
                          icon="credit-card"
                          onClick={() => setShowCreditCardModal(true)}
                        />
                      )}
                      <CFLink
                        href={`tel:${dispatch.restaurant.getPhoneNumber()}`}
                      >
                        <SectionButton
                          bg="rgba(0,0,0,.7)"
                          title="Contact Restaurant"
                          subtitle={dispatch.restaurant.getFormattedPhoneNumber()}
                          icon="phone"
                        />
                      </CFLink>
                      <CFLink
                        newTab
                        href={dispatch.restaurant.getGoogleMapsUrl()}
                      >
                        <SectionButton
                          bg="rgba(0,0,0,.4)"
                          title="Find Restaurant"
                          subtitle={dispatch.restaurant.getAddress()}
                          icon="map-marker-alt"
                        />
                      </CFLink>
                      <SectionButton
                        bg="rgba(0,0,0,.7)"
                        title="Sign Out"
                        icon="sign-out-alt"
                        onClick={signoutUser}
                      />
                    </CFView>
                  )}
                </CFSelect>
              </Card>
              <Card title="ORDER HISTORY" h="560px" w="560px" p="20px">
                <CFView h="calc(100% - 40px)" overflowY="auto">
                  <OrderHistory />
                </CFView>
              </Card>
            </CFView>
          </CFView>
        </PageContainer>
        <PrimaryModal
          title="Credit / Debit Card"
          isOpen={showCreditCardModal}
          onRequestClose={() => setShowCreditCardModal(false)}
        >
          <CreditCard onRequestClose={() => setShowCreditCardModal(false)} />
        </PrimaryModal>
        <PrimaryModal
          title="User Details"
          isOpen={showUserDetailsModal}
          onRequestClose={() => setShowUserDetailsModal(false)}
        >
          <UserDetails onRequestClose={() => setShowUserDetailsModal(false)} />
        </PrimaryModal>
      </DefaultScreen>
    </SharedBackground>
  )
}
