import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import moment from 'moment'
import {
  BackButton,
  Card,
  CFView,
  DefaultScreen,
  MobileScreen,
  PageContainer,
  PageHeader,
  PrimaryButton,
  SharedBackground,
} from 'components'
import TimeItem from './TimeItem'
import { dispatch } from 'store'

export default ({ onRequestClose }) => {
  const history = useHistory()
  const isMobile = useMediaQuery({ maxWidth: 767 })

  const [selectedTime, setSelectedTime] = useState('ASAP')

  const getTime = (hour, date) => {
    return moment(
      `${moment(hour).format('LT')}, ${date.format('MMM D')}`,
      'LT, MMM D'
    )
  }

  const generateTimeIntervals = (hours, date) => {
    const day = date.day()
    const open = getTime(hours[day].open, date).add(30, 'm')
    const close = getTime(hours[day].close, date)
    const breakStart = getTime(hours[day].breakStart, date)
    const breakEnd = getTime(hours[day].breakEnd, date).add(30, 'm')
    let waitTime = dispatch.restaurant.getWaitTime()
    if (dispatch.user.getOrderType() === 'Delivery') {
      waitTime =
        dispatch.restaurant.getWaitTime() +
        dispatch.restaurant.getDeliveryTime()
    }
    if (waitTime > 15) {
      waitTime += waitTime % 15
    }
    const timeIntervals = []
    const interval = open
    while (interval <= close) {
      if (
        !(
          moment().isSame(interval, 'd') &&
          moment().add(waitTime, 'm').valueOf() > interval.valueOf()
        ) &&
        !interval.isBetween(breakStart, breakEnd)
      ) {
        timeIntervals.push(interval.format('LT, MMM D'))
      }
      interval.add(15, 'm')
    }
    return timeIntervals
  }

  const setOrderTimeAndNavigateCheckout = () => {
    dispatch.user.setOrderTime(selectedTime)
    if (isMobile) {
      history.push('/checkout')
    } else {
      onRequestClose()
    }
  }

  let availableTimesToday = generateTimeIntervals(
    dispatch.restaurant.getHours(),
    moment()
  )
  if (dispatch.user.getOrderType() === 'Delivery') {
    availableTimesToday = generateTimeIntervals(
      dispatch.restaurant.getDeliveryHours(),
      moment()
    )
  }
  let availableTimesTomorrow = generateTimeIntervals(
    dispatch.restaurant.getHours(),
    moment().add(1, 'd')
  )
  if (dispatch.user.getOrderType() === 'Delivery') {
    availableTimesTomorrow = generateTimeIntervals(
      dispatch.restaurant.getDeliveryHours(),
      moment().add(1, 'd')
    )
  }
  const availableTimes = [
    'ASAP',
    ...availableTimesToday,
    ...availableTimesTomorrow,
  ]

  return (
    <>
      <MobileScreen>
        <SharedBackground>
          <PageHeader
            title={`${dispatch.user.getOrderType()} Time`}
            LeftButton={
              <BackButton onClick={() => history.push('/checkout')} />
            }
          />
          <PageContainer
            offset="header"
            pt="20px"
            ph="10px"
            column
            justifyBetween
          >
            <Card fill p="10px 20px" overflowY="auto">
              {availableTimes.map(time => (
                <TimeItem
                  key={time}
                  time={time}
                  selected={time === selectedTime}
                  onClick={() => setSelectedTime(time)}
                />
              ))}
            </Card>
            <PrimaryButton
              selfCenter
              mt="40px"
              mb="20px"
              label={'SAVE'}
              onClick={setOrderTimeAndNavigateCheckout}
            />
          </PageContainer>
        </SharedBackground>
      </MobileScreen>
      <DefaultScreen>
        <CFView w="420px" h="360px" pv="20px" ph="20px" column>
          <Card fill p="10px 20px" overflowY="auto">
            {availableTimes.map(time => (
              <TimeItem
                key={time}
                time={time}
                selected={time === selectedTime}
                onClick={() => setSelectedTime(time)}
              />
            ))}
          </Card>
          <PrimaryButton
            selfCenter
            mt="20px"
            label={'SAVE'}
            onClick={setOrderTimeAndNavigateCheckout}
          />
        </CFView>
      </DefaultScreen>
    </>
  )
}
