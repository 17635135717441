import React from 'react'
import { Card, CFIcon, CFView, ThemedText } from 'components'

export default ({ name, icon, onClick }) => (
  <Card
    mb={20}
    h={75}
    br={8}
    ph={19}
    w="100%"
    row
    alignCenter
    hover
    onClick={onClick}
  >
    <ThemedText>
      <CFIcon icon={icon} fontSize={30} />
    </ThemedText>
    <CFView ml={20} fill>
      <ThemedText h4 bold mb="3px">
        {name}
      </ThemedText>
    </CFView>
  </Card>
)
