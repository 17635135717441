import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import {
  BackButton,
  Card,
  CFSelect,
  CFView,
  DefaultScreen,
  IconButton,
  MobileScreen,
  Navbar,
  PageBackground,
  PageContainer,
  PageHeader,
  PrimaryButton,
  PrimaryModal,
  ToggleButton,
  ThemedText,
} from 'components'
import {
  CreditCard,
  DeliveryDetails,
  Locations,
  OrderTime,
  UserDetails,
} from 'pages'
import LocationSection from './LocationSection'
import ContactSection from './ContactSection'
import DeliverySection from './DeliverySection'
import PaymentSection from './PaymentSection'
import SectionHeader from './SectionHeader'
import SummarySection from './SummarySection'
import TimeSection from './TimeSection'
import TipSection from './TipSection'
import TypeSection from './TypeSection'
import PromoCodeSection from './PromoCodeSection'
import EditSectionButton from './EditSectionButton'
import { dispatch } from 'store'
import moment from 'moment'

export default () => {
  const history = useHistory()
  const isMobile = useMediaQuery({ maxWidth: 767 })

  const [requestingDelivery, setRequestingDelivery] = useState(false)
  const [showLocationModal, setShowLocationModal] = useState(false)
  const [showOrderTimeModal, setShowOrderTimeModal] = useState(false)
  const [showDeliveryModal, setShowDeliveryModal] = useState(false)
  const [showCreditCardModal, setShowCreditCardModal] = useState(false)
  const [showUserDetailsModal, setShowUserDetailsModal] = useState(false)

  const placeOrder = async () => {
    if (dispatch.user.getIsPlacingOrder()) {
      alert('Your order is already being placed! Please wait.')
      return
    }
    if (!dispatch.restaurant.getIsStoreOpen()) {
      alert('Sorry, the restaurant is closed.')
      return
    }
    const orderType = dispatch.user.getOrderType()
    if (!dispatch.restaurant.getIsDineIn()) {
      if (!dispatch.user.getName() || !dispatch.user.getPhoneNumber()) {
        alert('Name and phone number required to make order.')
        return
      }
      if (orderType === '' || orderType === 'Dine-in') {
        alert('Please choose pickup or delivery.')
        return
      }
      if (orderType === 'Delivery') {
        if (
          !dispatch.restaurant.getDeliveryOpen() ||
          dispatch.restaurant.getDeliveryHoursTime() === 'Not Available'
        ) {
          alert('Sorry, delivery is closed.')
          return
        }
        if (
          dispatch.user.getOrderTime() === 'ASAP' &&
          !dispatch.restaurant.getIsDeliveryHoursOpen()
        ) {
          alert('Please choose a delivery time within delivery hours.')
          return
        }
        const minOrder = dispatch.restaurant.getMinOrder()
        if (dispatch.user.getCartSubtotalAfterRewards() < minOrder) {
          alert(`Your subtotal must be greater than ${minOrder}.`)
          return
        }
        if (!dispatch.user.getAddress()) {
          alert('Address required to make delivery order.')
          return
        }
      }
    }
    const paymentMethod = dispatch.user.getPaymentMethod()
    if (dispatch.restaurant.getPaymentMethodType() === 'both') {
      if (paymentMethod === '') {
        alert('Please choose a payment method.')
        return
      }
    }
    if (paymentMethod === 'online') {
      if (!dispatch.user.getHasPayment()) {
        alert('Please add your credit / debit card.')
        return
      }
    }
    if (dispatch.user.getCartCount() < 1) {
      alert('Cannot place order with empty cart.')
      return
    }
    if (orderType === 'Delivery' && dispatch.restaurant.getDoorDashEnabled()) {
      setRequestingDelivery(true)
      try {
        const estimateDelivery = await dispatch.user.estimateDoorDashDelivery()
        if (
          window.confirm(
            `The estimated delivery time for this order is ${moment(
              estimateDelivery.data.delivery_time
            ).format('LT, MMM D')}. Do you wish to continue?`
          )
        ) {
          const createDelivery = await dispatch.user.createDoorDashDelivery()
          dispatch.user.setDoorDashDetails(createDelivery.data)
          dispatch.user.setOrderTime(
            moment(createDelivery.data.estimated_delivery_time).format(
              'LT, MMM D'
            )
          )
        } else {
          return
        }
      } catch (e) {
        if (e.response && e.response.data && e.response.data.field_errors) {
          alert(e.response.data.field_errors[0].error)
        } else {
          alert('Sorry, error requesting delivery. Please try again later.')
        }
        return
      } finally {
        setRequestingDelivery(false)
      }
    }
    try {
      const order = await dispatch.user.createOrder()
      dispatch.notification.setMessage({
        message: 'Your order has been processed!',
        level: 'success',
      })
      history.push('/order_complete', order)
    } catch (e) {
      if (e.response && e.response.status === 402) {
        alert(
          'Sorry, there was an error with your credit / debit card. Please try another card.'
        )
      } else {
        if (e.response && e.response.data && e.response.data.message) {
          alert(e.response.data.message)
        } else {
          alert(
            'Sorry, we could not process your order. Please call the restaurant to make your order.'
          )
        }
      }
    }
  }

  const setOrderTypePickup = () => {
    if (dispatch.restaurant.getDoorDashEnabled()) {
      dispatch.user.setTip(dispatch.restaurant.getTipValues()[1])
    }
    dispatch.user.setOrderType('Pickup')
  }

  const setOrderTypeDelivery = () => {
    if (!dispatch.user.getAddress()) {
      navigateDeliveryDetails()
    }
    if (dispatch.restaurant.getDoorDashEnabled()) {
      dispatch.user.setTip(dispatch.restaurant.getTipValues()[1])
    }
    dispatch.user.setOrderType('Delivery')
  }

  const navigateDeliveryDetails = () => {
    if (isMobile) {
      history.push('/delivery_details')
    } else {
      setShowDeliveryModal(true)
    }
  }

  const setPaymentMethodCreditCard = () => {
    if (!dispatch.user.getHasPayment()) {
      navigateCreditCard()
    }
    dispatch.user.setPaymentMethod('online')
  }

  const navigateCreditCard = () => {
    if (isMobile) {
      history.push('/credit_card/checkout')
    } else {
      setShowCreditCardModal(true)
    }
  }

  const navigateUserDetails = () => {
    if (isMobile) {
      history.push('/user_details/checkout')
    } else {
      setShowUserDetailsModal(true)
    }
  }

  const navigateLocation = () => {
    if (isMobile) {
      history.push('/locations')
    } else {
      setShowLocationModal(true)
    }
  }

  const closeDeliveryModal = () => {
    if (dispatch.user.getAddress() === '') {
      dispatch.user.setOrderType('')
    }
    setShowDeliveryModal(false)
  }

  const closeCreditCardModal = () => {
    if (!dispatch.user.getHasPayment()) {
      dispatch.user.setPaymentMethod('')
    }
    setShowCreditCardModal(false)
  }

  return (
    <CFSelect
      selector={{
        isDineIn: dispatch.restaurant.getIsDineIn,
        orderType: dispatch.user.getOrderType,
        paymentMethodType: dispatch.restaurant.getPaymentMethodType,
        paymentMethod: dispatch.user.getPaymentMethod,
        deliveryOnlinePaymentOnly:
          dispatch.restaurant.getDeliveryOnlinePaymentOnly,
        tipValues: dispatch.restaurant.getTipValues,
        tip: dispatch.user.getTip,
        promoCodesEnabled: dispatch.restaurant.getPromoCodesEnabled,
      }}
    >
      {({
        isDineIn,
        orderType,
        paymentMethodType,
        paymentMethod,
        deliveryOnlinePaymentOnly,
        tipValues,
        tip,
        promoCodesEnabled,
      }) => (
        <PageBackground>
          <MobileScreen>
            <PageHeader
              title="Checkout"
              LeftButton={<BackButton onClick={() => history.push('/cart')} />}
            />
            <PageContainer offset="header" column pv="20px" ph="10px">
              <CFView fill>
                <CFSelect
                  selector={dispatch.restaurant.getHasMultipleLocations}
                >
                  {!isDineIn && (
                    <Card mb="20px">
                      <LocationSection
                        EditButton={
                          <EditSectionButton onClick={navigateLocation} />
                        }
                      />
                    </Card>
                  )}
                </CFSelect>
                {isDineIn && (
                  <Card h="50px" center mb="20px">
                    <ThemedText h3 bold>
                      {`Table Number: ${dispatch.restaurant.getTableNumber()}`}
                    </ThemedText>
                  </Card>
                )}
                {!isDineIn && (
                  <CFSelect selector={dispatch.restaurant.getDeliveryEnabled}>
                    <Card mb="20px">
                      <TypeSection
                        PickupButton={
                          <ToggleButton
                            label="Pickup"
                            toggled={orderType === 'Pickup'}
                            onClick={setOrderTypePickup}
                          />
                        }
                        DeliveryButton={
                          <ToggleButton
                            label="Delivery"
                            toggled={orderType === 'Delivery'}
                            onClick={setOrderTypeDelivery}
                          />
                        }
                      />
                    </Card>
                  </CFSelect>
                )}
                {!isDineIn && orderType === 'Delivery' && (
                  <Card mb="20px">
                    <DeliverySection
                      EditButton={
                        <EditSectionButton onClick={navigateDeliveryDetails} />
                      }
                    />
                  </Card>
                )}
                <Card mb="20px">
                  <ContactSection
                    EditButton={
                      <EditSectionButton onClick={navigateUserDetails} />
                    }
                  />
                </Card>
                {!isDineIn && orderType && (
                  <Card mb="20px">
                    <TimeSection
                      EditButton={
                        <EditSectionButton
                          onClick={() => history.push('/order_time')}
                        />
                      }
                    />
                  </Card>
                )}
                {(paymentMethodType !== 'offline' ||
                  (deliveryOnlinePaymentOnly && orderType === 'Delivery')) && (
                  <Card mb="20px">
                    <PaymentSection
                      PayCreditCardButton={
                        <ToggleButton
                          label="Online"
                          toggled={paymentMethod === 'online'}
                          onClick={setPaymentMethodCreditCard}
                        />
                      }
                      PayCashButton={
                        <ToggleButton
                          label="In-Person"
                          toggled={paymentMethod === 'offline'}
                          onClick={() =>
                            dispatch.user.setPaymentMethod('offline')
                          }
                        />
                      }
                      EditButton={
                        <EditSectionButton onClick={navigateCreditCard} />
                      }
                    />
                  </Card>
                )}
                {promoCodesEnabled && (
                  <Card mb="20px">
                    <PromoCodeSection />
                  </Card>
                )}
                <Card mb="40px">
                  <SectionHeader
                    title="Order Summary"
                    icon="file-invoice-dollar"
                  />
                  {paymentMethod === 'online' && (
                    <TipSection
                      Tip10Button={
                        <ToggleButton
                          label={`${tipValues[0] * 100}%`}
                          toggled={tip === tipValues[0]}
                          onClick={() => dispatch.user.setTip(tipValues[0])}
                        />
                      }
                      Tip15Button={
                        <ToggleButton
                          label={`${tipValues[1] * 100}%`}
                          toggled={tip === tipValues[1]}
                          onClick={() => dispatch.user.setTip(tipValues[1])}
                        />
                      }
                      Tip20Button={
                        <ToggleButton
                          label={`${tipValues[2] * 100}%`}
                          toggled={tip === tipValues[2]}
                          onClick={() => dispatch.user.setTip(tipValues[2])}
                        />
                      }
                      TipNoneButton={
                        <ToggleButton
                          label={
                            orderType === 'Delivery' &&
                            dispatch.restaurant.getDoorDashEnabled()
                              ? '10%'
                              : 'None'
                          }
                          toggled={tip === 0 || tip === 0.1}
                          onClick={() =>
                            dispatch.user.setTip(
                              orderType === 'Delivery' &&
                                dispatch.restaurant.getDoorDashEnabled()
                                ? 0.1
                                : 0
                            )
                          }
                        />
                      }
                    />
                  )}
                  <SummarySection />
                </Card>
              </CFView>
              <CFView selfCenter>
                <CFSelect
                  selector={[
                    dispatch.user.getIsContactExists,
                    dispatch.user.getIsPlacingOrder,
                    dispatch.user.getHasPayment,
                  ]}
                >
                  {([isContactExists, isPlacingOrder, hasPayment]) => {
                    if (!isDineIn && !isContactExists) {
                      return (
                        <PrimaryButton
                          label="ADD CONTACT INFO"
                          onClick={navigateUserDetails}
                        />
                      )
                    } else if (paymentMethod === 'online' && !hasPayment) {
                      return (
                        <PrimaryButton
                          label="ADD PAYMENT"
                          onClick={navigateCreditCard}
                        />
                      )
                    } else {
                      return (
                        <PrimaryButton
                          label={
                            requestingDelivery
                              ? 'REQUESTING DELIVERY...'
                              : isPlacingOrder
                              ? 'PLACING ORDER...'
                              : 'PLACE ORDER'
                          }
                          disabled={requestingDelivery || isPlacingOrder}
                          onClick={placeOrder}
                        />
                      )
                    }
                  }}
                </CFSelect>
              </CFView>
            </PageContainer>
          </MobileScreen>
          <DefaultScreen>
            <Navbar />
            <PageContainer offset="defaultNavbar">
              <CFView p="20px" center relative zIndex={99}>
                <Card
                  w="640px"
                  pv="20px"
                  ph="40px"
                  title="CHECKOUT"
                  LeftButton={
                    <IconButton
                      label="Back"
                      icon="arrow-left"
                      onClick={() => history.push('/cart')}
                    />
                  }
                >
                  <CFSelect
                    selector={dispatch.restaurant.getHasMultipleLocations}
                  >
                    <Card mb="20px">
                      <LocationSection
                        EditButton={
                          <EditSectionButton onClick={navigateLocation} />
                        }
                      />
                    </Card>
                  </CFSelect>
                  <CFSelect selector={dispatch.restaurant.getDeliveryEnabled}>
                    <Card mb="20px">
                      <TypeSection
                        PickupButton={
                          <ToggleButton
                            label="Pickup"
                            toggled={orderType === 'Pickup'}
                            onClick={setOrderTypePickup}
                          />
                        }
                        DeliveryButton={
                          <ToggleButton
                            label="Delivery"
                            toggled={orderType === 'Delivery'}
                            onClick={setOrderTypeDelivery}
                          />
                        }
                      />
                    </Card>
                  </CFSelect>
                  {orderType === 'Delivery' && (
                    <Card mb="20px">
                      <DeliverySection
                        EditButton={
                          <EditSectionButton
                            onClick={navigateDeliveryDetails}
                          />
                        }
                      />
                    </Card>
                  )}
                  <Card mb="20px">
                    <ContactSection
                      EditButton={
                        <EditSectionButton onClick={navigateUserDetails} />
                      }
                    />
                  </Card>
                  {orderType && (
                    <Card mb="20px">
                      <TimeSection
                        EditButton={
                          <EditSectionButton
                            onClick={() => setShowOrderTimeModal(true)}
                          />
                        }
                      />
                    </Card>
                  )}
                  {(paymentMethodType !== 'offline' ||
                    (deliveryOnlinePaymentOnly &&
                      orderType === 'Delivery')) && (
                    <Card mb="20px">
                      <PaymentSection
                        PayCreditCardButton={
                          <ToggleButton
                            label="Online"
                            toggled={paymentMethod === 'online'}
                            onClick={setPaymentMethodCreditCard}
                          />
                        }
                        PayCashButton={
                          <ToggleButton
                            label="In-Person"
                            toggled={paymentMethod === 'offline'}
                            onClick={() =>
                              dispatch.user.setPaymentMethod('offline')
                            }
                          />
                        }
                        EditButton={
                          <EditSectionButton onClick={navigateCreditCard} />
                        }
                      />
                    </Card>
                  )}
                  {promoCodesEnabled && (
                    <Card mb="20px">
                      <PromoCodeSection />
                    </Card>
                  )}
                  <Card mb="40px">
                    <SectionHeader
                      title="Order Summary"
                      icon="file-invoice-dollar"
                    />
                    {paymentMethod === 'online' && (
                      <TipSection
                        Tip10Button={
                          <ToggleButton
                            label={`${tipValues[0] * 100}%`}
                            toggled={tip === tipValues[0]}
                            onClick={() => dispatch.user.setTip(tipValues[0])}
                          />
                        }
                        Tip15Button={
                          <ToggleButton
                            label={`${tipValues[1] * 100}%`}
                            toggled={tip === tipValues[1]}
                            onClick={() => dispatch.user.setTip(tipValues[1])}
                          />
                        }
                        Tip20Button={
                          <ToggleButton
                            label={`${tipValues[2] * 100}%`}
                            toggled={tip === tipValues[2]}
                            onClick={() => dispatch.user.setTip(tipValues[2])}
                          />
                        }
                        TipNoneButton={
                          <ToggleButton
                            label={
                              orderType === 'Delivery' &&
                              dispatch.restaurant.getDoorDashEnabled()
                                ? '10%'
                                : 'None'
                            }
                            toggled={
                              orderType === 'Delivery' &&
                              dispatch.restaurant.getDoorDashEnabled()
                                ? tip === 0.1
                                : tip === 0
                            }
                            onClick={() =>
                              dispatch.user.setTip(
                                orderType === 'Delivery' &&
                                  dispatch.restaurant.getDoorDashEnabled()
                                  ? 0.1
                                  : 0
                              )
                            }
                          />
                        }
                      />
                    )}
                    <SummarySection />
                  </Card>
                  <CFView center>
                    <CFSelect
                      selector={[
                        dispatch.user.getIsContactExists,
                        dispatch.user.getIsPlacingOrder,
                        dispatch.user.getHasPayment,
                      ]}
                    >
                      {([isContactExists, isPlacingOrder, hasPayment]) => {
                        if (!isContactExists) {
                          return (
                            <PrimaryButton
                              label="ADD CONTACT INFO"
                              onClick={navigateUserDetails}
                            />
                          )
                        } else if (paymentMethod === 'online' && !hasPayment) {
                          return (
                            <PrimaryButton
                              label="ADD PAYMENT"
                              onClick={navigateCreditCard}
                            />
                          )
                        } else {
                          return (
                            <PrimaryButton
                              label={
                                requestingDelivery
                                  ? 'REQUESTING DELIVERY...'
                                  : isPlacingOrder
                                  ? 'PLACING ORDER...'
                                  : 'PLACE ORDER'
                              }
                              disabled={requestingDelivery || isPlacingOrder}
                              onClick={placeOrder}
                            />
                          )
                        }
                      }}
                    </CFSelect>
                  </CFView>
                </Card>
              </CFView>
            </PageContainer>
            <PrimaryModal
              title="Locations"
              isOpen={showLocationModal}
              onRequestClose={() => setShowLocationModal(false)}
            >
              <Locations onRequestClose={() => setShowLocationModal(false)} />
            </PrimaryModal>
            <PrimaryModal
              title="Delivery Details"
              isOpen={showDeliveryModal}
              onRequestClose={closeDeliveryModal}
            >
              <DeliveryDetails
                onRequestClose={() => setShowDeliveryModal(false)}
              />
            </PrimaryModal>
            <PrimaryModal
              title={`${orderType} Time`}
              isOpen={showOrderTimeModal}
              onRequestClose={() => setShowOrderTimeModal(false)}
            >
              <OrderTime onRequestClose={() => setShowOrderTimeModal(false)} />
            </PrimaryModal>
            <PrimaryModal
              title="Credit / Debit Card"
              isOpen={showCreditCardModal}
              onRequestClose={closeCreditCardModal}
            >
              <CreditCard
                onRequestClose={() => setShowCreditCardModal(false)}
              />
            </PrimaryModal>
            <PrimaryModal
              title="User Details"
              isOpen={showUserDetailsModal}
              onRequestClose={() => setShowUserDetailsModal(false)}
            >
              <UserDetails
                onRequestClose={() => setShowUserDetailsModal(false)}
              />
            </PrimaryModal>
          </DefaultScreen>
        </PageBackground>
      )}
    </CFSelect>
  )
}
