import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  geocodeByPlaceId,
} from 'react-places-autocomplete'
import { isCoordinateInDeliveryZone } from 'cf-utils'
import {
  BackButton,
  CFIcon,
  CFView,
  DefaultScreen,
  LabelInput,
  MobileScreen,
  PageContainer,
  PageHeader,
  PrimaryButton,
  SharedBackground,
} from 'components'
import { COLOR } from 'styles'
import { dispatch } from 'store'
import { isEmpty } from 'lodash'

const options = {
  componentRestrictions: { country: ['ca'] },
  types: ['address'],
}

export default ({ onRequestClose }) => {
  const history = useHistory()
  const isMobile = useMediaQuery({ maxWidth: 767 })

  const [address, setAddress] = useState(dispatch.user.getAddress())
  const [latLng, setLatLng] = useState(dispatch.user.getLatLng())
  const [doordashAddress, setDoorDashAddress] = useState(
    dispatch.user.getDoorDashAddress()
  )
  const [aptNumber, setAptNumber] = useState(dispatch.user.getAptNumber())
  const [deliveryInstructions, setDeliveryInstructions] = useState(
    dispatch.user.getDeliveryInstructions()
  )
  const [saving, setSaving] = useState(false)

  const selectAddress = async (address, placeId) => {
    const results = await geocodeByAddress(address)
    const latLng = await getLatLng(results[0])
    const [place] = await geocodeByPlaceId(placeId)
    const addressParts = address.split(', ')
    const { long_name: postalCode = '' } =
      place.address_components.find(c => c.types.includes('postal_code')) || {}
    setAddress(address)
    setLatLng(latLng)
    setDoorDashAddress({
      city: addressParts[1],
      state: addressParts[2],
      street: addressParts[0],
      unit: aptNumber,
      zip_code: postalCode,
    })
  }

  const saveDeliveryDetails = async () => {
    if (isEmpty(address) || isEmpty(latLng)) {
      alert('Please select a valid address.')
      return
    }
    if (!dispatch.restaurant.getDoorDashEnabled()) {
      const deliveryZone = dispatch.restaurant.getDeliveryZone()
      if (
        deliveryZone.length > 0 &&
        !isCoordinateInDeliveryZone(latLng, deliveryZone)
      ) {
        alert('Sorry, your address is outside of our delivery area.')
        return
      }
      const deliveryZonesWithFees =
        dispatch.restaurant.getDeliveryZonesWithFees()
      if (deliveryZonesWithFees.length > 0) {
        let valid = false
        deliveryZonesWithFees.some(zone => {
          if (isCoordinateInDeliveryZone(latLng, zone.deliveryZone)) {
            valid = true
            return true
          }
          return false
        })
        if (!valid) {
          alert('Sorry, your address is outside of our delivery area.')
          return
        }
      }
    }
    setSaving(true)
    try {
      const trimmedAddress = address
        .replace(', BC', '')
        .replace(', Canada', '')
        .trim()
      await dispatch.user.setAddress({
        address: trimmedAddress,
        latLng,
        aptNumber: aptNumber.trim(),
        deliveryInstructions: deliveryInstructions.trim(),
      })
      if (dispatch.restaurant.getDoorDashEnabled()) {
        doordashAddress.unit = aptNumber.trim()
        await dispatch.user.setDoorDashAddress(doordashAddress)
      }
      dispatch.notification.setMessage({
        message: 'Delivery details saved!',
        level: 'success',
      })
      if (isMobile) {
        history.push('/checkout')
      } else {
        onRequestClose()
      }
    } catch (e) {
      alert('Sorry, delivery details could not be saved.')
    } finally {
      setSaving(false)
    }
  }

  const navigateCheckout = () => {
    if (dispatch.user.getAddress() === '') {
      dispatch.user.setOrderType('')
    }
    history.push('/checkout')
  }

  return (
    <>
      <MobileScreen>
        <SharedBackground>
          <PageHeader
            title="Delivery Details"
            LeftButton={<BackButton onClick={navigateCheckout} />}
          />
          <PageContainer offset="header" pt="40px" pb="20px" ph="20px" column>
            <CFView fill>
              <PlacesAutocomplete
                value={address}
                onChange={address => setAddress(address)}
                onSelect={selectAddress}
                searchOptions={options}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <CFView column relative>
                    <CFView row alignEnd mb="20px">
                      <LabelInput
                        fill
                        label="Delivery Address"
                        color="black"
                        placeholder="Enter Address"
                        {...getInputProps()}
                      />
                      <CFView pl="10px" pb="2px" onClick={() => setAddress('')}>
                        <CFIcon
                          icon="times-circle"
                          color={COLOR.red}
                          fontSize="30px"
                        />
                      </CFView>
                    </CFView>
                    <CFView
                      maxHeight="300px"
                      overflow="hidden"
                      ph="10px"
                      br="5px"
                      absolute
                      top="70px"
                      bg="white"
                    >
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion, index) => (
                        <div
                          key={index}
                          {...getSuggestionItemProps(suggestion)}
                        >
                          <CFView h="50px" mb="10px" row alignCenter>
                            <CFView h4 black>
                              {suggestion.description}
                            </CFView>
                          </CFView>
                        </div>
                      ))}
                    </CFView>
                  </CFView>
                )}
              </PlacesAutocomplete>
              <LabelInput
                mb="20px"
                label="Apt Number"
                color="black"
                maxLength={10}
                value={aptNumber}
                onChange={e => setAptNumber(e.target.value)}
              />
              <LabelInput
                mb="40px"
                label="Delivery Instructions"
                color="black"
                maxLength={128}
                value={deliveryInstructions}
                onChange={e => setDeliveryInstructions(e.target.value)}
              />
            </CFView>
            <PrimaryButton
              selfCenter
              label={saving ? 'SAVING...' : 'SAVE'}
              disabled={saving}
              onClick={saveDeliveryDetails}
            />
          </PageContainer>
        </SharedBackground>
      </MobileScreen>
      <DefaultScreen>
        <CFView w="500px" ph="20px" pt="40px" pb="20px" column center>
          <CFView w="100%">
            <PlacesAutocomplete
              value={address}
              onChange={address => setAddress(address)}
              onSelect={selectAddress}
              searchOptions={options}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <CFView column relative mb="20px">
                  <CFView row alignEnd>
                    <LabelInput
                      fill
                      label="Address"
                      color="black"
                      placeholder="Enter Address"
                      {...getInputProps()}
                    />
                    <CFView
                      pl="10px"
                      pb="3px"
                      onClick={() => setAddress('')}
                      hover
                    >
                      <CFIcon
                        icon="times-circle"
                        color={COLOR.red}
                        fontSize="30px"
                      />
                    </CFView>
                  </CFView>
                  <CFView
                    maxHeight="300px"
                    overflow="hidden"
                    w="100%"
                    ph="10px"
                    absolute
                    top="70px"
                    bg="white"
                  >
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion, index) => (
                      <div key={index} {...getSuggestionItemProps(suggestion)}>
                        <CFView row h="50px" alignCenter hover>
                          <CFView h4 black>
                            {suggestion.description}
                          </CFView>
                        </CFView>
                      </div>
                    ))}
                  </CFView>
                </CFView>
              )}
            </PlacesAutocomplete>
            <LabelInput
              mb="20px"
              label="Apt Number"
              color="black"
              maxLength={10}
              value={aptNumber}
              onChange={e => setAptNumber(e.target.value)}
            />
            <LabelInput
              mb="40px"
              label="Delivery Instructions"
              color="black"
              maxLength={128}
              value={deliveryInstructions}
              onChange={e => setDeliveryInstructions(e.target.value)}
            />
          </CFView>
          <PrimaryButton
            label={saving ? 'SAVING...' : 'SAVE'}
            disabled={saving}
            onClick={saveDeliveryDetails}
          />
        </CFView>
      </DefaultScreen>
    </>
  )
}
