import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { cloneDeep } from 'lodash'
import {
  BackButton,
  CFImage,
  CFView,
  DefaultScreen,
  MobileScreen,
  PageBackground,
  PageContainer,
  PageHeader,
} from 'components'
import ProductHeader from './ProductHeader'
import ModifierItem from './ModifierItem'
import ChoiceItem from './ChoiceItem'
import AddCartButton from './AddCartButton'
import AddQuantityButton from './AddQuantityButton'
import SubtractQuantityButton from './SubtractQuantityButton'
import { dispatch } from 'store'
import { isEmpty } from 'lodash'

export default ({ location, product = {}, onRequestClose }) => {
  const history = useHistory()
  const isMobile = useMediaQuery({ maxWidth: 767 })
  if (isEmpty(product) && !!location) {
    product = location.state
  }
  const { name, price, id, imageUrl = null } = product
  const modifiers = dispatch.restaurant.getModifiers()
  const productModifiers = dispatch.restaurant.getProductModifiers(id)
  const defaultSelectedModifiers = productModifiers.map(modifierId => {
    const modifier = modifiers[modifierId]
    const choices = modifier.choices.map((choice, index) => {
      if (!dispatch.restaurant.getIsChoiceActive(modifierId, index)) {
        if (choice.selected) {
          choice.selected = false
          modifier.choiceCount--
        }
      }
      return choice
    })
    modifier.choices = choices
    return { ...modifier }
  })

  const [quantity, setQuantity] = useState(1)
  const [selectedModifiers, setSelectedModifiers] = useState(
    cloneDeep(defaultSelectedModifiers)
  )

  const calculateChoicesPrice = () => {
    return selectedModifiers.reduce((acc, modifier) => {
      modifier.choices.forEach(choice => {
        if (choice.selected) {
          acc += choice.price
        }
      })
      return acc
    }, 0)
  }

  const [choicesPrice, setChoicesPrice] = useState(calculateChoicesPrice())

  const addProductToCart = () => {
    const choices = []
    let isEnoughChoices = true
    selectedModifiers.forEach(modifier => {
      const choiceCount = modifier.choiceCount
      if (choiceCount < modifier.required) {
        isEnoughChoices = false
      }
      modifier.choices.forEach(choice => {
        if (choice.selected) {
          choices.push({
            ...choice,
            modifierId: modifier.id,
          })
        }
      })
    })
    if (!isEnoughChoices) {
      alert('Sorry, you must select more choices.')
      return
    }
    dispatch.user.addChoicesCartItem({
      id,
      count: quantity,
      choices,
      choicesPrice,
    })
    dispatch.notification.setMessage({
      message: `${name} added to cart!`,
      level: 'success',
    })
    if (isMobile) {
      history.goBack()
    } else {
      onRequestClose()
    }
    setSelectedModifiers(cloneDeep(defaultSelectedModifiers))
  }

  const updateSelectedChoice = (modifierIndex, choiceIndex) => {
    const modifier = selectedModifiers[modifierIndex]
    const isOneChoiceRequired =
      modifier.required === 1 && modifier.maxSelect === 1
    if (isOneChoiceRequired && modifier.choices[choiceIndex].selected) {
      return
    }
    if (!dispatch.restaurant.getIsChoiceActive(modifier.id, choiceIndex)) {
      alert('Sorry, this option is sold out.')
      return
    }
    const choices = modifier.choices.map((choice, index) => {
      let selected = choice.selected
      let choiceCount = modifier.choiceCount
      if (isOneChoiceRequired && choiceIndex !== index && selected) {
        selected = false
        choiceCount--
      } else if (choiceIndex === index) {
        if (selected) {
          selected = false
          choiceCount--
        } else {
          selected = true
          choiceCount++
        }
      }
      if (
        !isOneChoiceRequired &&
        modifier.maxSelect > 0 &&
        choiceCount > modifier.maxSelect
      ) {
        alert('Sorry, you have selected too many choices.')
      } else {
        modifier.choiceCount = choiceCount
        choice.selected = selected
      }
      return choice
    })
    modifier.choices = choices
    selectedModifiers[modifierIndex] = modifier
    const newChoicesPrice = calculateChoicesPrice()
    setSelectedModifiers([...selectedModifiers])
    setChoicesPrice(newChoicesPrice)
  }

  const incrementQuantity = () => {
    setQuantity(quantity + 1)
  }

  const decrementQuantity = () => {
    setQuantity(quantity - 1)
  }

  return (
    <>
      <MobileScreen>
        <PageBackground>
          <PageHeader
            title={name}
            LeftButton={<BackButton onClick={history.goBack} />}
          />
          <PageContainer offset="header" column>
            {imageUrl && <ProductHeader product={product} />}
            <CFView fill pt="20px" ph="10px">
              {selectedModifiers.length > 0 && (
                <CFView>
                  {selectedModifiers.map((modifier, modifierIndex) => (
                    <ModifierItem key={modifierIndex} modifier={modifier}>
                      {modifier.choices.map((choice, choiceIndex) => (
                        <ChoiceItem
                          key={choiceIndex}
                          choiceIndex={choiceIndex}
                          modifier={modifier}
                          choice={choice}
                          onClick={() =>
                            updateSelectedChoice(modifierIndex, choiceIndex)
                          }
                        />
                      ))}
                    </ModifierItem>
                  ))}
                </CFView>
              )}
              <CFView column center mt="20px" mb="50px">
                <CFView
                  h2
                  bold
                  color={dispatch.restaurant.getStyles().fontColor}
                  pv="20px"
                  textCenter
                >
                  Quantity
                </CFView>
                <CFView row w="100%" justifyBetween alignCenter ph="10px">
                  <SubtractQuantityButton
                    disabled={quantity === 1}
                    onClick={decrementQuantity}
                  />
                  <CFView
                    h2
                    bold
                    color={dispatch.restaurant.getStyles().fontColor}
                  >
                    {quantity}
                  </CFView>
                  <AddQuantityButton
                    disabled={quantity === 50}
                    onClick={incrementQuantity}
                  />
                </CFView>
              </CFView>
            </CFView>
            <CFView w="100%" ph="20px" pb="20px">
              <AddCartButton
                price={(price + choicesPrice) * quantity}
                onClick={addProductToCart}
              />
            </CFView>
          </PageContainer>
        </PageBackground>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          minHeight="400px"
          w="500px"
          pt="20px"
          ph="10px"
          column
          justifyBetween
          alignCenter
        >
          {imageUrl && (
            <CFImage
              h="180px"
              w="300px"
              mb="20px"
              cover
              br={5}
              src={imageUrl}
              alt="Product"
              boxShadow="1px 1px 3px rgba(0,0,0,.8)"
            />
          )}
          {selectedModifiers.length > 0 && (
            <CFView ph="10px" w="100%">
              {selectedModifiers.map((modifier, modifierIndex) => (
                <ModifierItem key={modifierIndex} modifier={modifier}>
                  {modifier.choices.map((choice, choiceIndex) => (
                    <ChoiceItem
                      key={choiceIndex}
                      choiceIndex={choiceIndex}
                      modifier={modifier}
                      choice={choice}
                      onClick={() =>
                        updateSelectedChoice(modifierIndex, choiceIndex)
                      }
                    />
                  ))}
                </ModifierItem>
              ))}
            </CFView>
          )}
          <CFView column center w="95%" mt="20px" mb="50px">
            <CFView
              h2
              bold
              mb="20px"
              textCenter
              color={
                dispatch.restaurant.getStyles().brandColor
                  ? dispatch.restaurant.getStyles().fontColor
                  : 'black'
              }
            >
              Quantity
            </CFView>
            <CFView row w="300px" justifyBetween alignCenter>
              <SubtractQuantityButton
                disabled={quantity === 1}
                onClick={decrementQuantity}
              />
              <CFView
                h2
                bold
                color={
                  dispatch.restaurant.getStyles().brandColor
                    ? dispatch.restaurant.getStyles().fontColor
                    : 'black'
                }
              >
                {quantity}
              </CFView>
              <AddQuantityButton
                disabled={quantity === 50}
                onClick={incrementQuantity}
              />
            </CFView>
          </CFView>
          <CFView w="100%" mb="20px">
            <AddCartButton
              price={(price + choicesPrice) * quantity}
              onClick={addProductToCart}
            />
          </CFView>
        </CFView>
      </DefaultScreen>
    </>
  )
}
