import React, { Component } from 'react'
import {
  CFIcon,
  CFSelect,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { COLOR } from 'styles'
import { dispatch } from 'store'

export default class StoreClosedBanner extends Component {
  componentDidMount() {
    this.internval = setInterval(() => {
      this.forceUpdate()
    }, 1000)
  }
  componentWillUnmount() {
    clearInterval(this.internval)
  }
  render() {
    return (
      <CFSelect selectorNot={dispatch.restaurant.getIsStoreOpen}>
        <MobileScreen>
          <CFView
            h="45px"
            bg={COLOR.red}
            center
            boxShadow="0 1px 3px rgba(0,0,0,.8)"
          >
            <CFIcon icon="exclamation-triangle" color="white" />
            <CFView bold h4 color="white" ml="10px">
              ORDERING CLOSED
            </CFView>
          </CFView>
        </MobileScreen>
        <DefaultScreen>
          <CFView
            h="45px"
            w="410px"
            mv="5px"
            ml="5px"
            bg={COLOR.red}
            br="5px"
            center
            boxShadow="1px 1px 3px rgba(0,0,0,.8)"
          >
            <CFIcon icon="exclamation-triangle" color="white" />
            <CFView bold h3 color="white" ml="10px">
              ORDERING CLOSED
            </CFView>
          </CFView>
        </DefaultScreen>
      </CFSelect>
    )
  }
}
