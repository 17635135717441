import React from 'react'
import { CFIcon, CFView } from 'components'
import { dispatch } from 'store'
import { COLOR } from 'styles'

export default props => (
  <CFView
    h="45px"
    w="100px"
    bg={
      dispatch.restaurant.getStyles().brandColor
        ? dispatch.restaurant.getStyles().brandColor
        : COLOR.brand
    }
    br="25px"
    center
    hover
    boxShadow="1px 1px 3px rgba(0,0,0,.8)"
    {...props}
  >
    <CFIcon icon="plus" color="white" fontSize="21px" />
  </CFView>
)
