import React from 'react'
import { CFView } from 'components'
import { dispatch } from 'store'
import { COLOR } from 'styles'

export default ({ className, toggled, disabled, label, color, ...props }) => {
  return disabled ? (
    <CFView
      h="45px"
      w="100%"
      br="25px"
      bg={'rgba(255,255,255,.3)'}
      textCenter
      center
      boxShadow="1px 1px 3px rgba(0,0,0,.8)"
      {...props}
    >
      <CFView bold color="white" fontSize={18}>
        {label}
      </CFView>
    </CFView>
  ) : toggled ? (
    <CFView
      h="45px"
      w="100%"
      br="25px"
      bg={
        dispatch.restaurant.getStyles().brandColor
          ? dispatch.restaurant.getStyles().brandColor
          : COLOR.brand
      }
      textCenter
      center
      hover
      boxShadow="1px 1px 3px rgba(0,0,0,.8)"
      {...props}
    >
      <CFView bold color="white" fontSize={18}>
        {label}
      </CFView>
    </CFView>
  ) : (
    <CFView
      h="45px"
      w="100%"
      br="25px"
      bg={COLOR.mediumGrey}
      textCenter
      center
      hover
      boxShadow="1px 1px 3px rgba(0,0,0,.8)"
      {...props}
    >
      <CFView bold color={COLOR.darkgrey} fontSize={18}>
        {label}
      </CFView>
    </CFView>
  )
}
