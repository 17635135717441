import React from 'react'
import { CFView, ThemedText } from 'components'
import QuantityIcon from './QuantityIcon'

export default ({ charge }) => {
  const { name, price } = charge
  return (
    <CFView w="100%" row justifyBetween mv="10px">
      <CFView fill row alignStart>
        <QuantityIcon count={1} />
        <ThemedText h5 bold textLeft mt="3px" ml="10px" mr="10px">
          Add Charge: {name}
        </ThemedText>
      </CFView>
      <ThemedText h5 bold mt="2px" ml="10px">{`$${price.toFixed(
        2
      )}`}</ThemedText>
    </CFView>
  )
}
