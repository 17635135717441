import React from 'react'
import { CFIcon, CFView } from 'components'
import { COLOR } from 'styles'

export default ({ moreThanOne, ...props }) => (
  <CFView hover {...props}>
    <CFIcon
      icon={moreThanOne ? 'minus-circle' : 'times-circle'}
      color={COLOR.red}
      fontSize="24px"
    />
  </CFView>
)
