import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  BackButton,
  Card,
  CFImage,
  CFSelect,
  CFView,
  DefaultScreen,
  HorizontalLine,
  MobileScreen,
  LabelInput,
  Navbar,
  PageContainer,
  PageBackground,
  PageHeader,
  PrimaryButton,
  ThemedText,
} from 'components'
import { cartEmpty } from 'images'
import { Rewards } from 'pages'
import CartItem from './CartItem'
import RewardItem from './RewardItem'
import RemoveItemButton from './RemoveItemButton'
import RewardButton from './RewardButton'
import { dispatch } from 'store'
import { COLOR } from 'styles'

export default () => {
  const history = useHistory()

  const removeCartItem = (product, index) => {
    dispatch.user.subtractChoicesCartItem(index)
    dispatch.notification.setMessage({
      message: `${product.name} removed!`,
      level: 'success',
    })
  }

  const removeRewardItem = reward => {
    const { id, name } = reward
    dispatch.user.removePromo(id)
    dispatch.notification.setMessage({
      message: `${name} unredeemed!`,
      level: 'success',
    })
  }

  const orderAsGuest = async () => {
    let message =
      'Order history is not available as Guest. Do you want to continue?'
    if (
      (dispatch.restaurant.getPaymentMethodType() === 'online' ||
        dispatch.restaurant.getPaymentMethodType() === 'both') &&
      dispatch.restaurant.getRewardsEnabled()
    ) {
      message =
        'Order history, rewards, and online payment are not available as Guest. Do you want to continue?'
    } else if (dispatch.restaurant.getRewardsEnabled()) {
      message =
        'Order history and rewards are not available as Guest. Do you want to continue?'
    } else if (
      dispatch.restaurant.getPaymentMethodType() === 'online' ||
      dispatch.restaurant.getPaymentMethodType() === 'both'
    ) {
      message =
        'Order history and online payment are not available as Guest. Do you want to continue?'
    }
    if (window.confirm(message)) {
      if (dispatch.user.getIsPlacingOrder()) {
        alert('Your order is already being placed! Please wait.')
        return
      }
      try {
        const order = await dispatch.user.createGuestOrder()
        dispatch.notification.setMessage({
          message: 'Your order has been processed!',
          level: 'success',
        })
        history.push('/order_complete', order)
      } catch (e) {
        alert(
          'Sorry, we could not process your order. Please call the restaurant to make your order.'
        )
      }
    }
  }

  return (
    <PageBackground>
      <MobileScreen>
        <PageHeader
          title="My Cart"
          LeftButton={<BackButton onClick={() => history.push('/menu')} />}
        />
        <CFSelect selector={dispatch.user.getIsCartEmpty}>
          {isCartEmpty => {
            return isCartEmpty ? (
              <Card column center mt="20px" mh="10px" p="20px">
                <CFImage
                  src={
                    dispatch.restaurant.getImages().cartEmpty
                      ? dispatch.restaurant.getImages().cartEmpty
                      : cartEmpty
                  }
                  w="220px"
                  mb="20px"
                />{' '}
                <ThemedText h3 bold>
                  Cart is empty.
                </ThemedText>
                <ThemedText h3 bold>
                  Please add items from menu.
                </ThemedText>
              </Card>
            ) : (
              <PageContainer offset="header" ph="10px" pt="20px">
                <CFView h="100%" column alignCenter>
                  <CFView fill w="100%">
                    <Card pt="20px" ph="10px" mb="40px">
                      <CFSelect
                        selector={
                          dispatch.user.getChoicesCartWithProductDetails
                        }
                      >
                        {cart =>
                          cart.map((product, index) => (
                            <CartItem
                              key={index}
                              product={product}
                              RemoveButton={
                                <RemoveItemButton
                                  onClick={() => removeCartItem(product, index)}
                                  moreThanOne={product.count > 1}
                                />
                              }
                            />
                          ))
                        }
                      </CFSelect>
                      <CFSelect
                        selector={dispatch.user.getValidPromosWithDetails}
                      >
                        {promos =>
                          Object.values(promos).map((reward, index) => (
                            <RewardItem
                              key={index}
                              reward={reward}
                              RemoveButton={
                                <RemoveItemButton
                                  onClick={() => removeRewardItem(reward)}
                                />
                              }
                            />
                          ))
                        }
                      </CFSelect>
                      <HorizontalLine mv="10px" color="black" />
                      <CFView row justifyBetween mb="20px">
                        <ThemedText bold>Subtotal:</ThemedText>
                        <CFSelect
                          selector={dispatch.user.getCartSubtotalAfterRewards}
                        >
                          {subtotal => (
                            <CFView
                              bold
                              color={COLOR.red}
                            >{`$${subtotal.toFixed(2)}`}</CFView>
                          )}
                        </CFSelect>
                      </CFView>
                      <CFSelect
                        selector={dispatch.restaurant.getCommentsEnabled}
                      >
                        <CFView>
                          <CFSelect selector={dispatch.user.getNotes}>
                            {notes => (
                              <LabelInput
                                multiline
                                mb="20px"
                                label="Special Requests"
                                placeholder="Add any special requests (food allergies, extra sauce, etc). Additional charge may apply."
                                placeholderstyle={{ color: 'grey' }}
                                maxLength={128}
                                onChange={e =>
                                  dispatch.user.setNotes(e.target.value)
                                }
                                value={notes}
                              />
                            )}
                          </CFSelect>
                        </CFView>
                      </CFSelect>
                    </Card>
                  </CFView>
                  <CFView pb="20px">
                    <CFSelect selector={dispatch.restaurant.getRewardsEnabled}>
                      <CFSelect
                        selector={dispatch.restaurant.getIsOrderingEnabled}
                      >
                        <RewardButton
                          onClick={() => history.push('/rewards/cart')}
                        />
                      </CFSelect>
                    </CFSelect>
                    <CFSelect
                      selector={{
                        guestOrderingEnabled:
                          dispatch.restaurant.getGuestOrderingEnabled,
                        isDineIn: dispatch.restaurant.getIsDineIn,
                        isLoggedIn: dispatch.user.getIsLoggedIn,
                        isPlacingOrder: dispatch.user.getIsPlacingOrder,
                      }}
                    >
                      {({
                        guestOrderingEnabled,
                        isDineIn,
                        isLoggedIn,
                        isPlacingOrder,
                      }) => {
                        return (
                          guestOrderingEnabled &&
                          isDineIn &&
                          !isLoggedIn && (
                            <PrimaryButton
                              mb="15px"
                              label={
                                isPlacingOrder
                                  ? 'PLACING ORDER...'
                                  : 'ORDER AS GUEST'
                              }
                              disabled={isPlacingOrder}
                              onClick={orderAsGuest}
                            />
                          )
                        )
                      }}
                    </CFSelect>
                    <CFSelect
                      selector={dispatch.restaurant.getIsOrderingEnabled}
                    >
                      <CFSelect selector={dispatch.user.getIsLoggedIn}>
                        <PrimaryButton
                          label="CHECKOUT"
                          onClick={() => history.push('/checkout')}
                        />
                      </CFSelect>
                      <CFSelect selectorNot={dispatch.user.getIsLoggedIn}>
                        <PrimaryButton
                          label="LOGIN"
                          onClick={() => history.push('/auth/cart')}
                        />
                      </CFSelect>
                    </CFSelect>
                  </CFView>
                </CFView>
              </PageContainer>
            )
          }}
        </CFSelect>
      </MobileScreen>
      <DefaultScreen>
        <Navbar />
        <PageContainer offset="defaultNavbar">
          <CFView center p="20px">
            <Card minHeight="400px" maxWidth="1080px" pv="25px" ph="40px" row>
              <CFSelect
                selector={[
                  dispatch.restaurant.getRewardsEnabled,
                  dispatch.user.getPointsWithPromoApplied,
                ]}
              >
                {([rewardsEnabled, pointsWithPromoApplied]) =>
                  rewardsEnabled && (
                    <CFView mr="40px">
                      <Rewards
                        pointsWithPromoApplied={pointsWithPromoApplied}
                      />
                    </CFView>
                  )
                }
              </CFSelect>
              <CFView w="680px">
                <CFSelect selector={dispatch.user.getIsCartEmpty}>
                  {isCartEmpty => {
                    return isCartEmpty ? (
                      <CFView column center>
                        <CFImage
                          src={
                            dispatch.restaurant.getImages().cartEmpty
                              ? dispatch.restaurant.getImages().cartEmpty
                              : cartEmpty
                          }
                          w="220px"
                          mv="20px"
                        />
                        <ThemedText h3 bold>
                          Cart is empty.
                        </ThemedText>
                        <ThemedText h3 bold>
                          Please add items from menu.
                        </ThemedText>
                      </CFView>
                    ) : (
                      <CFView h="100%" column justifyBetween>
                        <CFView column>
                          <CFSelect
                            selector={
                              dispatch.user.getChoicesCartWithProductDetails
                            }
                          >
                            {cart =>
                              cart.map((product, index) => (
                                <CartItem
                                  key={index}
                                  product={product}
                                  RemoveButton={
                                    <RemoveItemButton
                                      moreThanOne={product.count > 1}
                                      onClick={() =>
                                        removeCartItem(product, index)
                                      }
                                    />
                                  }
                                />
                              ))
                            }
                          </CFSelect>
                          <CFSelect
                            selector={dispatch.user.getValidPromosWithDetails}
                          >
                            {promos =>
                              Object.values(promos).map((reward, index) => (
                                <RewardItem
                                  key={index}
                                  reward={reward}
                                  RemoveButton={
                                    <RemoveItemButton
                                      onClick={() => removeRewardItem(reward)}
                                    />
                                  }
                                />
                              ))
                            }
                          </CFSelect>
                          <HorizontalLine mv="10px" color="black" />
                          <CFView row justifyBetween mb="20px">
                            <ThemedText bold>Subtotal:</ThemedText>
                            <CFSelect
                              selector={
                                dispatch.user.getCartSubtotalAfterRewards
                              }
                            >
                              {subtotal => (
                                <CFView
                                  bold
                                  color={COLOR.red}
                                >{`$${subtotal.toFixed(2)}`}</CFView>
                              )}
                            </CFSelect>
                          </CFView>
                        </CFView>
                        <CFView column>
                          <CFSelect
                            selector={dispatch.restaurant.getCommentsEnabled}
                          >
                            <CFSelect selector={dispatch.user.getNotes}>
                              {notes => (
                                <LabelInput
                                  multiline
                                  mb="30px"
                                  label="Special Requests"
                                  placeholder="Add any special requests (food allergies, extra sauce, etc). Additional charge may apply."
                                  placeholderstyle={{ color: 'grey' }}
                                  maxLength={128}
                                  onChange={e =>
                                    dispatch.user.setNotes(e.target.value)
                                  }
                                  value={notes}
                                />
                              )}
                            </CFSelect>
                          </CFSelect>
                          <CFSelect selector={dispatch.user.getIsLoggedIn}>
                            <PrimaryButton
                              selfCenter
                              label="CHECKOUT"
                              onClick={() => history.push('/checkout')}
                            />
                          </CFSelect>
                          <CFSelect selectorNot={dispatch.user.getIsLoggedIn}>
                            <PrimaryButton
                              selfCenter
                              label="LOGIN"
                              onClick={() => history.push('/auth/cart')}
                            />
                          </CFSelect>
                        </CFView>
                      </CFView>
                    )
                  }}
                </CFSelect>
              </CFView>
            </Card>
          </CFView>
        </PageContainer>
      </DefaultScreen>
    </PageBackground>
  )
}
