import React from 'react'
import { Card, CFView, ThemedText } from 'components'

const numbers = [
  'Many',
  'One',
  'Two',
  'Three',
  'Four',
  'Five',
  'Six',
  'Seven',
  'Eight',
  'Nine',
]

export default ({ modifier, children }) => {
  const { maxSelect, name, required } = modifier
  return (
    <Card mb="20px" pb="15px">
      <ThemedText h2 bold mb="10px" p="10px 20px" bg="rgba(150,150,150,.5)">
        {name}
        <CFView row justifyBetween>
          <ThemedText roboto h5 subtitle>
            Choose {numbers[maxSelect]}
          </ThemedText>
          {required > 0 ? (
            <ThemedText roboto h5 subtitle>
              (Required)
            </ThemedText>
          ) : (
            <ThemedText roboto h5 subtitle>
              (Optional)
            </ThemedText>
          )}
        </CFView>
      </ThemedText>
      <CFView column mh="20px">
        {children}
      </CFView>
    </Card>
  )
}
