import React from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import {
  BackButton,
  Card,
  CFSelect,
  CFView,
  DefaultScreen,
  HorizontalLine,
  MobileScreen,
  OrderStatus,
  PageBackground,
  PageContainer,
  PageHeader,
  ThemedText,
} from 'components'
import ProductItem from './ProductItem'
import AddedChargeItem from './AddedChargeItem'
import RewardItem from './RewardItem'
import { isEmpty } from 'lodash'
import { COLOR } from 'styles'

export default ({ location, order = {} }) => {
  const history = useHistory()
  if (isEmpty(order) && !!location) {
    order = location.state
  }
  const {
    createdAt,
    orderNumber,
    orderType,
    paymentMethod,
    rewardsDiscount = 0,
    choicesProducts = [],
    id,
    subTotal,
    deliveryFee = 0,
    tipAmount = 0,
    taxAmount = 0,
    bagFeeAmount = 0,
    rewards = null,
    refunds = [],
    status = '',
    totalRefundAmount = 0,
    addedCharges = null,
    firstOrderDiscountAmount = 0,
    pickupDiscountAmount = 0,
    promoDiscountAmount = 0,
    promoCode = {},
    total,
  } = order

  return (
    <>
      <MobileScreen>
        <PageBackground>
          <PageHeader
            title="Order Details"
            LeftButton={
              <BackButton onClick={() => history.push('/order_history')} />
            }
            zIndex={1}
          />
          <PageContainer offset="header" ph="10px" pv="20px">
            <OrderStatus orderId={id} />
            <CFView mt="32%" mb="15px">
              <Card column p="10px">
                <CFView row justifyBetween mb="5px">
                  <ThemedText>{`Order #${orderNumber}`}</ThemedText>
                  <ThemedText>{`${moment(createdAt).format(
                    'LT, DD/MM/YY'
                  )}`}</ThemedText>
                </CFView>
                <CFView row justifyBetween>
                  <ThemedText>{orderType}</ThemedText>
                  <ThemedText>
                    {paymentMethod === 'online'
                      ? 'Paid Online'
                      : 'Paid In-Person'}
                  </ThemedText>
                </CFView>
              </Card>
            </CFView>
            <Card p="10px" mb="10px">
              <CFView column>
                {Object.values(choicesProducts).map((product, index) => (
                  <ProductItem key={index} product={product} />
                ))}
                {!!rewards &&
                  Object.values(rewards).map((reward, index) => {
                    return <RewardItem key={index} reward={reward} />
                  })}
                {!!addedCharges &&
                  addedCharges.map((charge, index) => (
                    <AddedChargeItem key={index} charge={charge} />
                  ))}
              </CFView>
              <HorizontalLine mv="10px" />
              <CFView>
                <CFSelect selector={subTotal > 0}>
                  <CFView row justifyBetween mv="5px">
                    <ThemedText>Subtotal</ThemedText>
                    <ThemedText>{`$${(subTotal - rewardsDiscount).toFixed(
                      2
                    )}`}</ThemedText>
                  </CFView>
                  {firstOrderDiscountAmount > 0 && (
                    <CFView row justifyBetween mv="5px">
                      <ThemedText>First Order Discount</ThemedText>
                      <ThemedText>{`-$${firstOrderDiscountAmount.toFixed(
                        2
                      )}`}</ThemedText>
                    </CFView>
                  )}
                  {pickupDiscountAmount > 0 && (
                    <CFView row justifyBetween mv="5px">
                      <ThemedText>Pickup Discount</ThemedText>
                      <ThemedText>{`-$${pickupDiscountAmount.toFixed(
                        2
                      )}`}</ThemedText>
                    </CFView>
                  )}
                  {promoDiscountAmount > 0 && (
                    <CFView row justifyBetween mv="5px">
                      <ThemedText>{`Promo Discount (${promoCode.code})`}</ThemedText>
                      <ThemedText>{`-$${promoDiscountAmount.toFixed(
                        2
                      )}`}</ThemedText>
                    </CFView>
                  )}
                  {deliveryFee > 0 && (
                    <CFView row justifyBetween mv="5px">
                      <ThemedText>Delivery Fee</ThemedText>
                      <ThemedText>{`$${deliveryFee.toFixed(2)}`}</ThemedText>
                    </CFView>
                  )}
                  <CFView row justifyBetween mv="5px">
                    <ThemedText>Taxes</ThemedText>
                    <ThemedText>{`$${taxAmount.toFixed(2)}`}</ThemedText>
                  </CFView>
                  {bagFeeAmount > 0 && (
                    <CFView row justifyBetween mv="5px">
                      <ThemedText>Bag Fee</ThemedText>
                      <ThemedText>{`$${bagFeeAmount.toFixed(2)}`}</ThemedText>
                    </CFView>
                  )}
                  {tipAmount > 0 && (
                    <CFView row justifyBetween mv="5px">
                      <ThemedText>Tip</ThemedText>
                      <ThemedText>{`$${tipAmount.toFixed(2)}`}</ThemedText>
                    </CFView>
                  )}
                  {refunds.length > 0 &&
                    refunds.map(refund => (
                      <CFView row justifyBetween mv="5px">
                        <ThemedText>{`Refund - ${refund.description}`}</ThemedText>
                        <ThemedText>{`-$${refund.amount.toFixed(
                          2
                        )}`}</ThemedText>
                      </CFView>
                    ))}
                  {status === 'Cancelled' && (
                    <CFView row justifyBetween mv="5px">
                      <ThemedText>Refund</ThemedText>
                      <ThemedText>{`-$${totalRefundAmount.toFixed(
                        2
                      )}`}</ThemedText>
                    </CFView>
                  )}
                  <HorizontalLine mv="10px" color="black" />
                </CFSelect>
                <CFView row justifyBetween mv="5px">
                  <ThemedText bold>Total</ThemedText>
                  <ThemedText bold color={COLOR.red}>
                    {total === 0
                      ? 'FREE'
                      : `$${(total - totalRefundAmount).toFixed(2)}`}
                  </ThemedText>
                </CFView>
              </CFView>
            </Card>
          </PageContainer>
        </PageBackground>
      </MobileScreen>
      <DefaultScreen>
        <CFView column w="580px" p="20px">
          <OrderStatus orderId={id} />
          <Card column mt="25%" mb="20px" pv="10px" ph="20px">
            <CFView row justifyBetween mb="5px">
              <ThemedText>{`Order #${orderNumber}`}</ThemedText>
              <ThemedText>{`${moment(createdAt).format('LT')}, ${moment(
                createdAt
              ).format('DD/MM/YY')}`}</ThemedText>
            </CFView>
            <CFView row justifyBetween>
              <ThemedText>{orderType}</ThemedText>
              <ThemedText>
                {paymentMethod === 'online' ? 'Paid Online' : 'Paid In-Person'}
              </ThemedText>
            </CFView>
          </Card>
          <Card pv="10px" ph="20px">
            <CFView column>
              {Object.values(choicesProducts).map((product, index) => (
                <ProductItem key={index} product={product} />
              ))}
              {!!rewards &&
                Object.values(rewards).map((reward, index) => (
                  <RewardItem key={index} reward={reward} />
                ))}
              {!!addedCharges &&
                addedCharges.map((charge, index) => (
                  <AddedChargeItem key={index} charge={charge} />
                ))}
            </CFView>
            <HorizontalLine mv="10px" color="black" />
            <CFSelect selector={subTotal > 0}>
              <CFView row justifyBetween mv="5px">
                <ThemedText>Subtotal</ThemedText>
                <ThemedText>{`$${(subTotal - rewardsDiscount).toFixed(
                  2
                )}`}</ThemedText>
              </CFView>
              {firstOrderDiscountAmount > 0 && (
                <CFView row justifyBetween mv="5px">
                  <ThemedText>First Order Discount</ThemedText>
                  <ThemedText>{`-$${firstOrderDiscountAmount.toFixed(
                    2
                  )}`}</ThemedText>
                </CFView>
              )}
              {pickupDiscountAmount > 0 && (
                <CFView row justifyBetween mv="5px">
                  <ThemedText>Pickup Discount</ThemedText>
                  <ThemedText>{`-$${pickupDiscountAmount.toFixed(
                    2
                  )}`}</ThemedText>
                </CFView>
              )}
              {promoDiscountAmount > 0 && (
                <CFView row justifyBetween mv="5px">
                  <ThemedText>{`Promo Discount (${promoCode.code})`}</ThemedText>
                  <ThemedText>{`-$${promoDiscountAmount.toFixed(
                    2
                  )}`}</ThemedText>
                </CFView>
              )}
              {deliveryFee > 0 && (
                <CFView row justifyBetween mv="5px">
                  <ThemedText>Delivery Fee</ThemedText>
                  <ThemedText>{`$${deliveryFee.toFixed(2)}`}</ThemedText>
                </CFView>
              )}
              <CFView row justifyBetween mv="5px">
                <ThemedText>Taxes</ThemedText>
                <ThemedText>{`$${taxAmount.toFixed(2)}`}</ThemedText>
              </CFView>
              {bagFeeAmount > 0 && (
                <CFView row justifyBetween mv="5px">
                  <ThemedText>Bag Fee</ThemedText>
                  <ThemedText>{`$${bagFeeAmount.toFixed(2)}`}</ThemedText>
                </CFView>
              )}
              {tipAmount > 0 && (
                <CFView row justifyBetween mv="5px">
                  <ThemedText>Tip</ThemedText>
                  <ThemedText>{`$${tipAmount.toFixed(2)}`}</ThemedText>
                </CFView>
              )}
              {refunds.length > 0 &&
                refunds.map(refund => (
                  <CFView row justifyBetween mv="5px">
                    <ThemedText>{`Refund - ${refund.description}`}</ThemedText>
                    <ThemedText>{`-$${refund.amount.toFixed(2)}`}</ThemedText>
                  </CFView>
                ))}
              {status === 'Cancelled' && (
                <CFView row justifyBetween mv="5px">
                  <ThemedText>Refund</ThemedText>
                  <ThemedText>{`-$${totalRefundAmount.toFixed(2)}`}</ThemedText>
                </CFView>
              )}
              <HorizontalLine mv="10px" color="black" />
            </CFSelect>
            <CFView row justifyBetween mv="5px">
              <ThemedText bold>Total</ThemedText>
              <CFView bold color={COLOR.red}>
                {total === 0
                  ? 'FREE'
                  : `$${(total - totalRefundAmount).toFixed(2)}`}
              </CFView>
            </CFView>
          </Card>
        </CFView>
      </DefaultScreen>
    </>
  )
}
