import React from 'react'
import { CFImage, CFView } from 'components'
import { dispatch } from 'store'

export default props => {
  return (
    <CFView pulsate {...props}>
      <CFImage
        w="260px"
        src={dispatch.restaurant.getImages().rewardRedeemButton}
        alt="Redeem Button"
      />
    </CFView>
  )
}
