import React from 'react'
import { CFView } from 'components'
import { dispatch } from 'store'
import { COLOR } from 'styles'

export default ({ label, disabled, onClick, ...props }) => {
  return (
    <CFView
      w="280px"
      h="45px"
      bg={
        disabled
          ? COLOR.disabled
          : dispatch.restaurant.getStyles().brandColor
          ? dispatch.restaurant.getStyles().brandColor
          : COLOR.brand
      }
      br="25px"
      boxShadow="1px 1px 3px rgba(0,0,0,.8)"
      center
      hover
      onClick={!disabled ? onClick : undefined}
      {...props}
    >
      <CFView white bold>
        {label}
      </CFView>
    </CFView>
  )
}
