import React from 'react'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import { COLOR } from 'styles'
import { dispatch } from 'store'

export default ({ category, selected, ...props }) => {
  const { name, imageUrl } = category
  return (
    <>
      <MobileScreen>
        <CFView h="180px" w="48vw" pb="20px" {...props}>
          <CFView
            h="75%"
            w="95%"
            m="5px 5px 0 5px"
            relative
            transition
            br="5px"
            boxShadow="1px 1px 3px rgba(0,0,0,.8)"
          >
            <CFImage
              h="100%"
              w="100%"
              cover
              absolute
              br="5px"
              zIndex={2}
              src={imageUrl}
              alt="Category"
            />
          </CFView>
          <CFView fill center>
            <CFView
              h4
              bold
              center
              textCenter
              zIndex={4}
              pt="8px"
              color={dispatch.restaurant.getStyles().fontColor}
            >
              {name.toUpperCase()}
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView h="200px" w="200px" m="5px" pb="20px" hover {...props}>
          <CFView
            h="82%"
            w="100%"
            relative
            br="5px"
            boxShadow="1px 1px 3px rgba(0,0,0,.8)"
          >
            <CFImage
              h="100%"
              w="100%"
              cover
              absolute
              br="5px"
              zIndex={2}
              src={imageUrl}
              alt="Category"
            />
            <CFView
              h="100%"
              w="100%"
              bg={selected ? 'transparent' : 'rgba(0,0,0,.5)'}
              br="5px"
              absolute
              zIndex={3}
            />
          </CFView>
          <CFView fill center>
            <CFView
              h4
              bold
              center
              color={
                selected
                  ? COLOR.red
                  : dispatch.restaurant.getStyles().theme === 'dark'
                  ? 'white'
                  : 'black'
              }
              textCenter
              zIndex={4}
              pt="8px"
            >
              {name.toUpperCase()}
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </>
  )
}
