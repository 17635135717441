import React, { useState, useEffect } from 'react'
import {
  Card,
  CFImage,
  CFSelect,
  CFView,
  LabelInput,
  PageBackground,
  PageContainer,
  PrimaryButton,
  Navbar,
  ThemedText,
} from 'components'
import RequestButton from './RequestButton'
import { requestConfirm } from 'images'
import { dispatch } from 'store'

export default () => {
  const [requestDescription, setRequestDescription] = useState('')

  useEffect(() => {
    const requestId = localStorage.getItem('requestId')
    const lastConfirmedRequestId = localStorage.getItem(
      'lastConfirmedRequestId'
    )
    if (
      requestId &&
      requestId !== lastConfirmedRequestId &&
      !dispatch.restaurant.getConfirmingRequest()
    ) {
      dispatch.restaurant.setConfirmingRequest(true)
      dispatch.restaurant.subscribeRequest(requestId)
    }
  })

  const submitRequest = async description => {
    if (!dispatch.restaurant.getIsStoreOpen()) {
      alert('Sorry, the restuarant is closed.')
      return
    }
    let submittedDescription = description
    if (description === 'Custom') {
      submittedDescription = requestDescription.trim()
      if (submittedDescription === '') {
        alert('Custom request cannot be empty.')
        return
      }
    }
    dispatch.restaurant.setConfirmingRequest(true)
    try {
      const requestDoc = await dispatch.restaurant.createRequest(
        submittedDescription
      )
      dispatch.user.createNotificationForRequest()
      const requestId = requestDoc.id
      localStorage.setItem('requestId', requestId)
      await dispatch.restaurant.subscribeRequest(requestId)
      if (description === 'Custom') {
        setRequestDescription('')
      }
    } catch (e) {
      alert('Sorry, there was problem submitting your request.')
    }
  }

  return (
    <PageBackground>
      <PageContainer offset="navbar">
        <CFSelect selector={dispatch.restaurant.getConfirmingRequest}>
          {confirmingRequest =>
            confirmingRequest ? (
              <CFView h="100%" p="20px" column center>
                <Card column center w="80%" pt="20px">
                  <ThemedText h2 bold mb="20px">
                    Confirming Request
                  </ThemedText>
                  <CFImage
                    src={
                      dispatch.restaurant.getImages().confirmingRequest
                        ? dispatch.restaurant.getImages().confirmingRequest
                        : requestConfirm
                    }
                    w="100%"
                  />
                </Card>
              </CFView>
            ) : (
              <CFView h="100%" column pt="20px" ph="20px">
                <CFView fill>
                  <CFView
                    h4
                    bold
                    mb="10px"
                    color={dispatch.restaurant.getStyles().fontColor}
                  >
                    Please choose a request
                  </CFView>
                  <CFView column justifyCenter>
                    {dispatch.restaurant.getRequestTypes().map(requestType => (
                      <RequestButton
                        label={requestType.name}
                        icon={requestType.icon}
                        onClick={() => submitRequest(requestType.name)}
                      />
                    ))}
                  </CFView>
                </CFView>
                <CFView mt="10px" mb="20px">
                  <LabelInput
                    multiline
                    label="Custom Request"
                    color={dispatch.restaurant.getStyles().fontColor}
                    placeholder="Ex. Need more spoons"
                    placeholderstyle={{ color: 'grey' }}
                    maxLength={100}
                    value={requestDescription}
                    onChange={e => setRequestDescription(e.target.value)}
                  />
                </CFView>
                <CFView selfCenter pb="20px">
                  <PrimaryButton
                    label="SUBMIT REQUEST"
                    onClick={() => submitRequest('Custom')}
                  />
                </CFView>
              </CFView>
            )
          }
        </CFSelect>
      </PageContainer>
      <Navbar />
    </PageBackground>
  )
}
