import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import {
  CFIcon,
  CFSelect,
  CFView,
  DefaultScreen,
  MobileScreen,
  PrimaryModal,
  ThemedText,
} from 'components'
import { Locations } from 'pages'
import { dispatch } from 'store'

export default () => {
  const history = useHistory()
  const isMobile = useMediaQuery({ maxWidth: 767 })

  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    if (!dispatch.restaurant.getSelectedLocationId()) {
      if (isMobile) {
        history.push('/locations')
      } else {
        setShowModal(true)
      }
    }
  }, [history, isMobile])

  const navigateLocation = () => {
    if (isMobile) {
      history.push('/locations')
    } else {
      setShowModal(true)
    }
  }

  return (
    <CFView>
      <MobileScreen>
        <ThemedText
          h="45px"
          w="100%"
          bg={
            dispatch.restaurant.getStyles().theme === 'dark' ? 'black' : 'white'
          }
          boxShadow={
            dispatch.restaurant.getStyles().theme === 'dark'
              ? '0 1px 3px rgba(255,255,255,.8)'
              : '0 1px 3px rgba(0,0,0,.8)'
          }
          center
          onClick={navigateLocation}
        >
          <CFIcon icon="map-marker-alt" fontSize={22} />
          <CFView bold mh="10px" mb="2px">
            <CFSelect selector={dispatch.restaurant.getLocationName} />
          </CFView>
          <CFIcon icon="angle-down" fontSize={30} />
        </ThemedText>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          center
          br="25px"
          bc={
            dispatch.restaurant.getStyles().theme === 'dark' ? 'white' : 'black'
          }
          bw="2px"
          pv="5px"
          ph="15px"
          pointer
          onClick={navigateLocation}
        >
          <ThemedText bold mr="10px" mb="2px">
            <CFSelect selector={dispatch.restaurant.getLocationName} />
          </ThemedText>
          <ThemedText>
            <CFIcon icon="angle-down" fontSize={28} />
          </ThemedText>
        </CFView>
      </DefaultScreen>
      <PrimaryModal
        title="Locations"
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
      >
        <Locations onRequestClose={() => setShowModal(false)} />
      </PrimaryModal>
    </CFView>
  )
}
