import React from 'react'
import { CFView } from 'components'
import { dispatch } from 'store'
import { COLOR } from 'styles'

export default ({ children, subtitle = false, ...props }) => (
  <CFView
    color={
      subtitle
        ? dispatch.restaurant.getStyles().theme === 'dark'
          ? COLOR.lightGrey
          : COLOR.darkGrey
        : dispatch.restaurant.getStyles().theme === 'dark'
        ? 'white'
        : 'black'
    }
    {...props}
  >
    {children}
  </CFView>
)
