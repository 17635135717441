import React, { useState } from 'react'
import Fuse from 'fuse.js'
import { useHistory } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import {
  BackButton,
  CFSelect,
  CFView,
  DefaultScreen,
  IconInput,
  MobileScreen,
  PageBackground,
  PageContainer,
  PageHeader,
  PrimaryModal,
  ViewCartButton,
} from 'components'
import { Product } from 'pages'
import SearchItem from './SearchItem'
import { dispatch } from 'store'

export default () => {
  const history = useHistory()
  const isMobile = useMediaQuery({ maxWidth: 767 })

  const [query, setQuery] = useState('')
  const [showProductModal, setShowProductModal] = useState(false)
  const [product, setProduct] = useState({})

  const navigateProduct = product => {
    if (!dispatch.restaurant.getIsProductActive(product.id)) {
      alert('Sorry, this product is sold out.')
      return
    }
    if (isMobile) {
      history.push('/product', product)
    } else {
      setProduct(product)
      setShowProductModal(true)
    }
  }

  const options = {
    // isCaseSensitive: false,
    // includeScore: false,
    // shouldSort: true,
    // includeMatches: false,
    // findAllMatches: false,
    // minMatchCharLength: 1,
    // useExtendedSearch: false,
    threshold: 0,
    ignoreLocation: true,
    keys: ['name'],
  }
  const fuse = new Fuse(
    Object.values(dispatch.restaurant.getActiveProducts()),
    options
  )
  const searchResults = fuse.search(query)

  return (
    <>
      <MobileScreen>
        <PageBackground>
          <PageHeader
            title="Search Menu"
            LeftButton={<BackButton onClick={() => history.push('/menu')} />}
          />
          <PageContainer offset="header">
            <CFView m="10px 10px 0 10px">
              <IconInput
                icon="search"
                value={query}
                onChange={e => setQuery(e.target.value)}
                placeholder="Enter product name"
              />
            </CFView>
            <CFView column p="10px" mb="80px">
              {searchResults.map((search, index) => (
                <SearchItem
                  key={index}
                  product={search.item}
                  onClick={() => navigateProduct(search.item)}
                />
              ))}
            </CFView>
            <CFSelect selectorNot={dispatch.user.getIsCartEmpty}>
              <CFView w="100%" center fixed bottom="20px" zIndex={99}>
                <ViewCartButton onClick={() => history.push('/cart')} />
              </CFView>
            </CFSelect>
          </PageContainer>
        </PageBackground>
      </MobileScreen>
      <DefaultScreen>
        <CFView w="100%" column>
          <CFView m="5px 10px">
            <IconInput
              icon="search"
              value={query}
              onChange={e => setQuery(e.target.value)}
              placeholder="Enter product name"
            />
          </CFView>
          <CFView column p="5px 10px">
            {searchResults.map((search, index) => (
              <SearchItem
                key={index}
                product={search.item}
                onClick={() => navigateProduct(search.item)}
              />
            ))}
          </CFView>
        </CFView>
        <PrimaryModal
          title={product.name}
          isOpen={showProductModal}
          onRequestClose={() => setShowProductModal(false)}
        >
          <Product
            product={product}
            onRequestClose={() => setShowProductModal(false)}
          />
        </PrimaryModal>
      </DefaultScreen>
    </>
  )
}
