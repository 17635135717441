import React from 'react'
import { CFIcon, CFSelect, CFView } from 'components'
import { dispatch } from 'store'
import { COLOR } from 'styles'

export default props => {
  return (
    <CFView
      h="45px"
      w="280px"
      bg={
        dispatch.restaurant.getStyles().brandColor
          ? dispatch.restaurant.getStyles().brandColor
          : COLOR.brand
      }
      br="25px"
      boxShadow="1px 1px 3px rgba(0,0,0,.8)"
      zIndex={99}
      row
      justifyAround
      alignCenter
      {...props}
    >
      <CFIcon icon="shopping-cart" color="white" />
      <CFView white bold>
        VIEW MY CART
      </CFView>
      <CFSelect selector={dispatch.user.getCartCount}>
        {cartCount => (
          <CFView
            h="27px"
            w="27px"
            br="30px"
            color={
              dispatch.restaurant.getStyles().brandColor
                ? dispatch.restaurant.getStyles().brandColor
                : COLOR.brand
            }
            bg="white"
            bold
            center
          >
            {cartCount}
          </CFView>
        )}
      </CFSelect>
    </CFView>
  )
}
