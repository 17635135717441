import React from 'react'
import { CFIcon, CFView } from 'components'
import { dispatch } from 'store'

export default ({
  label,
  icon,
  fontSize = '24px',
  color = dispatch.restaurant.getStyles().theme === 'dark' ? 'white' : 'black',
  ...props
}) => {
  return (
    <CFView row alignCenter fontSize={fontSize} hover {...props}>
      <CFIcon icon={icon} color={color} />
      <CFView bold color={color} ml="5px">
        {label}
      </CFView>
    </CFView>
  )
}
