import React from 'react'
import { CFImage, CFView } from 'components'
import { rewardButton } from 'images'

export default props => (
  <CFView {...props}>
    <CFImage
      w="286px"
      mb="10px"
      relative
      right="3px"
      src={rewardButton}
      contain
    />
  </CFView>
)
