import React from 'react'
import moment from 'moment'
import { Card, CFView, ThemedText } from 'components'
import { COLOR } from 'styles'
import { dispatch } from 'store'

const STATUS_COLORS = {
  New: COLOR.green,
  Preparing: COLOR.orange,
  Done: COLOR.red,
  Cancelled:
    dispatch.restaurant.getStyles().theme === 'dark' ? 'white' : 'black',
}

export default ({ order, OrderDetailsModal, ...props }) => {
  const { createdAt, orderNumber, total } = order
  let status = order.status
  if (status === 'Preparing' && moment().subtract(3, 'd').isAfter(createdAt)) {
    status = 'Done'
  }
  return (
    <Card w="100%" mb="10px" p="15px 10px" column hover {...props}>
      <CFView row justifyBetween mb="10px">
        <ThemedText bold># {orderNumber}</ThemedText>
        <CFView bold color={STATUS_COLORS[status]}>
          {status}
        </CFView>
      </CFView>
      <CFView row justifyBetween>
        <ThemedText subtitle fontSize={16}>
          {`${moment(createdAt).format('LT, DD/MM/YY')}`}
        </ThemedText>
        <ThemedText>{`$${total.toFixed(2)}`}</ThemedText>
      </CFView>
      {OrderDetailsModal}
    </Card>
  )
}
