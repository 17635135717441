import React from 'react'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'
import { CFSelect } from 'components'
import { dispatch } from 'store'
import * as pages from 'pages'

export default () => {
  const LoadedRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={props => (
        <CFSelect selector={dispatch.restaurant.getLoading}>
          {loading => (loading ? <pages.Splash /> : <Component {...props} />)}
        </CFSelect>
      )}
    />
  )

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={props => (
        <CFSelect
          selector={[
            dispatch.restaurant.getLoading,
            dispatch.user.getIsLoggedIn,
          ]}
        >
          {([loading, isLoggedIn]) =>
            loading ? (
              <pages.Splash />
            ) : isLoggedIn ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{
                  pathname: `/auth${props.location.pathname}`,
                }}
              />
            )
          }
        </CFSelect>
      )}
    />
  )

  return (
    <Router>
      <Switch>
        <Route
          path="/restaurants/:restaurantId/locations/:locationId"
          exact
          component={pages.Restaurant}
        />
        <Route
          path="/restaurants/:restaurantId/locations/:locationId/tables/:tableId"
          component={pages.Restaurant}
        />
        <Route
          path="/restaurants/:restaurantId/locations/:locationId/kiosks/:tableId/"
          component={pages.OrderType}
        />
        <LoadedRoute path="/auth" exact component={pages.Auth} />
        <LoadedRoute path="/auth/:nextRoute" component={pages.Auth} />
        <LoadedRoute path="/cart" component={pages.Cart} />
        <LoadedRoute path="/category" component={pages.Category} />
        <LoadedRoute path="/locations" component={pages.Locations} />
        <LoadedRoute path="/order_type" component={pages.OrderType} />
        <LoadedRoute path="/menu" component={pages.Menu} />
        <LoadedRoute path="/product" component={pages.Product} />
        <LoadedRoute path="/order_complete" component={pages.OrderComplete} />
        <LoadedRoute path="/order_history" component={pages.OrderHistory} />
        <LoadedRoute path="/order_time" component={pages.OrderTime} />
        <LoadedRoute path="/requests" component={pages.Requests} />
        <LoadedRoute path="/rewards" exact component={pages.Rewards} />
        <LoadedRoute path="/rewards/:nextRoute" component={pages.Rewards} />
        <LoadedRoute path="/search" component={pages.Search} />
        <PrivateRoute path="/checkout" component={pages.Checkout} />
        <PrivateRoute path="/credit_card" exact component={pages.CreditCard} />
        <PrivateRoute
          path="/credit_card/:nextRoute"
          component={pages.CreditCard}
        />
        <PrivateRoute
          path="/delivery_details"
          component={pages.DeliveryDetails}
        />
        <PrivateRoute
          path="/delivery_details/:nextRoute"
          exact
          component={pages.DeliveryDetails}
        />
        <PrivateRoute path="/order_details" component={pages.OrderDetails} />
        <PrivateRoute path="/user" component={pages.User} />
        <PrivateRoute
          path="/user_details"
          exact
          component={pages.UserDetails}
        />
        <PrivateRoute
          path="/user_details/:nextRoute"
          component={pages.UserDetails}
        />
        <Route component={pages.Page404} />
      </Switch>
    </Router>
  )
}
