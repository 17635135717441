import React from 'react'
import { CFIcon, CFModal, CFView } from 'components'
import { sharedBg } from 'images'
import { dispatch } from 'store'
import { COLOR } from 'styles'

export default ({ title, isOpen, onRequestClose, children, ...props }) => {
  return (
    <CFModal isOpen={isOpen} onRequestClose={onRequestClose}>
      <CFView
        bg="white"
        br="5px"
        column
        overflow="hidden"
        image={`url(${
          dispatch.restaurant.getStyles().brandColor
            ? dispatch.restaurant.getImages().mainBgMobile
            : sharedBg
        }) center / cover no-repeat`}
        {...props}
      >
        <CFView h="50px" w="100%" row alignCenter relative bg="rgba(0,0,0,.8)">
          <CFView fill center>
            <CFView white bold h3 truncate maxWidth="320px" textCenter>
              {title}
            </CFView>
          </CFView>
          <CFView
            h="50px"
            w="50px"
            center
            absolute
            right={0}
            hover
            onClick={onRequestClose}
          >
            <CFIcon icon="times-circle" color={COLOR.red} fontSize={28} />
          </CFView>
        </CFView>
        <CFView maxHeight="80vh" overflowY="auto">
          {children}
        </CFView>
      </CFView>
    </CFModal>
  )
}
