import React from 'react'
import { CFImage, CFView } from 'components'
import { leftRewardArrow } from 'images'

export default ({ disabled, onClick, ...props }) => {
  return (
    <CFView
      absolute
      top="41%"
      left="1.5%"
      onClick={!disabled ? onClick : undefined}
      hover
      {...props}
    >
      <CFImage
        w="50px"
        contain
        src={leftRewardArrow}
        style={disabled ? { opacity: 0.5 } : {}}
        alt="Left Arrow"
      />
    </CFView>
  )
}
