import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import {
  BackButton,
  Card,
  CFSelect,
  CFView,
  DefaultScreen,
  MobileScreen,
  PageBackground,
  PageContainer,
  PageHeader,
  PrimaryModal,
  ThemedText,
  ViewCartButton,
} from 'components'
import { Product } from 'pages'
import CategoryHeader from './CategoryHeader'
import ProductItem from './ProductItem'
import { dispatch } from 'store'
import { isEmpty } from 'lodash'

export default ({ location, category = {} }) => {
  if (isEmpty(category)) {
    category = location.state
  }
  const { id, name, description } = category
  const history = useHistory()
  const isMobile = useMediaQuery({ maxWidth: 767 })

  const [showProductModal, setShowProductModal] = useState(false)
  const [product, setProduct] = useState({})

  const navigateProduct = product => {
    if (!dispatch.restaurant.getIsProductActive(product.id)) {
      alert('Sorry, this product is sold out.')
      return
    }
    if (isMobile) {
      history.push('/product', product)
    } else {
      setProduct(product)
      setShowProductModal(true)
    }
  }

  return (
    <>
      <MobileScreen>
        <PageBackground>
          <PageHeader
            title={name}
            LeftButton={<BackButton onClick={() => history.push('/menu')} />}
          />
          <PageContainer offset="header">
            <CategoryHeader category={category} />
            {description && (
              <Card m="10px 10px 0 10px" p="10px">
                <ThemedText>{description}</ThemedText>
              </Card>
            )}
            <CFView column p="10px" mb="80px">
              <CFSelect
                selector={() => dispatch.restaurant.getProductsInOrder(id)}
              >
                {productsInOrder =>
                  id === 'recent' && productsInOrder.length === 0 ? (
                    <CFView row center mt="80px">
                      <CFView
                        h2
                        bold
                        color={dispatch.restaurant.getStyles().fontColor}
                      >
                        No recent orders.
                      </CFView>
                    </CFView>
                  ) : (
                    productsInOrder.map((product, index) => (
                      <ProductItem
                        key={index}
                        product={product}
                        onClick={() => navigateProduct(product)}
                      />
                    ))
                  )
                }
              </CFSelect>
            </CFView>
            <CFSelect selectorNot={dispatch.user.getIsCartEmpty}>
              <CFView w="100%" center fixed bottom="20px" zIndex={99}>
                <ViewCartButton onClick={() => history.push('/cart')} />
              </CFView>
            </CFSelect>
          </PageContainer>
        </PageBackground>
      </MobileScreen>
      <DefaultScreen>
        <CFView w="100%" column>
          {description && (
            <Card m="5px 10px" p="10px">
              <ThemedText>{description}</ThemedText>
            </Card>
          )}
          <CFView column p="5px 10px">
            <CFSelect
              selector={() => dispatch.restaurant.getProductsInOrder(id)}
            >
              {productsInOrder =>
                id === 'recent' && productsInOrder.length === 0 ? (
                  <CFView row center mt="80px">
                    <ThemedText h2 bold>
                      No recent orders.
                    </ThemedText>
                  </CFView>
                ) : (
                  productsInOrder.map((product, index) => (
                    <ProductItem
                      key={index}
                      product={product}
                      onClick={() => navigateProduct(product)}
                    />
                  ))
                )
              }
            </CFSelect>
          </CFView>
        </CFView>
        <PrimaryModal
          title={product.name}
          isOpen={showProductModal}
          onRequestClose={() => setShowProductModal(false)}
        >
          <Product
            product={product}
            onRequestClose={() => setShowProductModal(false)}
          />
        </PrimaryModal>
      </DefaultScreen>
    </>
  )
}
