import React from 'react'
import { CFSelect, CFView, ThemedText } from 'components'
import SectionHeader from './SectionHeader'
import { dispatch } from 'store'

export default ({ EditButton }) => (
  <CFView column>
    <SectionHeader title="Delivery Address" icon="map-marked-alt" />
    <CFView ph="10px" pv="10px">
      <CFView row alignStart>
        <CFView fill column>
          <ThemedText h3 bold mb="2px">
            Address
          </ThemedText>
          {dispatch.user.getAddress() ? (
            <ThemedText h5 subtitle mb="10px">
              <CFSelect selector={dispatch.user.getAddress} />
            </ThemedText>
          ) : (
            <ThemedText h5 subtitle mb="10px">
              Please add address.
            </ThemedText>
          )}
          <CFSelect selector={dispatch.user.getAptNumber}>
            <ThemedText h3 bold mb="2px">
              Suite Number
            </ThemedText>
            <ThemedText h5 subtitle mb="10px">
              <CFSelect selector={dispatch.user.getAptNumber} />
            </ThemedText>
          </CFSelect>
          <CFSelect selector={dispatch.user.getDeliveryInstructions}>
            <ThemedText h3 bold mb="2px">
              Delivery Instructions
            </ThemedText>
            <ThemedText h5 subtitle mb="10px">
              <CFSelect selector={dispatch.user.getDeliveryInstructions} />
            </ThemedText>
          </CFSelect>
        </CFView>
        {EditButton}
      </CFView>
    </CFView>
  </CFView>
)
