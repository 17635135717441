import React, { useState } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import {
  BackButton,
  CFIcon,
  CFSelect,
  CFView,
  DefaultScreen,
  LabelInput,
  MobileScreen,
  PageHeader,
  PageContainer,
  PrimaryButton,
  SharedBackground,
} from 'components'
import { dispatch } from 'store'
import { COLOR } from 'styles'

export default ({ onRequestClose }) => {
  const history = useHistory()
  const params = useParams()
  const location = useLocation()
  const { nextRoute } = params
  const isMobile = useMediaQuery({ maxWidth: 767 })

  const [name, setName] = useState(dispatch.user.getName())
  const [phoneNumber, setPhoneNumber] = useState(dispatch.user.getPhoneNumber())

  const saveUserDetails = async () => {
    if (name.trim() === '') {
      alert('Sorry, name cannot be empty.')
      return
    }
    if (!dispatch.restaurant.getIsDineIn()) {
      if (isNaN(Number(phoneNumber))) {
        alert('Sorry, phone number must be a number.')
        return
      }
      if (phoneNumber.length < 10) {
        alert('Sorry, phone number must be 10 digits.')
        return
      }
    }
    await dispatch.user.changeUserInfo({ name, phoneNumber })
    dispatch.notification.setMessage({
      message: 'User details saved!',
      level: 'success',
    })
    if (isMobile) {
      history.push(`/${nextRoute}`)
    } else {
      onRequestClose()
    }
  }

  const deactivateUser = async () => {
    if (
      window.confirm(
        'You will lose all data associated with your account including order history, reward points, etc. Are you sure you want to delete?'
      )
    ) {
      try {
        await dispatch.user.deactivateUser()
        dispatch.user.signOut()
        dispatch.notification.setMessage({
          message: `User account deleted!`,
          level: 'success',
        })
      } catch (e) {
        alert('Error deleting account.')
      }
    }
  }

  return (
    <>
      <MobileScreen>
        <SharedBackground>
          <PageHeader
            title="User Details"
            LeftButton={
              <BackButton onClick={() => history.push(`/${nextRoute}`)} />
            }
          />
          <PageContainer offset="header" column>
            <CFView fill mt="40px" ph="20px" column>
              <LabelInput
                mb="20px"
                label="Name"
                color="black"
                maxLength={28}
                value={name}
                onChange={e => setName(e.target.value)}
              />
              <CFSelect selectorNot={dispatch.restaurant.getIsDineIn}>
                <LabelInput
                  mb="40px"
                  label="Phone Number"
                  color="black"
                  maxLength={10}
                  value={phoneNumber}
                  onChange={e => setPhoneNumber(e.target.value)}
                />
              </CFSelect>
              {location.pathname === '/user_details/user' && (
                <CFView
                  br="5px"
                  p="10px"
                  mb="40px"
                  bg={COLOR.darkOpacity}
                  bowShadow={'1px 1px 3px rgba(255,255,255,.8)'}
                  row
                  justifyBetween
                  alignCenter
                >
                  <CFView column>
                    <CFView white bold mb="8px">
                      Delete Account
                    </CFView>
                    <CFView white>{`${dispatch.user.getEmail()}`}</CFView>
                  </CFView>
                  <CFView hover onClick={deactivateUser}>
                    <CFIcon
                      icon="times-circle"
                      color={COLOR.red}
                      fontSize={28}
                    />
                  </CFView>
                </CFView>
              )}
            </CFView>
            <PrimaryButton
              mb="20px"
              label="SAVE"
              onClick={saveUserDetails}
              selfCenter
            />
          </PageContainer>
        </SharedBackground>
      </MobileScreen>
      <DefaultScreen>
        <CFView w="420px" pt="40px" pb="20px" ph="20px" column>
          <LabelInput
            mb="20px"
            label="Name"
            color="black"
            maxLength={28}
            value={name}
            onChange={e => setName(e.target.value)}
          />
          <CFSelect selectorNot={dispatch.restaurant.getIsDineIn}>
            <LabelInput
              mb="40px"
              label="Phone Number"
              color="black"
              maxLength={10}
              value={phoneNumber}
              onChange={e => setPhoneNumber(e.target.value)}
            />
          </CFSelect>
          {location.pathname === '/user' && (
            <CFView
              br="5px"
              p="10px"
              mb="40px"
              bg={COLOR.darkOpacity}
              bowShadow={'1px 1px 3px rgba(255,255,255,.8)'}
              row
              justifyBetween
              alignCenter
            >
              <CFView column>
                <CFView white bold mb="8px">
                  Delete Account
                </CFView>
                <CFView white>{`${dispatch.user.getEmail()}`}</CFView>
              </CFView>
              <CFView hover onClick={deactivateUser}>
                <CFIcon icon="times-circle" color={COLOR.red} fontSize={28} />
              </CFView>
            </CFView>
          )}
          <PrimaryButton label="SAVE" onClick={saveUserDetails} selfCenter />
        </CFView>
      </DefaultScreen>
    </>
  )
}
