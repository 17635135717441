import React from 'react'
import { CFIcon, CFView } from 'components'

export default ({ title, subtitle, icon, ...props }) => {
  return (
    <CFView
      h="70px"
      w="100%"
      ph="20px"
      row
      justifyBetween
      alignCenter
      highlight
      {...props}
    >
      <CFView w="45px">
        <CFIcon icon={icon} color="white" fontSize={24} />
      </CFView>
      <CFView column fill>
        <CFView h2 white bold>
          {title}
        </CFView>
        <CFView roboto h5 white mt="5px">
          {subtitle}
        </CFView>
      </CFView>
      <CFIcon color="white" icon="angle-right" fontSize={34} />
    </CFView>
  )
}
