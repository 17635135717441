import React from 'react'
import { CFSelect, CFView, ThemedText } from 'components'
import SectionHeader from './SectionHeader'
import { dispatch } from 'store'

export default ({ EditButton }) => (
  <CFView column>
    <SectionHeader title="Contact Information" icon="id-card" />
    <CFView ph="10px" pb="10px">
      <CFView row alignStart mv="10px">
        <CFView fill column>
          <ThemedText bold h3>
            Name
          </ThemedText>
          {dispatch.user.getName() ? (
            <ThemedText h5 subtitle mt="2px">
              <CFSelect selector={dispatch.user.getName} />
            </ThemedText>
          ) : (
            <ThemedText h5 subtitle mt="2px">
              Please add name.
            </ThemedText>
          )}
          <CFSelect selectorNot={dispatch.restaurant.getIsDineIn}>
            <ThemedText h3 bold mt="10px">
              Phone Number
            </ThemedText>
            {dispatch.user.getPhoneNumber() ? (
              <ThemedText h5 subtitle mt="2px">
                <CFSelect selector={dispatch.user.getFormattedPhoneNumber} />
              </ThemedText>
            ) : (
              <ThemedText h5 subtitle mt="2px">
                Please add phone number.
              </ThemedText>
            )}
          </CFSelect>
        </CFView>
        {EditButton}
      </CFView>
    </CFView>
  </CFView>
)
