import React from 'react'
import { CFView, ThemedText } from 'components'
import ChoiceItem from './ChoiceItem'
import QuantityIcon from './QuantityIcon'

export default ({ product, RemoveButton }) => {
  const { count, name, totalPrice } = product
  return (
    <CFView column mv="10px">
      <CFView row justifyBetween>
        <CFView fill row alignStart>
          <QuantityIcon count={count} />
          <ThemedText h5 bold textLeft mt="3px" ml="10px" mr="10px">
            {name}
          </ThemedText>
        </CFView>
        <ThemedText h5 bold mt="2px" ml="10px">
          {`$${(totalPrice * count).toFixed(2)}`}
        </ThemedText>
      </CFView>
      {product.choices.map((choice, index) => {
        return <ChoiceItem key={index} choice={choice} />
      })}
    </CFView>
  )
}
