import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  CFSelect,
  CFView,
  PageHeader,
  PageContainer,
  SharedBackground,
} from 'components'
import TypeButton from './TypeButton'
import { dispatch } from 'store'

export default () => {
  const history = useHistory()
  const { restaurantId = '', locationId = '', tableId = '' } = useParams()

  useEffect(() => {
    if (restaurantId) {
      dispatch.restaurant.setRestaurantId(restaurantId)
      localStorage.setItem('restaurantId', restaurantId)
    }
    if (locationId) {
      dispatch.restaurant.setLocationId(locationId)
      localStorage.setItem('locationId', locationId)
    }
    if (tableId) {
      dispatch.restaurant.setTableId(tableId)
      dispatch.restaurant.setVirtualKioskEnabled(true)
      localStorage.setItem('tableId', tableId)
    }
  })

  const changeOrderType = orderType => {
    if (orderType === 'takeout') {
      dispatch.restaurant.setTableId('')
      localStorage.removeItem('tableId')
    }
    history.push('/menu')
  }

  return (
    <SharedBackground>
      <PageHeader title="Order Type" />
      <PageContainer offset="header" column>
        <CFSelect selector={dispatch.restaurant.getLocations}>
          {locations => {
            return (
              <CFView
                h="100%"
                w="100%"
                column
                alignCenter
                pv="30px"
                overflowY="auto"
                style={{ '-webkit-overflow-scrolling': 'touch' }}
              >
                <CFView h3 bold mv="20px" black>
                  Please choose an order type:
                </CFView>
                <CFView w="90%">
                  <TypeButton
                    name="Dine-in"
                    icon="utensils"
                    onClick={() => changeOrderType('dine-in')}
                  />
                  <TypeButton
                    name="Takeout"
                    icon="shopping-bag"
                    onClick={() => changeOrderType('takeout')}
                  />
                </CFView>
              </CFView>
            )
          }}
        </CFSelect>
      </PageContainer>
    </SharedBackground>
  )
}
