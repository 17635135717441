import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

function isWeiXinAndIos() {
  let ua = '' + window.navigator.userAgent.toLowerCase()
  let isWeixin = /MicroMessenger/i.test(ua)
  let isIos = /\(i[^;]+;( U;)? CPU.+Mac OS X/i.test(ua)
  return isWeixin && isIos
}

function wechatKeyboardFix() {
  let myFunction
  let isWXAndIos = isWeiXinAndIos()
  if (isWXAndIos) {
    document.body.addEventListener('focusin', () => {
      clearTimeout(myFunction)
    })
    document.body.addEventListener('focusout', () => {
      clearTimeout(myFunction)
      myFunction = setTimeout(function() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      }, 200)
    })
  }
}

wechatKeyboardFix()

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
