import React from 'react'
import { CFIcon, CFView, ThemedText } from 'components'
import QuantityIcon from './QuantityIcon'

export default ({ reward }) => {
  const { count, name, productId, totalDiscountSubtotal = 0 } = reward
  return (
    <CFView w="100%" mv="10px" row justifyBetween>
      <CFView fill row alignStart>
        <QuantityIcon count={count} />
        <CFView row justifyStart alignCenter mt="2px" ml="10px" mr="10px">
          <CFIcon icon="gift" color="red" fontSize="22px" />
          <ThemedText h5 bold textLeft ml="5px">
            {name}
          </ThemedText>
        </CFView>
      </CFView>
      <ThemedText h5 bold mt="2px" ml="10px">
        {productId ? 'FREE' : `-$${totalDiscountSubtotal.toFixed(2)}`}
      </ThemedText>
    </CFView>
  )
}
