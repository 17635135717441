import * as api from '../../api'
import { get, isEmpty } from 'lodash'
import { isMobile } from 'cf-core/src/config/firebase'

export default ({ dispatch, getState, stripeKey, apiKey, serverUrl }) => ({
  getCustomerId() {
    return getState().user.payment.customerId
  },
  getHasPayment() {
    return !!dispatch.user.getCustomerId()
  },
  getLast4() {
    return get(getState(), 'user.payment.card.last4', '')
  },
  getExpiryDate() {
    const month = get(getState(), 'user.payment.card.exp_month', 0)
    const year = get(getState(), 'user.payment.card.exp_year', 0)
    if (month && year) {
      return ('0' + month).slice(-2) + '/' + year.toString().slice(-2)
    }
    return ''
  },
  async addCreditCard(card) {
    const userToken = await dispatch.user.getUserToken()
    if (!userToken) throw new Error('User Token not valid')

    // Create a new card
    const response = await api.stripe.createCardWithStripe(card, stripeKey)
    const { last4, id, brand, exp_month, exp_year } = response.data.card

    // Create a new customer on server side
    const res = await api.server.createCustomerWithStripe(serverUrl, userToken, {
      cardToken: response.data.id,
    })

    const payment = {
      card: { last4, id, brand, exp_month, exp_year },
      customerId: res.data.id,
    }

    const userId = dispatch.user.getUserId()

    api.user.updateUser(userId, { payment })
  },
  async updateCreditCard(card) {
    const userToken = await dispatch.user.getUserToken()
    if (!userToken) throw new Error('User Token not valid')

    const response = await api.stripe.createCardWithStripe(card, stripeKey)
    const { last4, id, brand, exp_month, exp_year } = response.data.card

    // Create a new customer on server side
    const res = await api.server.updateCustomerWithStripe(serverUrl, userToken, {
      cardToken: response.data.id,
    })

    const payment = {
      card: { last4, id, brand, exp_month, exp_year },
      customerId: res.data.id,
    }

    const userId = dispatch.user.getUserId()

    api.user.updateUser(userId, { payment })
  },
  async deleteCreditCard() {
    return api.user.updateUser(dispatch.user.getUserId(), { payment: {} })
  },
  async createOrder() {
    if (!dispatch.user.getIsLoggedIn()) {
      throw new Error('Not Authenticated')
    }
    if (!dispatch.restaurant.getIsStoreOpen()) {
      alert('Sorry, ordering is currently closed.')
      return
    }
    dispatch.user.setIsPlacingOrder(true)
    try {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      const locationId = dispatch.restaurant.getSelectedLocationId()
      const products = dispatch.user.getCart()
      const choicesProducts = dispatch.user.getChoicesCart()
      const tableId = dispatch.restaurant.getTableId() || ''
      const redeemedPromoCode = dispatch.user.getPromoCode().code || ''
      let orderType = dispatch.user.getOrderType()
      if (dispatch.restaurant.getIsDineIn()) {
        orderType = 'Dine-in'
      }
      if (!orderType) {
        orderType = 'Pickup'
      }
      let paymentMethod = dispatch.user.getPaymentMethod()
      let tip = dispatch.user.getTip()
      if (paymentMethod === 'offline') {
        tip = 0
      }
      const orderTime = dispatch.user.getOrderTime()
      const notes = redeemedPromoCode
        ? dispatch.user.getNotes()
          ? `${redeemedPromoCode} - ${dispatch.user.getNotes()}`
          : redeemedPromoCode
        : dispatch.user.getNotes()
      const validPromosWithDetails = dispatch.user.getValidPromosWithDetails()
      const promos = Object.keys(validPromosWithDetails).reduce((prev, promoId) => {
        prev[promoId] = {
          id: promoId,
          count: validPromosWithDetails[promoId].count,
        }
        return prev
      }, {})
      const doordashDetails = dispatch.user.getDoorDashDetails()
      if (orderType === 'Delivery' && dispatch.restaurant.getDoorDashEnabled() && isEmpty(doordashDetails)) {
        alert('Sorry, there was an error with delivery. Please try again.')
        return
      }
      const userToken = await dispatch.user.getUserToken()
      const userId = dispatch.user.getUserId()
      const {
        data: {
          order: { orderNumber, tableNumber, pointsEarned, orderId, total, completedAt },
        },
      } = await api.server.createOrderAndCharge(serverUrl, userToken, restaurantId, locationId, {
        products,
        choicesProducts,
        notes,
        tip,
        promos,
        redeemedPromoCode,
        orderType,
        orderTime,
        tableId,
        paymentMethod,
        doordashDetails,
        sourceClient: isMobile ? 'mobile' : 'web',
      })
      if (dispatch.restaurant.getDineInEnabled()) {
        let body = ''
        if (dispatch.restaurant.getIsDineIn()) {
          body = `Open to confirm order for table #${tableNumber}!`
        } else {
          body = `Open to confirm order #${orderNumber}!`
        }
        const message = {
          locationId,
          tableId,
          title: `New ${orderType} Order`,
          body,
        }
        api.server.sendStaffNotification(serverUrl, apiKey, message)
      }
      api.user.updateUserRestaurant(userId, restaurantId, {
        reviewOrderId: orderId,
      })
      dispatch.user.clearCart()
      dispatch.user.setDoorDashDetails({})
      dispatch.user.setPromoCode({})
      const orderDetails = {
        orderId,
        orderNumber,
        orderType,
        tableNumber,
        completedAt,
        paymentMethod,
        products,
        pointsEarned,
        promos,
        total,
      }
      return orderDetails
    } catch (error) {
      throw error
    } finally {
      dispatch.user.setIsPlacingOrder(false)
    }
  },
  async createGuestOrder() {
    if (!dispatch.restaurant.getIsStoreOpen()) {
      alert('Sorry, ordering is currently closed.')
      return
    }
    if (!dispatch.restaurant.getIsDineIn()) {
      alert('Sorry, guest ordering is only available for dine-in.')
      return
    }
    dispatch.user.setIsPlacingOrder(true)
    try {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      const locationId = dispatch.restaurant.getSelectedLocationId()
      const products = dispatch.user.getCart()
      const choicesProducts = dispatch.user.getChoicesCart()
      const tableId = dispatch.restaurant.getTableId() || ''
      const paymentMethod = 'offline'
      const orderType = 'Dine-in'
      const tip = 0
      const orderTime = dispatch.user.getOrderTime()
      const notes = dispatch.user.getNotes()
      const {
        data: {
          order: { orderNumber, tableNumber, orderId, total, completedAt },
        },
      } = await api.server.createGuestOrder(serverUrl, apiKey, restaurantId, locationId, {
        products,
        choicesProducts,
        notes,
        tip,
        orderType,
        orderTime,
        tableId,
        paymentMethod,
        sourceClient: 'web',
      })
      let body = ''
      if (dispatch.restaurant.getIsDineIn()) {
        body = `Open to confirm order for table #${tableNumber}!`
      } else {
        body = `Open to confirm order #${orderNumber}!`
      }
      const message = {
        locationId,
        tableId,
        title: `New ${orderType} Order`,
        body,
      }
      api.server.sendStaffNotification(serverUrl, apiKey, message)
      dispatch.user.clearCart()
      const orderDetails = {
        orderId,
        orderNumber,
        orderType,
        tableNumber,
        completedAt,
        paymentMethod,
        products,
        total,
      }
      return orderDetails
    } catch (error) {
      throw error
    } finally {
      dispatch.user.setIsPlacingOrder(false)
    }
  },
})
