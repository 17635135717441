import React from 'react'
import { CFImage } from 'components'
import { dispatch } from 'store'

export default () => {
  return (
    <CFImage
      src={dispatch.restaurant.getImages().menuHeader}
      cover
      w="100%"
      h="180px"
      alt="Category"
      boxShadow="0 1px 3px rgba(0,0,0,.8)"
    />
  )
}
