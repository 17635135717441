import React from 'react'
import { Card, CFImage, CFView, ThemedText } from 'components'
import { COLOR } from 'styles'
import { dispatch } from 'store'

export default ({ product, ...props }) => {
  const { id, name, price, description, imageUrl = null } = product
  return (
    <Card row fill mb="10px" hover {...props}>
      <CFView column fill p="10px">
        <ThemedText h4 bold>
          {name}
        </ThemedText>
        <CFView fill>
          {description && (
            <ThemedText roboto h5 subtitle mt="5px">
              {description}
            </ThemedText>
          )}
        </CFView>
        {dispatch.restaurant.getIsProductActive(id) ? (
          <ThemedText h4 bold mt="5px">
            {`$${price.toFixed(2)}`}
          </ThemedText>
        ) : (
          <CFView h4 bold mt="5px" color={COLOR.red}>
            SOLD OUT
          </CFView>
        )}
      </CFView>
      {imageUrl && (
        <CFImage h="140px" w="140px" cover src={imageUrl} alt="Product" />
      )}
    </Card>
  )
}
