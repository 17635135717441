import React, { useState } from 'react'
import { CFSelect, CFView, LabelInput, PrimaryButton } from 'components'
import SectionHeader from './SectionHeader'
import EditSectionButton from './EditSectionButton'
import { dispatch } from 'store'
import { find } from 'lodash'
import moment from 'moment'

export default () => {
  const [promoCodeField, setPromoCodeField] = useState('')

  const applyPromoCode = async () => {
    const promoCodes = dispatch.restaurant.getPromoCodes()
    const validPromoCode = find(promoCodes, {
      code: promoCodeField.toUpperCase(),
    })
    if (!validPromoCode) {
      alert('Sorry, that promo code is invalid.')
      return
    }
    if (!validPromoCode.active) {
      alert('Sorry, that promo code is inactive.')
      return
    }
    if (!validPromoCode.validDays[moment().day()]) {
      alert('Sorry, that promo code cannot be used today.')
      return
    }
    const frequency = validPromoCode.frequency
    if (frequency !== 'none') {
      const fromDate = moment().startOf(frequency).subtract(1, frequency)
      const toDate = moment().endOf('day')
      const orders = await dispatch.user.getOrderHistoryRange({
        fromDate,
        toDate,
      })
      let isValid = true
      orders.forEach(order => {
        if (order.promoCode?.code === promoCodeField) {
          isValid = false
        }
      })
      if (!isValid) {
        alert(`Sorry, that promo can only be used once a ${frequency}.`)
        return
      }
    }
    dispatch.user.setPromoCode(validPromoCode)
    dispatch.notification.setMessage({
      message: 'Promo code redeemed!',
      level: 'success',
    })
  }

  const editPromoCode = () => {
    dispatch.user.setPromoCode({})
  }

  return (
    <CFView column>
      <SectionHeader title="Promo Code" icon="tag" />
      <CFView mh="10px" mt="10px" mb="20px">
        <CFSelect selector={dispatch.user.getPromoCode}>
          {promoCode =>
            Object.keys(promoCode).length > 0 ? (
              <CFView row mt="10px" alignCenter justifyBetween>
                <CFView color="white">{`${promoCode.code} - ${
                  promoCode.discount * 100
                }% discount applied!`}</CFView>
                <EditSectionButton onClick={editPromoCode} />
              </CFView>
            ) : (
              <CFView row mt="10px" alignCenter>
                <CFView fill>
                  <LabelInput
                    mr="30px"
                    placeholder="Code"
                    value={promoCodeField}
                    onChange={e => setPromoCodeField(e.target.value)}
                  />
                </CFView>
                <PrimaryButton
                  w="120px"
                  label="Apply"
                  onClick={applyPromoCode}
                />
              </CFView>
            )
          }
        </CFSelect>
      </CFView>
    </CFView>
  )
}
