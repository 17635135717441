import React from 'react'
import { CFView, ThemedText } from 'components'

export default ({ choice }) => {
  const { name, price } = choice
  return (
    <CFView ml="40px">
      <ThemedText h6 subtitle>
        • {name} {price > 0 && `($${price.toFixed(2)})`}
      </ThemedText>
    </CFView>
  )
}
