import React from 'react'
import { Provider } from 'react-redux'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import Routes from 'routes'
import {
  Notification,
  SubscribeRestaurant,
  SubscribeRestaurantLocation,
} from 'components'
import { store } from 'store'

library.add(fas)

export default () => {
  return (
    <Provider store={store}>
      <>
        <Routes />
        <Notification />
        <SubscribeRestaurant />
        <SubscribeRestaurantLocation />
      </>
    </Provider>
  )
}
