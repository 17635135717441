import React from 'react'
import moment from 'moment'
import { CFFBSubscribe, CFImage, CFView } from 'components'
import { orderStatusMeter, orderStatusProgress } from 'images'
import { dispatch } from 'store'

export default ({ orderId }) => {
  const orderDoc = dispatch.user.getOrderDoc({ orderId })
  return (
    <CFFBSubscribe doc={orderDoc}>
      {({ loading, data: orderDetails }) => {
        let status = ''
        let statusMeterWidth = 0
        if (!loading) {
          status = orderDetails.status
          if (
            status === 'Preparing' &&
            moment().subtract(3, 'd').isAfter(orderDetails.createdAt)
          ) {
            status = 'Done'
          }
          switch (status) {
            case 'New':
              statusMeterWidth = 0.32
              break
            case 'Pending':
              statusMeterWidth = 0.32
              break
            case 'Preparing':
              statusMeterWidth = 0.66
              break
            case 'Done':
              statusMeterWidth = 1
              break
            default:
              break
          }
        }
        return (
          <CFView center relative>
            <CFView w="100%" maxWidth="400px" center absolute top="15%">
              <CFView
                overflow="hidden"
                center
                style={{
                  clipPath: `polygon(0% 0%, 0% 100%, ${
                    statusMeterWidth * 100
                  }% 100%, ${statusMeterWidth * 100}% 0%)`,
                  transition: '1s cubic-bezier(.17,.67,.83,.67)',
                }}
              >
                <CFImage
                  w="95%"
                  contain
                  src={orderStatusProgress}
                  alt="Progress"
                />
              </CFView>
            </CFView>
            <CFView w="100%" maxWidth="400px" center absolute top="15%">
              <CFImage w="95%" src={orderStatusMeter} alt="Meter" zIndex={0} />
            </CFView>
          </CFView>
        )
      }}
    </CFFBSubscribe>
  )
}
