import React, { useState } from 'react'
import { firebaseAuth } from 'cf-core/src/config/firebase'
import { CFView, LabelInput, PrimaryButton, PrimaryModal } from 'components'
import { dispatch } from 'store'
import { COLOR } from 'styles'

export default props => {
  const [email, setEmail] = useState('')
  const [showPasswordModal, setShowPasswordModal] = useState(false)
  const [resetting, setResetting] = useState(false)

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      resetPassword()
    }
  }

  const resetPassword = async () => {
    if (!email) {
      alert('Email cannot be empty!')
      return
    }
    setResetting(true)
    try {
      await firebaseAuth.sendPasswordResetEmail(email)
      dispatch.notification.setMessage({
        message: `Password reset sent to ${email}!`,
        level: 'success',
      })
      setShowPasswordModal(false)
    } catch (e) {
      alert('Sorry, we could not reset your password.')
    } finally {
      setResetting(false)
    }
  }

  return (
    <CFView {...props}>
      <CFView
        h5
        h="40px"
        w="140px"
        color={
          dispatch.restaurant.getStyles().brandColor
            ? dispatch.restaurant.getStyles().brandColor
            : COLOR.brand
        }
        onClick={() => setShowPasswordModal(true)}
        hover
      >
        Forgot Password?
      </CFView>
      <PrimaryModal
        title="Reset Password"
        isOpen={showPasswordModal}
        onRequestClose={() => setShowPasswordModal(false)}
      >
        <CFView w="100%" maxWidth="420px" column ph="20px" pv="40px">
          <LabelInput
            mb="40px"
            label="Email"
            color="black"
            value={email}
            onChange={e => setEmail(e.target.value)}
            onKeyPress={handleKeyPress}
          />
          <PrimaryButton
            label="RESET"
            disabled={resetting}
            onClick={resetPassword}
            selfCenter
          />
        </CFView>
      </PrimaryModal>
    </CFView>
  )
}
