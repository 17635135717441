import { db, firebaseAuth, DELETE_FIELD_VALUE, isMobile } from 'cf-core/src/config/firebase'
import { TYPES } from 'cf-core/src/constants'
import moment from 'moment'
import * as utils from 'cf-utils'
import * as api from '../../api'
import { get, omit, isEmpty, orderBy, keys, isEqual } from 'lodash'
import paymentActions from './paymentActions'

const DEFAULT_STATE = {
  // User Restaurant data
  cart: {},
  choicesCart: [],
  orderType: '',
  points: 0,
  promos: {},
  promoCode: {},
  paymentMethod: '',
  recentOrders: {},
  reviewOrderId: '',

  // User data
  id: '',
  payment: {},
  phoneNumber: '',
  email: '',
  name: '',
  address: '',
  latLng: {},
  aptNumber: '',
  deliveryInstructions: '',
  doordashAddress: {},
  loading: false,
  loadingUserRestaurant: false,
  isLoggingIn: false,
  isLoggingOut: false,
  isPlacingOrder: false,
  notificationToken: '',

  // User Last Order
  lastUserOrder: {},
  loadingLastUserOrder: null,

  // local only
  orderTime: 'ASAP',
  notes: '',
  tip: 0.15,
  doordashDetails: {},
}

export default (stripeKey, serverUrl, apiKey, _choicesCart = []) => ({
  state: { ...DEFAULT_STATE, choicesCart: _choicesCart },
  reducers: {
    _setUser: (state, user) => ({
      ...state,
      ...user,
    }),
    _unsetUser: () => DEFAULT_STATE,
    _setLastUserOrder: (state, lastUserOrder) => ({
      ...state,
      lastUserOrder,
    }),
    setIsPlacingOrder: (state, isPlacingOrder) => ({
      ...state,
      isPlacingOrder,
    }),
    setIsLoggingIn: (state, isLoggingIn) => ({
      ...state,
      isLoggingIn,
    }),
    setIsLoggingOut: (state, isLoggingOut) => ({
      ...state,
      isLoggingOut,
    }),
    setLoading: (state, loading) => ({
      ...state,
      loading,
    }),
    setLoadingUserRestaurant: (state, loadingUserRestaurant) => ({
      ...state,
      loadingUserRestaurant,
    }),
    setLoadingLastUserOrder: (state, loadingLastUserOrder) => ({
      ...state,
      loadingLastUserOrder,
    }),
    _setAddress: (state, { address, aptNumber, deliveryInstructions, latLng }) => ({
      ...state,
      address,
      aptNumber,
      deliveryInstructions,
      latLng,
    }),
    _setCart: (state, cart) => ({
      ...state,
      cart,
    }),
    _setChoicesCart: (state, choicesCart) => ({
      ...state,
      choicesCart,
    }),
    _addCartItem: (state, { id, qty }) => ({
      ...state,
      cart: {
        ...state.cart,
        [id]: {
          id,
          count: get(state.cart[id], 'count', 0) + qty,
        },
      },
    }),
    _subtractCartItem: (state, { id }) => ({
      ...state,
      cart:
        state.cart[id].count === 1
          ? omit(state.cart, id)
          : {
              ...state.cart,
              [id]: {
                ...state.cart[id],
                count: state.cart[id].count - 1,
              },
            },
    }),
    _clearCart: (state) => ({
      ...state,
      cart: DEFAULT_STATE.cart,
      choicesCart: DEFAULT_STATE.choicesCart,
    }),
    _addChoicesCartItem: (state, choicesCart) => ({
      ...state,
      choicesCart,
    }),
    _subtractChoicesCartItem: (state, choicesCart) => ({
      ...state,
      choicesCart,
    }),
    _addRecentOrderProducts(state, products) {
      const productIdsObj = Object.keys(products).reduce((prev, next) => {
        prev[next] = true
        return prev
      }, {})
      return {
        ...state,
        recentOrders: [...productIdsObj, ...state.recentOrders],
      }
    },
    setOrderTime(state, orderTime) {
      return {
        ...state,
        orderTime,
      }
    },
    setNotes(state, notes) {
      return {
        ...state,
        notes,
      }
    },
    _setOrderType(state, orderType) {
      return {
        ...state,
        orderType,
      }
    },
    setTip(state, tip) {
      return {
        ...state,
        tip,
      }
    },
    setDoorDashDetails(state, doordashDetails) {
      return {
        ...state,
        doordashDetails,
      }
    },
    setPromoCode(state, promoCode) {
      return {
        ...state,
        promoCode,
      }
    },
    _setPaymentMethod(state, paymentMethod) {
      return {
        ...state,
        paymentMethod,
      }
    },
    _clearNotes(state) {
      return {
        ...state,
        notes: DEFAULT_STATE.notes,
      }
    },
    clearRecentOrders(state) {
      return {
        ...state,
        recentOrders: DEFAULT_STATE.recentOrders,
      }
    },
    _clearPromos(state) {
      return {
        ...state,
        promos: DEFAULT_STATE.promos,
      }
    },
  },
  actions: ({ dispatch, getState }) => ({
    ...paymentActions({ dispatch, getState, stripeKey, apiKey, serverUrl }),
    getUserToken() {
      return api.auth.getUserToken()
    },
    getUserId() {
      return get(api.auth.getCurrentUser(), 'uid')
    },
    getCart() {
      const cart = getState().user.cart
      const activeProducts = dispatch.restaurant.getActiveProducts()
      return Object.keys(cart).reduce((prev, id) => {
        if (activeProducts[id]) {
          prev[id] = cart[id]
        }
        return prev
      }, {})
    },
    getChoicesCart() {
      const cart = getState().user.choicesCart
      const activeProducts = dispatch.restaurant.getActiveProducts()
      return cart.reduce((prev, { id }, index) => {
        if (activeProducts[id]) {
          prev.push(cart[index])
        }
        return prev
      }, [])
    },
    getIsCartEmpty() {
      return dispatch.user.getCartCount() === 0
    },
    async getIsEmailExists(email) {
      if (!email) {
        throw new Error('Email cannot be empty')
      }
      return await api.auth.isEmailExists(email)
    },
    getIsLoggingIn() {
      return getState().user.isLoggingIn
    },
    getIsLoggingOut() {
      return getState().user.isLoggingOut
    },
    getIsLoading() {
      return getState().user.loading || getState().user.loadingUserRestaurant || !!getState().user.loadingLastUserOrder
    },
    getIsPlacingOrder() {
      return getState().user.isPlacingOrder
    },
    getIsLoggedIn() {
      return !!api.auth.getCurrentUser()
    },
    getEmail() {
      return getState().user.email
    },
    getPhoneNumber() {
      return getState().user.phoneNumber
    },
    getFormattedPhoneNumber() {
      return utils.formatPhoneNumber(dispatch.user.getPhoneNumber())
    },
    getName() {
      return getState().user.name
    },
    getIsContactExists() {
      return !!dispatch.user.getName() && !!dispatch.user.getPhoneNumber()
    },
    getAddress() {
      return getState().user.address
    },
    getLatLng() {
      return getState().user.latLng
    },
    getAptNumber() {
      return getState().user.aptNumber
    },
    getDeliveryInstructions() {
      return getState().user.deliveryInstructions
    },
    getDoorDashAddress() {
      return getState().user.doordashAddress
    },
    getDoorDashDetails() {
      return getState().user.doordashDetails
    },
    getRecentOrders() {
      return getState().user.recentOrders
    },
    getRecentOrdersSorted() {
      const activeProducts = dispatch.restaurant.getActiveProducts()

      const recentOrders = dispatch.user.getRecentOrders()
      const validRecentOrders = Object.keys(recentOrders).reduce((prev, productId) => {
        if (activeProducts[productId]) {
          prev[productId] = recentOrders[productId]
        }
        return prev
      }, {})

      return orderBy(keys(validRecentOrders), (k) => validRecentOrders[k], 'desc')
    },
    getFirstOrderDiscountAmount() {
      const firstOrderDiscount = dispatch.restaurant.getFirstOrderDiscount()
      if (
        dispatch.user.getIsFirstOrder() &&
        firstOrderDiscount > 0 &&
        dispatch.user.getCartSubTotal() >= dispatch.restaurant.getMinOrderDiscount()
      ) {
        return dispatch.user.getCartSubTotal() * firstOrderDiscount
      } else {
        return 0
      }
    },
    getPickupDiscountAmount() {
      const pickupDiscount = dispatch.restaurant.getPickupDiscount()
      if (
        dispatch.user.getOrderType() === 'Pickup' &&
        pickupDiscount > 0 &&
        dispatch.user.getCartSubTotal() >= dispatch.restaurant.getMinOrderDiscount()
      ) {
        return dispatch.user.getCartSubTotal() * pickupDiscount
      } else {
        return 0
      }
    },
    getPromoDiscountAmount() {
      const promoCode = dispatch.user.getPromoCode()
      if (isEmpty(promoCode)) {
        return 0
      }
      const discountAmount = dispatch.user.getCartSubTotal() * promoCode.discount
      return discountAmount > promoCode.maxDiscountAmount
        ? promoCode.maxDiscountAmount
        : discountAmount > dispatch.user.getCartSubtotalAfterRewards()
        ? dispatch.user.getCartSubtotalAfterRewards()
        : discountAmount
    },
    getCartWithProductDetails() {
      const restaurantProducts = dispatch.restaurant.getProducts()
      const cartItems = dispatch.user.getCart()
      return Object.keys(cartItems).reduce((prev, cartId) => {
        prev[cartId] = {
          ...cartItems[cartId],
          ...restaurantProducts[cartId],
        }
        return prev
      }, {})
    },
    getChoicesCartWithProductDetails() {
      const restaurantProducts = dispatch.restaurant.getProducts()
      const cartItems = dispatch.user.getChoicesCart()
      return cartItems.map((product) => {
        const totalPrice = product.choicesPrice + restaurantProducts[product.id].price
        return {
          ...product,
          ...restaurantProducts[product.id],
          totalPrice,
        }
      })
    },
    getCartCount() {
      const cartCount = Object.values(dispatch.user.getCart()).reduce((prev, product) => {
        return (prev += product.count)
      }, 0)
      const choicesCartCount = dispatch.user.getChoicesCart().reduce((prev, product) => {
        return (prev += product.count)
      }, 0)
      const promosCount = Object.values(dispatch.user.getValidPromosWithDetails()).reduce((prev, product) => {
        return (prev += product.count)
      }, 0)
      return cartCount + choicesCartCount + promosCount
    },
    getCartSubTotal() {
      const cartProducts = dispatch.user.getCartWithProductDetails()
      const cartSubtotal = Object.keys(cartProducts).reduce((prev, id) => {
        return prev + cartProducts[id].price * cartProducts[id].count
      }, 0)

      const choicesCartProducts = dispatch.user.getChoicesCartWithProductDetails()
      const choicesCartSubtotal = choicesCartProducts.reduce((prev, product) => {
        return prev + product.totalPrice * product.count
      }, 0)

      return cartSubtotal + choicesCartSubtotal
    },
    getCartDiscount() {
      return (
        dispatch.user.getFirstOrderDiscountAmount() +
        dispatch.user.getPickupDiscountAmount() +
        dispatch.user.getPromoDiscountAmount()
      )
    },
    getCartRewardsDiscount() {
      const promos = dispatch.user.getValidPromosWithDetails()
      const promoIds = Object.keys(promos)

      let totalDiscount = 0
      for (const promoId of promoIds) {
        const promoDetails = promos[promoId]
        if (promoDetails.totalDiscountSubtotal > 0) {
          totalDiscount += promoDetails.totalDiscountSubtotal
        }
      }

      // Cannot get discount more than cartSubTotal
      return Math.min(totalDiscount, dispatch.user.getCartSubTotal())
    },
    getCartSubtotalAfterRewards() {
      return Math.max(dispatch.user.getCartSubTotal() - dispatch.user.getCartRewardsDiscount(), 0)
    },
    getCartTax() {
      const deliveryFee = dispatch.user.getOrderType() === 'Delivery' ? dispatch.restaurant.getDeliveryFee() : 0
      let taxAfterRewards =
        (dispatch.user.getCartSubtotalAfterRewards() - dispatch.user.getCartDiscount() + deliveryFee) * 0.05
      const subtotalTax = dispatch.user.getCartSubTotal() * 0.05
      const productTax = dispatch.user.getChoicesCartWithProductDetails().reduce((prev, product) => {
        const { taxRate = 0.05 } = product
        return prev + product.totalPrice * product.count * taxRate
      }, 0)
      const otherTax = productTax - subtotalTax
      if (taxAfterRewards > 0) {
        taxAfterRewards += otherTax
      }
      return taxAfterRewards
    },
    getCartBagFee() {
      if (dispatch.user.getOrderType() === 'Dine-in') {
        return 0
      }
      const bagFee = dispatch.restaurant.getBagFee()
      if (isEmpty(bagFee)) {
        return 0
      }
      if (bagFee.amount === 0 || bagFee.interval === 0) {
        return 0
      }
      const subTotal = dispatch.user.getCartSubTotal()
      const { amount, interval } = bagFee
      const numberOfIntervals = Math.ceil(subTotal / interval)
      return amount * numberOfIntervals
    },
    getCartTotalBeforeTip() {
      let deliveryFee = 0
      if (dispatch.user.getOrderType() === 'Delivery') {
        deliveryFee = dispatch.restaurant.getDeliveryFee()
      }
      return (
        dispatch.user.getCartSubtotalAfterRewards() -
        dispatch.user.getCartDiscount() +
        dispatch.user.getCartTax() +
        dispatch.user.getCartBagFee() +
        deliveryFee
      )
    },
    getCartTip() {
      if (dispatch.user.getPaymentMethod() === 'online') {
        return dispatch.user.getCartTotalBeforeTip() * dispatch.user.getTip()
      }
      return 0
    },
    getCartTotal() {
      let deliveryFee = 0
      if (dispatch.user.getOrderType() === 'Delivery') {
        deliveryFee = dispatch.restaurant.getDeliveryFee()
      }
      return (
        dispatch.user.getCartSubtotalAfterRewards() -
        dispatch.user.getCartDiscount() +
        dispatch.user.getCartTax() +
        dispatch.user.getCartBagFee() +
        dispatch.user.getCartTip() +
        deliveryFee
      )
    },
    getReviewOrderId() {
      return getState().user.reviewOrderId || ''
    },
    getPoints() {
      return getState().user.points || 0
    },
    getPointsMax() {
      const points = dispatch.user.getPoints()
      if (points <= 1000) {
        return 1000
      }
      return (Math.floor((points - 1) / 1000) + 1) * 1000
    },
    getPointsWithPromoApplied() {
      const promos = dispatch.user.getValidPromosWithDetails()
      const promoIds = Object.keys(promos)
      let pointsRedeemedTotal = 0
      for (const promoId of promoIds) {
        const promoDetails = promos[promoId]
        if (promoDetails.totalRequiredPoints > 0) {
          pointsRedeemedTotal += promoDetails.totalRequiredPoints
        }
      }

      return dispatch.user.getPoints() - pointsRedeemedTotal
    },
    getOrderHistoryRange({ fromDate, toDate, limit = 100 }) {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      const userId = dispatch.user.getUserId()
      return api.orders.getOrderHistoryRange({
        restaurantId,
        userId,
        fromDate,
        toDate,
        limit,
      })
    },
    getOrderTime() {
      return getState().user.orderTime
    },
    getNotes() {
      return getState().user.notes
    },
    getOrderType() {
      if (dispatch.restaurant.getIsDineIn()) {
        return 'Dine-in'
      }
      if (!dispatch.restaurant.getDeliveryEnabled()) {
        return 'Pickup'
      }
      return getState().user.orderType
    },
    getTip() {
      return getState().user.tip
    },
    getPaymentMethod() {
      if (dispatch.user.getOrderType() === 'Delivery' && dispatch.restaurant.getDeliveryOnlinePaymentOnly()) {
        return 'online'
      }
      if (dispatch.restaurant.getPaymentMethodType() !== 'both') {
        return dispatch.restaurant.getPaymentMethodType()
      }
      return getState().user.paymentMethod
    },
    async createNotificationForRequest() {
      const locationId = dispatch.restaurant.getSelectedLocationId()
      const tableId = dispatch.restaurant.getTableId()
      const tableNumber = dispatch.restaurant.getTableNumber()
      const message = {
        locationId,
        tableId,
        title: 'New Dine-in Request',
        body: `Open the Staff App to confirm request for #${tableNumber}!`,
      }
      api.server.sendStaffNotification(serverUrl, apiKey, message)
    },
    async changeUserInfo({ name, phoneNumber }) {
      const userInfo = {}
      const nameTrimmed = name && name.trim()
      const numerOnlyPhoneNumber = utils.removeNonNumericString(phoneNumber)

      if (nameTrimmed) {
        userInfo.name = nameTrimmed
        if (nameTrimmed.length > 18) {
          throw new Error('Name is too long')
        }
      }
      if (numerOnlyPhoneNumber) {
        if (!numerOnlyPhoneNumber || numerOnlyPhoneNumber.length !== 10) {
          throw new Error('Incorrect Phone Number. Please enter format of 7783334444')
        }
        userInfo.phoneNumber = numerOnlyPhoneNumber
      }
      if (dispatch.user.getIsLoggedIn() && !isEmpty(userInfo)) {
        return api.user.updateUser(dispatch.user.getUserId(), userInfo)
      }
    },
    async deactivateUser() {
      const userToken = await dispatch.user.getUserToken()
      return api.server.deactivateUser(serverUrl, userToken, { userId: dispatch.user.getUserId() })
    },
    changeUserAddress(address) {
      return api.user.updateUser(dispatch.user.getUserId(), address)
    },
    signInWithEmailAndPassword({ email, password, onSuccess }) {
      return new Promise((resolve, reject) => {
        if (!email) {
          reject(new Error('Email cannot be empty.'))
          return
        }
        if (!password) {
          reject(new Error('Password cannot be empty.'))
          return
        }
        dispatch.user.setIsLoggingIn(true)
        try {
          api.auth
            .signInWithEmailAndPassword(email, password)
            .then((res) => {
              const uid = res.uid || get(res, 'user.uid')
              dispatch({ type: TYPES.ANALYTICS.LOGIN_SUCCESS, id: uid, email })
              onSuccess && onSuccess(res)
              dispatch.user.setIsLoggingIn(false)
              resolve(`Logged in as ${email}`)
            })
            .catch((error) => {
              dispatch({ type: TYPES.ANALYTICS.LOGIN_FAIL, error, email })
              dispatch.user.setIsLoggingIn(false)
              reject(error)
            })
        } catch (e) {
          dispatch({ type: TYPES.ANALYTICS.ERROR, error: e, email })
          dispatch.user.setIsLoggingIn(false)
          reject(e)
        }
      })
    },
    createUserWithEmailAndPassword({ email, password, phoneNumber = '', name = '', onSuccess }) {
      return new Promise((resolve, reject) => {
        if (!email || email.length === 0) {
          reject(new Error('Email cannot be empty.'))
          return
        }
        if (!password || password.length === 0) {
          reject(new Error('Password cannot be empty.'))
          return
        }
        const numerOnlyPhoneNumber = utils.removeNonNumericString(phoneNumber)
        if (!dispatch.restaurant.getIsDineIn()) {
          if (!name || name.trim().length === 0) {
            reject(new Error('Name cannot be empty.'))
            return
          }
          if (!numerOnlyPhoneNumber || numerOnlyPhoneNumber.length !== 10) {
            reject(new Error('Incorrect Phone Number. Please enter format of 7783334444.'))
            return
          }
        }
        dispatch.user.setIsLoggingIn(true)
        api.auth
          .createUserWithEmailAndPassword(email, password)
          .then((res) => {
            const uid = res.uid || get(res, 'user.uid')
            const userData = {
              id: uid,
              email: email.toLowerCase(),
              role: 'customer',
            }
            if (name) {
              userData.name = name.trim()
            }
            if (numerOnlyPhoneNumber) {
              userData.phoneNumber = numerOnlyPhoneNumber
            }
            dispatch({ type: TYPES.ANALYTICS.REGISTER_SUCCESS, ...userData })
            return api.user.createUser(uid, userData)
          })
          .then(() => {
            onSuccess && onSuccess()
            resolve(`Registered as ${email}`)
          })
          .catch((err) => {
            dispatch({ type: TYPES.ANALYTICS.REGISTER_FAIL, error: err, email })
            dispatch.user._unsetUser()
            reject(err)
          })
          .finally(() => {
            dispatch.user.setIsLoggingIn(false)
          })
      })
    },
    clearCart() {
      dispatch.user._clearCart()
      dispatch.user._clearPromos()
      dispatch.user._clearNotes()
      dispatch.user.setOrderTime('ASAP')
      if (!isMobile) {
        localStorage.removeItem('cart')
      }
    },
    setCart(cartToSet) {
      const userId = dispatch.user.getUserId()
      if (userId) {
        const restaurantId = dispatch.restaurant.getRestaurantId()
        api.user.updateUserRestaurant(userId, restaurantId, {
          cart: cartToSet,
        })
      } else {
        dispatch.user._setCart(cartToSet)
      }
    },
    setChoicesCart(cartToSet) {
      const userId = dispatch.user.getUserId()
      if (userId) {
        const restaurantId = dispatch.restaurant.getRestaurantId()
        return api.user.updateUserRestaurant(userId, restaurantId, {
          choicesCart: cartToSet,
        })
      } else {
        dispatch.user._setChoicesCart(cartToSet)
      }
    },
    setAddress(userAddress) {
      const userId = dispatch.user.getUserId()
      if (userId) {
        return dispatch.user.changeUserAddress(userAddress)
      } else {
        dispatch.user._setAddress(userAddress)
        return Promise.resolve()
      }
    },
    setDoorDashAddress(doordashAddress) {
      return api.user.updateUser(dispatch.user.getUserId(), { doordashAddress })
    },
    setOrderType(orderType) {
      const userId = dispatch.user.getUserId()
      if (userId) {
        const restaurantId = dispatch.restaurant.getRestaurantId()
        return api.user.updateUserRestaurant(userId, restaurantId, { orderType })
      } else {
        dispatch.user._setOrderType(orderType)
        return Promise.resolve()
      }
    },
    setPaymentMethod(paymentMethod) {
      const userId = dispatch.user.getUserId()
      if (userId) {
        const restaurantId = dispatch.restaurant.getRestaurantId()
        return api.user.updateUserRestaurant(userId, restaurantId, { paymentMethod })
      } else {
        dispatch.user._setPaymentMethod(paymentMethod)
        return Promise.resolve()
      }
    },
    addCartItem({ id, qty }) {
      const isAuthed = dispatch.user.getIsLoggedIn()
      if (isAuthed) {
        const cart = dispatch.user.getCart()
        const userId = dispatch.user.getUserId()
        const restaurantId = dispatch.restaurant.getRestaurantId()
        api.user.updateUserRestaurant(userId, restaurantId, {
          cart: {
            ...cart,
            [id]: {
              count: get(cart[id], 'count', 0) + qty,
            },
          },
        })
      } else {
        dispatch.user._addCartItem({ id, qty })
      }
    },
    subtractCartItem({ id }) {
      const isAuthed = dispatch.user.getIsLoggedIn()
      if (isAuthed) {
        const userId = dispatch.user.getUserId()
        const restaurantId = dispatch.restaurant.getRestaurantId()
        const cart = dispatch.user.getCart()
        api.user.updateUserRestaurant(userId, restaurantId, {
          cart:
            cart[id].count === 1
              ? omit(cart, id)
              : {
                  ...cart,
                  [id]: {
                    ...cart[id],
                    count: cart[id].count - 1,
                  },
                },
        })
      } else {
        dispatch.user._subtractCartItem({ id })
      }
    },
    addChoicesCartItem(product) {
      const isAuthed = dispatch.user.getIsLoggedIn()
      const choicesCart = dispatch.user.getChoicesCart()
      let newChoicesCart = []
      let isUnique = true
      newChoicesCart = choicesCart.reduce((prev, cartProduct) => {
        if (cartProduct.id === product.id && isEqual(cartProduct.choices, product.choices)) {
          cartProduct.count += product.count
          isUnique = false
        }
        prev.push(cartProduct)
        return prev
      }, [])
      if (isUnique) {
        newChoicesCart.push(product)
      }
      if (isAuthed) {
        const userId = dispatch.user.getUserId()
        const restaurantId = dispatch.restaurant.getRestaurantId()
        api.user.updateUserRestaurant(userId, restaurantId, {
          choicesCart: newChoicesCart,
        })
      } else {
        if (!isMobile) {
          localStorage.setItem('cart', JSON.stringify(newChoicesCart))
        }
        dispatch.user._addChoicesCartItem(newChoicesCart)
      }
    },
    subtractChoicesCartItem(productIndex) {
      const isAuthed = dispatch.user.getIsLoggedIn()
      const choicesCart = dispatch.user.getChoicesCart()
      const newChoicesCart = choicesCart.reduce((prev, product, index) => {
        if (index === productIndex && product.count > 1) {
          prev.push(product)
          product.count--
        } else if (index !== productIndex) {
          prev.push(product)
        }
        return prev
      }, [])
      if (isAuthed) {
        const userId = dispatch.user.getUserId()
        const restaurantId = dispatch.restaurant.getRestaurantId()
        api.user.updateUserRestaurant(userId, restaurantId, {
          choicesCart: newChoicesCart,
        })
      } else {
        if (!isMobile) {
          localStorage.setItem('cart', JSON.stringify(newChoicesCart))
        }
        dispatch.user._subtractChoicesCartItem(newChoicesCart)
      }
    },
    getPromos() {
      return getState().user.promos
    },
    getPromoCode() {
      return getState().user.promoCode
    },
    getValidPromosWithDetails() {
      const promos = dispatch.user.getPromos()
      const promoIds = Object.keys(promos)
      const rewards = dispatch.restaurant.getRewards()
      const productsDetails = dispatch.restaurant.getProducts()
      const cartSubTotal = dispatch.user.getCartSubTotal()
      const isLoggedIn = dispatch.user.getIsLoggedIn()
      const isFirstOrder = dispatch.user.getIsFirstOrder()
      const points = dispatch.user.getPoints()

      const validPromoWithDetails = {}
      let remainingPoints = points

      for (const promoId of promoIds) {
        const rewardInfo = rewards[promoId]
        if (!rewardInfo) {
          continue
        }

        for (let remainingCount = promos[promoId].count; remainingCount > 0; remainingCount--) {
          const rewardDetails = utils.parseRewardInfo({
            rewardInfo,
            isFirstOrder,
            productsDetails,
            count: remainingCount,
          })

          const { valid } = utils.getIsRewardValid({
            isLoggedIn,
            rewardDetails,
            isFirstOrder,
            productsDetails,
            cartSubTotal,
            redeemedRewardCount: remainingCount,
            userPointsWithPromoApplied: remainingPoints - (rewardDetails.totalRequiredPoints || 0),
          })

          if (valid) {
            validPromoWithDetails[promoId] = { ...rewardDetails, id: promoId, count: remainingCount }
            break
          }
        }
      }

      return validPromoWithDetails
    },
    getRewardDetails({ rewardId }) {
      const promos = dispatch.user.getPromos()
      let count = 0
      if (promos[rewardId]) {
        count = promos[rewardId].count || 0
      }
      const rewards = dispatch.restaurant.getRewards()
      const productsDetails = dispatch.restaurant.getProducts()
      const cartSubTotal = dispatch.user.getCartSubTotal()
      const isLoggedIn = dispatch.user.getIsLoggedIn()
      const userPointsWithPromoApplied = dispatch.user.getPointsWithPromoApplied()
      const isFirstOrder = dispatch.user.getIsFirstOrder()

      const rewardDetails = utils.parseRewardInfo({
        rewardInfo: rewards[rewardId],
        isFirstOrder,
        productsDetails,
        count,
      })
      const rewardRedeemable = utils.getIsRewardRedeemable({
        isLoggedIn,
        rewardDetails,
        isFirstOrder,
        productsDetails,
        cartSubTotal,
        redeemedRewardCount: count,
        userPointsWithPromoApplied,
      })

      return { ...rewardDetails, ...rewardRedeemable }
    },
    getRewardsWithDetails() {
      const rewards = dispatch.restaurant.getRewards()
      const rewardsWithDetails = {}
      const rewardIds = Object.keys(rewards)
      for (const rewardId of rewardIds) {
        const rewardDetails = dispatch.user.getRewardDetails({ rewardId })
        rewardsWithDetails[rewardId] = rewardDetails
        rewardsWithDetails[rewardId].id = rewardId
      }
      return rewardsWithDetails
    },
    getRewardsCount() {
      return Object.keys(dispatch.user.getRewardsWithDetails()).length
    },
    getRewardsWithDetailsSorted() {
      const rewardsWithDetails = dispatch.user.getRewardsWithDetails()
      return orderBy(Object.values(rewardsWithDetails), (v) => v.requiredPoints)
    },
    addPromo(code) {
      if (dispatch.user.getIsLoggedIn()) {
        const promos = dispatch.user.getPromos()
        const count = get(promos[code], 'count', 0)

        return api.user.updateUserRestaurant(dispatch.user.getUserId(), dispatch.restaurant.getRestaurantId(), {
          ['promos.' + code]: {
            id: code,
            count: count + 1,
          },
        })
      } else {
        // We don't allow adding promo while not logged in
        throw new Error('Please log in to redeem rewards!')
      }
    },
    removePromo(code) {
      if (dispatch.user.getIsLoggedIn()) {
        const promos = dispatch.user.getValidPromosWithDetails()
        const count = get(promos[code], 'count', 0)
        if (count <= 1) {
          return api.user.updateUserRestaurant(dispatch.user.getUserId(), dispatch.restaurant.getRestaurantId(), {
            ['promos.' + code]: DELETE_FIELD_VALUE,
          })
        } else {
          return api.user.updateUserRestaurant(dispatch.user.getUserId(), dispatch.restaurant.getRestaurantId(), {
            ['promos.' + code]: {
              id: code,
              count: count - 1,
            },
          })
        }
      }
    },
    clearPromos() {
      if (dispatch.user.getIsLoggedIn()) {
        return api.user.updateUserRestaurant(dispatch.user.getUserId(), dispatch.restaurant.getRestaurantId(), {
          promos: {},
        })
      }
    },
    async signOut() {
      try {
        dispatch.user.setIsLoggingOut(true)
        const res = await api.auth.signOut()
        dispatch.user._unsetUser()
        return res
      } finally {
        dispatch.user.setIsLoggingOut(false)
      }
    },
    getOrderDoc({ orderId }) {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      return db.collection('Restaurants').doc(restaurantId).collection('Orders').doc(orderId)
    },
    getOrder({ orderId }) {
      return dispatch.user
        .getOrderDoc({ orderId })
        .get()
        .then((doc) => {
          const orderData = doc.data()
          orderData.id = doc.id
          return orderData
        })
    },
    setNotificationToken({ notificationToken }) {
      const userId = dispatch.user.getUserId()
      if (userId) {
        return api.user.updateUser(userId, { notificationToken })
      }
    },
    async estimateDoorDashDelivery() {
      const data = {
        pickup_address: dispatch.restaurant.getDoorDashAddress(),
        dropoff_address: dispatch.user.getDoorDashAddress(),
        order_value: Math.round(dispatch.user.getCartSubtotalAfterRewards() * 100),
        external_store_id: dispatch.restaurant.getSelectedLocationId(),
        external_business_name: dispatch.restaurant.getName(),
      }
      const orderTime = dispatch.user.getOrderTime()
      if (orderTime === 'ASAP') {
        data.pickup_time = utils
          .moment()
          .add(dispatch.restaurant.getWaitTime() + 10, 'minutes')
          .toISOString()
      } else {
        data.delivery_time = moment(orderTime, 'LT, MMM D').toISOString()
      }
      const userToken = await dispatch.user.getUserToken()
      return promiseRetry(() => api.server.estimateDelivery(serverUrl, userToken, data))
    },
    async createDoorDashDelivery() {
      const data = {
        pickup_address: dispatch.restaurant.getDoorDashAddress(),
        dropoff_address: dispatch.user.getDoorDashAddress(),
        pickup_phone_number: `+1${dispatch.restaurant.getPhoneNumber()}`,
        dropoff_instructions: dispatch.user.getDeliveryInstructions(),
        order_value: Math.round(dispatch.user.getCartSubtotalAfterRewards() * 100),
        tip: Math.round(dispatch.user.getCartTip() * 100),
        customer: {
          phone_number: `+1${dispatch.user.getPhoneNumber()}`,
          first_name: dispatch.user.getName(),
          last_name: '',
          email: dispatch.user.getEmail(),
          should_send_notifications: true,
        },
        external_store_id: dispatch.restaurant.getSelectedLocationId(),
        external_business_name: dispatch.restaurant.getName(),
      }
      const orderTime = dispatch.user.getOrderTime()
      if (orderTime === 'ASAP') {
        data.pickup_time = utils
          .moment()
          .add(dispatch.restaurant.getWaitTime() + 10, 'minutes')
          .toISOString()
      } else {
        data.delivery_time = moment(orderTime, 'LT, MMM D').toISOString()
      }
      const userToken = await dispatch.user.getUserToken()
      return promiseRetry(() => api.server.createDelivery(serverUrl, userToken, data))
    },
    updateOrderReview({ orderId, review }) {
      const userId = dispatch.user.getUserId()
      const restaurantId = dispatch.restaurant.getRestaurantId()
      return new Promise((resolve, reject) => {
        api.orders
          .updateOrder({ restaurantId, orderId, order: { review } })
          .then(() => {
            api.user
              .updateUserRestaurant(userId, restaurantId, {
                reviewOrderId: null,
              })
              .then(resolve)
              .catch(reject)
          })
          .catch(reject)
      })
    },
    getLastUserOrder() {
      return getState().user.lastUserOrder
    },
    getIsLastUserOrderLoading() {
      return getState().user.loadingLastUserOrder
    },
    getIsFirstOrder() {
      const loading = dispatch.user.getIsLastUserOrderLoading()
      if (loading === false) {
        const lastUserOrder = dispatch.user.getLastUserOrder()
        return !get(lastUserOrder, 'id')
      } else {
        return false
      }
    },
    getUserDoc() {
      const userId = dispatch.user.getUserId()
      return db.collection('Users').doc(userId)
    },
    getUserRestaurantDoc() {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      return dispatch.user.getUserDoc().collection('Restaurants').doc(restaurantId)
    },
    getLastUserOrderDoc() {
      const userId = dispatch.user.getUserId()
      return dispatch.restaurant.getOrdersDoc().where('userId', '==', userId).orderBy('createdAt', 'desc').limit(1)
    },
    subscribeAuth() {
      dispatch.user.setLoading(true)
      let unsubscribeUser = null
      let unsubscribeUserRestaurant = null
      let unsubscribeLastUserOrder = null
      firebaseAuth.onAuthStateChanged((user) => {
        unsubscribeUser && unsubscribeUser()
        unsubscribeUserRestaurant && unsubscribeUserRestaurant()
        unsubscribeLastUserOrder && unsubscribeLastUserOrder()
        if (isEmpty(user)) {
          dispatch.user.setLoading(false)
        } else {
          // Update createdAt and lastLogin
          dispatch.user
            .getUserDoc()
            .get()
            .then((doc) => {
              const restaurantId = dispatch.restaurant.getRestaurantId()
              const currentTimestamp = moment().valueOf()

              const userData = doc.data() || {}

              if (!userData.role || !userData.email || userData.email !== user.email) {
                api.user.createUser(user.uid, {
                  id: user.uid,
                  email: user.email,
                  role: 'customer',
                })
              }

              if (!userData.createdAt) {
                // User doesn't have createdAt
                api.user.createUser(user.uid, {
                  createdAt: currentTimestamp,
                  lastLogin: currentTimestamp,
                })
              } else {
                api.user.createUser(user.uid, {
                  lastLogin: currentTimestamp,
                })
              }

              if (
                !userData.restaurants ||
                !userData.restaurants[restaurantId] ||
                !userData.restaurants[restaurantId].createdAt
              ) {
                api.user.createUser(user.uid, {
                  restaurants: {
                    [restaurantId]: {
                      createdAt: currentTimestamp,
                      lastLogin: currentTimestamp,
                    },
                  },
                })
              } else {
                api.user.createUser(user.uid, {
                  restaurants: {
                    [restaurantId]: {
                      lastLogin: currentTimestamp,
                    },
                  },
                })
              }
            })
          unsubscribeUser = dispatch.user.getUserDoc().onSnapshot(
            (snapshot) => {
              if (!dispatch.user.getIsLoggedIn()) {
                dispatch.user._unsetUser()
              } else {
                const userData = snapshot.data() || {}
                userData.id = snapshot.id
                dispatch.user._setUser(userData)
              }
              dispatch.user.setLoading(false)
            },
            (error) => {
              console.warn(error)
              dispatch.user.setLoading(false)
            }
          )
          dispatch.user.setLoadingUserRestaurant(true)
          unsubscribeUserRestaurant = dispatch.user.getUserRestaurantDoc().onSnapshot(
            (snapshot) => {
              if (!dispatch.user.getIsLoggedIn()) {
                dispatch.user._unsetUser()
              } else {
                const userRestaurantData = snapshot.data()
                dispatch.user._setUser(userRestaurantData || {})
              }
              dispatch.user.setLoadingUserRestaurant(false)
            },
            (error) => {
              console.warn(error)
              dispatch.user.setLoadingUserRestaurant(false)
            }
          )
          dispatch.user.setLoadingLastUserOrder(true)
          unsubscribeLastUserOrder = dispatch.user.getLastUserOrderDoc().onSnapshot((snapshots) => {
            snapshots.forEach((snapshot) => {
              if (!dispatch.user.getIsLoggedIn()) {
                dispatch.user._unsetUser()
              } else {
                const lastOrderData = snapshot.data()
                lastOrderData.id = snapshot.id
                dispatch.user._setLastUserOrder(lastOrderData)
              }
            })
            dispatch.user.setLoadingLastUserOrder(false)
          }, console.warn)
        }
      })
    },
  }),
})

function promiseRetry(fn, retries = 3, delay = 10, e = null) {
  if (!retries) {
    return Promise.reject(e)
  }
  return fn().catch((e) => {
    if (e.response && e.response.status.toString().charAt(0) === '5') {
      return new Promise((resolve) => {
        setTimeout(resolve(promiseRetry(fn, retries - 1, delay * 10, e)), delay)
      })
    } else {
      return Promise.reject(e)
    }
  })
}
