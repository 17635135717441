import React from 'react'
import { CFSelect } from 'components'
import { dispatch } from 'store'

export default class SubscribeRestaurant extends React.PureComponent {
  restaurantId = null
  unsubscribe = null
  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe()
  }
  render() {
    return (
      <CFSelect selector={dispatch.restaurant.getRestaurantId}>
        {restaurantId => {
          if (restaurantId !== this.restaurantId) {
            if (restaurantId) {
              this.unsubscribe && this.unsubscribe()
              this.unsubscribe = dispatch.restaurant.subscribeRestaurant()
            }
            this.restaurantId = restaurantId
          }
          return null
        }}
      </CFSelect>
    )
  }
}
