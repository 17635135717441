import React from 'react'
import { CFIcon, CFView, ThemedText } from 'components'

export default ({ title, icon, EditButton }) => {
  return (
    <CFView
      h="40px"
      p="10px"
      btlr="5px"
      btrr="5px"
      row
      alignCenter
      bg="rgba(150,150,150,.5)"
    >
      <ThemedText>
        <CFIcon ml="5px" mr="8px" icon={icon} />
      </ThemedText>
      <ThemedText h3 bold>
        {title}
      </ThemedText>
    </CFView>
  )
}
