import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import moment from 'moment'
import {
  PageBackground,
  CFSelect,
  CFView,
  DefaultScreen,
  MobileScreen,
  Navbar,
  PageContainer,
  PrimaryModal,
  ThemedText,
} from 'components'
import { OrderDetails } from 'pages'
import OrderHistoryItem from './OrderHistoryItem'
import { dispatch } from 'store'

export default () => {
  const history = useHistory()
  const isMobile = useMediaQuery({ maxWidth: 767 })

  const [loading, setLoading] = useState(true)
  const [orderHistory, setOrderHistory] = useState([])
  const [showOrderModal, setShowOrderModal] = useState(false)
  const [order, setOrder] = useState({})

  useEffect(() => {
    if (dispatch.user.getIsLoggedIn()) {
      const fetchOrderHistory = async () => {
        const fromDate = moment().startOf('day').subtract(1, 'years')
        const toDate = moment().endOf('day')

        const orders = await dispatch.user.getOrderHistoryRange({
          fromDate,
          toDate,
        })
        setOrderHistory(orders)
        setLoading(false)
      }
      fetchOrderHistory()
    }
  }, [loading])

  const navigateOrderDetails = order => {
    if (isMobile) {
      history.push('/order_details', order)
    } else {
      setOrder(order)
      setShowOrderModal(true)
    }
  }

  return (
    <>
      <MobileScreen>
        <PageBackground>
          <PageContainer offset="navbar" p="10px">
            <CFSelect selector={dispatch.user.getIsLoggedIn}>
              {loading ? (
                <ThemedText>Loading...</ThemedText>
              ) : orderHistory.length > 0 ? (
                orderHistory.map((order, index) => (
                  <OrderHistoryItem
                    key={index}
                    order={order}
                    onClick={() => navigateOrderDetails(order)}
                  />
                ))
              ) : (
                <CFView h="100%" row center>
                  <CFView
                    h2
                    bold
                    color={dispatch.restaurant.getStyles().fontColor}
                  >
                    No orders yet.
                  </CFView>
                </CFView>
              )}
            </CFSelect>
            <CFSelect selectorNot={dispatch.user.getIsLoggedIn}>
              <CFView h="100%" row center>
                <CFView
                  h2
                  bold
                  color={dispatch.restaurant.getStyles().fontColor}
                >
                  Please login to view orders.
                </CFView>
              </CFView>
            </CFSelect>
          </PageContainer>
          <Navbar />
        </PageBackground>
      </MobileScreen>
      <DefaultScreen>
        {loading ? (
          <ThemedText>Loading...</ThemedText>
        ) : (
          <CFView pv="10px" ph="15px">
            {orderHistory.length > 0 ? (
              orderHistory.map((order, index) => (
                <OrderHistoryItem
                  key={index}
                  order={order}
                  onClick={() => navigateOrderDetails(order)}
                />
              ))
            ) : (
              <CFView row center>
                <ThemedText h2 bold mt="80px">
                  No orders yet.
                </ThemedText>
              </CFView>
            )}
          </CFView>
        )}
        <PrimaryModal
          title="Order Details"
          isOpen={showOrderModal}
          onRequestClose={() => setShowOrderModal(false)}
        >
          <OrderDetails
            order={order}
            onRequestClose={() => setShowOrderModal(false)}
          />
        </PrimaryModal>
      </DefaultScreen>
    </>
  )
}
